import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

export default function Accepted({ apicall_change_status = () => {} }) {

  const creator = require("./assets/creator.png")
  const acceptedRocket = require("./assets/accepted-rocket.png")
  const timeIcon = require("./assets/time.png")
  const dotsIcon = require("./assets/dots.png")
  const accepted = require("./assets/accepted.png")

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
        style={{backgroundColor: "#6242F9", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
    <div className="min-h-screen flex flex-col" style={{backgroundColor: "#6242F9"}}>
      {/* Main Content */}
      <div className="flex-grow bg-[#6242F9] mt-16">
        <div className="flex flex-col items-center justify-center mt-16 px-6">
          {/* Header Text */}
          <h1
            className="text-2xl font-bold text-white text-center "
            style={{ textShadow: "0px 4px 5px rgba(0, 0, 0, 0.5)", margin: 20, marginLeft: 30 }}
          >
             Your Application Accepted! 🎉
          </h1>

          {/* Icons Section */}
          <div className="relative flex justify-center items-center mt-8 w-full">
            <div className="relative absolute left-4 flex items-center justify-center" style={{width: "150px", right: "20px"}}>
              <img
                src={acceptedRocket}
                alt="Rocket"
                className=" scale-125"
                style={{width: "150px", height: "150px"}}
              />
              <img
                src={dotsIcon}
                alt="Dots"
                className="absolute w-12 h-2"
                style={{right: "-10px"}}
              />
            </div>

            <img
              src={timeIcon}
              alt="Time"
              className="w-12 h-12"
              style={{marginRight: 4}}
            />
            <img
              src={dotsIcon}
              alt="Dots"
              className="w-12 h-2"
              style={{marginRight: 4}}
            />
            <img
              src={accepted}
              alt="Accepted"
              
              style={{width: "70px", height: "70px", padding: 4}}

            />
          </div>

          {/* Informational Text */}
          <p className="text-sm font-semibold text-white text-center" style={{paddingInline: "40px"}}>
            Your request has been approved after processing, you can now create
            snacks!
          </p>
          <h2 className="text-2xl font-bold text-white" style={{marginTop: "30px"}}>
            Create Your First Snack!
          </h2>
          <img
            src={creator}
            alt="Creator"
            className="w-36 h-36 mt-4"
            style={{width: "250px", height: "200px", margin: 4}}
          />

          {/* Continue Button */}
          <div style={{margin: 12}}>
            <button
              onClick={() => apicall_change_status("registered")}
              className="bg-black border border-white text-white text-lg font-bold rounded-xl shadow-xl hover:bg-gray-800"
              style={{width: "200px", height: "50px"}}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
}

Accepted.propTypes = {
  apicall_change_status: PropTypes.func,
};
