import React from 'react';
import { motion } from 'framer-motion';

export default function Rejected({ apicall_change_status = () => {} }) {
    return (
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
        style={{backgroundColor: "#6242F9", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
        <div className="min-h-screen flex" style={{backgroundColor: "#6242F9"}}>
            <div className="flex-1 bg-[#6242F9] mt-16" style={{padding: "20px"}}>
                <div className="flex-1 overflow-y-scroll">
                    {/* Main Content */}
                    <div className="flex flex-col items-center justify-center mt-24 p-8">
                        <h2
                            className="font-bold text-2xl text-white text-center"
                            style={{
                                textShadow: '0 4px 5px rgba(0, 0, 0, 0.5)', margin: 20, marginLeft: 30
                            }}
                        >
                            Your Application Rejected!
                        </h2>

                        <div className="relative flex justify-center items-center mt-8 w-full">
                            <div className="relative absolute left-4 flex items-center justify-center" style={{width: "150px", right: "20px"}}>
                                <img
                                    src={require("./assets/accepted-rocket.png")}
                                    alt="Rocket"
                                    className=" scale-125"
                                    style={{width: "150px", height: "150px"}}
                                />
                                <img
                                    src={require("./assets/dots.png")}
                                    alt="Dots"
                                    className="absolute w-12 h-2"
                                    style={{right: "-10px"}}
                                />
                            </div>

                            <img
                            src={require("./assets/time.png")}
                            alt="Time"
                            className="w-12 h-12"
                            style={{marginRight: 4}}
                            />
                            <img
                            src={require("./assets/dots.png")}
                            alt="Dots"
                            className="w-12 h-2"
                            style={{marginRight: 4}}
                            />
                            <img
                            src={require("./assets/rejected.png")}
                            alt="Rejected"
                            
                            style={{width: "70px", height: "70px", padding: 4}}

                            />
                        </div>

                        <p className="font-semibold text-lg text-center text-white mx-8 mt-0 mb-0">
                            Your request has been rejected after processing, try again later :(
                        </p>
                        <img
                            src={require("./assets/warning.png")}
                            alt="Warning"
                            className="w-[300px] h-[200px] mt-2"
                        />

                        <div style={{margin: "40px"}}>
                            <button
                                onClick={() => apicall_change_status(null)}
                                className="bg-black border border-white rounded-xl flex justify-center items-center shadow-xl text-white font-bold text-lg"
                                style={{
                                    boxShadow: '0px 5px 9px rgba(0, 0, 0, 0.5)',
                                    width: "200px",
                                    height: "50px"
                                }}
                            >
                                Continue
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </motion.div>
        </div>
    );
}
