export const ButtonPrimary = ({ onClick = () => {}, children, className, ...props }) => {

    return (<div
        onClick={(e) => onClick(e)}
        className={` p-[5px] px-[10px] bg-violet-500 rounded-[20px] w-fit text-[12px] font-bold  ${className} `}
        {...props}
        >
        {children}
    </div>);


};

export const PrimaryButton = ({ disabled, isLoading, label, className = "", onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`rounded-full overflow-hidden ${className}`}
      style={{
        background: "linear-gradient(to right, #6c6cff, #b58ef7)",
        cursor: disabled || isLoading ? "not-allowed" : "pointer",
        padding: "10px 20px",
        border: "none",
      }}
    >
      <span style={{ color: "white", fontWeight: "bold" }}>
        {isLoading ? "..." : label}
      </span>
    </button>
  );
};


export const PrimaryButtonBoxy = ({
  disabled,
  isLoading,
  label,
  _className = "",
  onClick,
}) => {
  const btnBackground = require("../Components/Buttons/UI_Images/buttons/btn_box.png"); // Replace with the actual image path

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`relative overflow-hidden ${_className}`}
      style={{
        backgroundImage: `url(${btnBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        borderRadius: "9999px", // Full rounded button
        border: "none",
        padding: "0",
      }}
    >
      <span className="block py-[2vh] font-bold text-white text-center">
        {isLoading ? "..." : label}
      </span>
    </button>
  );
};
