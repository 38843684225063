

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "Auth/AuthContext";
// import AuthContext, { useUser } from "AuthContext";
import { API } from "constant";
// import GoogleLogin from "./GoogleLogin";
import { sendAnalytics } from 'firebaseConfig';
import { Store as wallatStore } from "StoreContext/wallatStore";
import { getToken, setToken } from "helpers";
import {
  SecondaryButtonBoxyBlack,
  SecondaryButtonBoxyWhite,
} from "../../Components/ButtonSecondary"
import axios from "axios";
import { headers } from "helpers";

import _bg from "../../Assets/bg.png";
import _snacky from "../../Assets/Logo.png";
import GoogleLoginButton from "./GoogleLogin";


const LoginScreen = () => {

    useEffect(() => {
        sendAnalytics( `screen_view`, {
            firebase_screen: "Login page", 
            firebase_screen_class: "Auth page"
        })
    }, [])

  const navigate = useNavigate();
  let _store_wallet = useContext(wallatStore)
//   const { loadUserData, user } = useUser();

  const [inputVals, setInputVals] = useState({
    email: "",
    password: "",
  });
  const { user } = useAuthContext()
  const [userData, setUserData] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [hidePass, setHidePass] = useState(true);
  const { setUser } = useAuthContext();


  const fetchUserData = async () => {
    try {

        const res = await axios.get(`${API}/snackr/user/data`, {
            headers: headers()
        })
        const userData = res?.data?.user || {}

        //   console.log(res.data)

        _store_wallet.dispatch({
            type: 'initUser',
            payload: { user: userData }
        })
        // setvideos((oldVideos) => [...oldVideos, ...snacks]);
        setUserData(userData)
    } catch (ex) {

    }
  }

//   useEffect(() => {
//     fetchUserData()
//   }, [])

  const handleInputChange = (value, key) => {
    setError("");
    setInputVals((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

//   const handleLogin = async () => {
//     setIsLoading(true);
//     try {
//       const value = {
//         identifier: inputVals.email,
//         password: inputVals.password,
//       };

//       const response = await fetch(`${API}/auth/local`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(value),
//       });

//       const data = await response.json();
//       if (data?.error) {
//         throw data?.error;
//       } else {
//         await setToken(data.jwt);
//         // loadUserData(data);
//         // analyticslogLogin({ method: "Web" });
//         navigate("/");
//       }
//     } catch (ex) {
//       setError(
//         ex?.details?.errors?.[0]?.message ??
//           ex?.message ??
//           ex.response?.data?.error?.message ??
//           "Something went wrong!"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

    const onFinish = async (values) => {
            setIsLoading(true);
            try {
                const value = {
                    identifier: values.email,
                    password: values.password,
                };
                const response = await fetch(`${API}/auth/local`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(value),
                });
    
                const data = await response.json();
                if (data?.error) {
                    throw data?.error;
                } else {
                    // set the token
                    setToken(data.jwt);
    
                    // set the user
                    setUser(data.user);
    
                    console.log(`Welcome back ${data.user.username}!`);
                    navigate("/")
                    // navigate("/", { replace: true });
                    // callback()
    
                    sendAnalytics("Login", {})
                }
            } catch (ex) {
                // console.log(ex?.message|| "lol");
                setError( ex?.message ?? ex.response?.data?.error?.message ??  "Something went wrong!");
            } finally {
                setIsLoading(false);
            }
        };

  const gotoCreateAccount = () => {
    navigate("/signup");
  };

  const gotoForgetPassword = () => {
    navigate("/forgot-password");
  };

  const gotoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className="bg-[#0E0C1C] min-h-screen">
      <div className="h-full flex flex-col">
        <div
          className="w-full h-screen bg-cover bg-center"
          style={{ backgroundImage: `url(${_bg})` }}
        >
          <div className="mt-[35px]">
            <img
              src={_snacky}
              alt="Snacky Logo"
              className="w-[70px] h-[70px] mx-auto"
            />
          </div>

          <div className="px-[20px] pt-[20px] h-full rounded-t-[40px]">
            <h1 className="pb-[10px] text-[2rem] px-[30px] font-bold text-left text-white">
              Hi!
            </h1>

            <div className="bg-black/50 p-[30px] rounded-xl">
              <div className="pb-[10px]">
                <input
                  type="email"
                  value={inputVals.email}
                  onChange={(e) => handleInputChange(e.target.value, "email")}
                  placeholder="Email"
                  className="rounded-md w-full h-[50px] border-2 border-zinc-200 pl-[20px] font-bold text-zinc-800 placeholder:text-slate-400 bg-zinc-100 focus:border-violet-300"
                />
              </div>

              <div className="relative">
                <input
                  type={hidePass ? "password" : "text"}
                  value={inputVals.password}
                  onChange={(e) => handleInputChange(e.target.value, "password")}
                  placeholder="Password"
                  className="rounded-md w-full h-[50px] border-2 border-zinc-200 pl-[20px] font-bold text-zinc-800 placeholder:text-slate-400 bg-zinc-100 focus:border-violet-300"
                />
                <button
                  type="button"
                  onClick={() => setHidePass(!hidePass)}
                  className="absolute right-[20px] top-[-5px] text-[1.2rem] px-[10px] py-[10px]"
                >
                  👁️
                </button>
              </div>

              <div>
                <p className="text-center text-red-500 py-[5px]">{error}</p>
              </div>

              <div className="mt-[5px]">
                <SecondaryButtonBoxyBlack
                  _className="py-[14px] w-full"
                  onClick={() => onFinish(inputVals)}
                  isLoading={isLoading}
                  label="Continue"
                />
              </div>

              <p className="text-center mb-[10px] text-white pt-[15px]">or</p>

              <div className="pt-[10px] ">
                <SecondaryButtonBoxyWhite
                  _className="py-[14px] w-full"
                  onClick={gotoHome}
                  label="Login as guest"
                />
              </div>

              <div className="mt-[20px] max-w-[300px]">
                <GoogleLoginButton />
              </div>

              <div className="pt-[30px]">
                <button
                  onClick={gotoForgetPassword}
                  className="min-h-[48px] flex flex-col items-left w-full font-bold text-left text-white"
                >
                  Forgot your password?
                </button>
              </div>

              <div className="text-left flex flex-row items-left justify-left mt-[10px]">
                <p className="text-white">Don't have an account?</p>
                <button
                  onClick={gotoCreateAccount}
                  className="ml-2 font-bold text-white underline"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
