import React, { useEffect } from 'react'
import { useUI } from 'UIContext/UIContext'
import StreakReward from './StreakReward'
import SignupBonus from './SignupBonus'
import LoginPrompt from './LoginPrompt'
import FeedbackPrompt from './FeedbackPrompt'
import MessageBox from './MessageBox'

export default function Index() {

    const { modal, setModal } = useUI()
    // const  modal  = ""
    // console.log(modal)
   
    

  return ( 
    <>
    {modal === "streak_reward" && <StreakReward />}
    {modal === "login_prompt" && <LoginPrompt />}
    {modal === "signup_bonus" && <SignupBonus />}
    {modal === "feedback_prompt"  && <FeedbackPrompt />}
    {modal === "message_not_available"  
      &&  
      <MessageBox
        title="Feature Coming Soon!"
        message="This feature is not available at the moment."
       />}
    </>
  ) 
}