import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getToken, setToken } from "../../../../helpers";
import { API } from "../../../../constant";
import { motion } from "framer-motion";
import { PrimaryButton } from "Components/ButtonPrimary";

const ChangePassword = ({handlePageState = () => {}}) => {
  const navigate = useNavigate();

  const goBack = () => {
    handlePageState("settings") // Go back to the previous page
  };

  const [inputVals, setInputVals] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handleInputChange = (key, value) => {
    setError("");
    setInputVals((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const clearInputs = () => {
    setInputVals({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const value = {
        password: inputVals.password,
        newPassword: inputVals.newPassword,
        confirmPassword: inputVals.confirmPassword,
      };
      const token = await getToken();
      const response = await axios.post(
        `${API}/snackr/user/changePassword`,
        value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = response?.data;
      if (data?.error) {
        throw data?.error;
      } else {
        await setToken(data.jwt);
        alert("Success: Password updated");
        clearInputs();
      }
    } catch (ex) {
      setError(
        ex.response?.data?.error?.message ??
          ex?.message ??
          ex.response.data?.details?.errors?.[0]?.message ??
          "Something went wrong!"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
    style={{backgroundColor: "#0E0C1C"}}
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
    <div className="bg-[#0E0C1C] min-h-screen">
      <div className="px-[10px] py-[5px] border-b border-[#b58ef7]">
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={require("../../../../Assets/left-arrow.png")}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">Change Password</h1>
        </div>
      </div>

      <div className="px-[15px] py-[5px] pt-[30px]">
        <label className="text-slate-200 text-[0.8rem] ml-[2vw] mb-[1vh]">
          Old password
        </label>
        <input
          type="password"
          value={inputVals.password}
          onChange={(e) => handleInputChange("password", e.target.value)}
          placeholder="Old password"
          className="w-full p-[10px] pl-[20px] rounded-full bg-white text-gray-800"
        />
      </div>

      <div className="px-[15px] py-[5px] pt-[30px]">
        <label className="text-slate-200 text-[0.8rem] ml-[2vw] mb-[1vh]">
          New password
        </label>
        <input
          type="password"
          value={inputVals.newPassword}
          onChange={(e) => handleInputChange("newPassword", e.target.value)}
          placeholder="New password"
          className="w-full p-[10px] pl-[20px] rounded-full bg-white text-gray-800"
          
        />
        <p className="text-slate-200 text-[0.8rem] ml-[2vw] mt-[1vh]">
          Must be at least 8 characters
        </p>
      </div>

      <div className="px-[15px] py-[5px] pt-[30px]">
        <label className="text-slate-200 text-[0.8rem] ml-[2vw] mb-[1vh]">
          Confirm password
        </label>
        <input
          type="password"
          value={inputVals.confirmPassword}
          onChange={(e) => handleInputChange("confirmPassword", e.target.value)}
          placeholder="Confirm password"
          className="w-full p-[10px] pl-[20px] rounded-full bg-white text-gray-800"
    
        />
        <p className="text-slate-200 text-[0.8rem] ml-[2vw] mt-[1vh]">
          Both passwords must match
        </p>
      </div>

      <div>
        {error && <p className="text-center text-red-500 py-[8px]">{error}</p>}
      </div>

      <div className="px-[15px] my-[5px] pt-[30px]">
        <PrimaryButton 
            isLoading={isLoading}
            label={"Change Password"}
            onClick={handleSubmit}
            className=" w-full"
        />
        {/* <button
          onClick={handleSubmit}
          disabled={isLoading}
          className={`w-full p-4 rounded-2xl bg-blue-600 text-white ${
            isLoading ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}
        >
          {isLoading ? "Loading..." : "Change password"}
        </button> */}
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default ChangePassword;
