import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./AuthContext";
import { API, BEARER } from "constant";
import { getToken, removeToken } from "helpers";
import { headers } from "helpers";
import { Store as wallatStore } from "StoreContext/wallatStore";
import axios from "axios";
const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const authToken = getToken();
  let _store_wallet  = useContext(wallatStore)
  const fetchLoggedInUser = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/snackr/user/me`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
    //   const response = await fetch(`${API}/users/me`, {
    //     headers: { Authorization: `${BEARER} ${token}` },
    //   });
      const data = await response.json();

      setUserData(data);
    } catch (error) {
      console.error(error);
    //   message.error("Error While Getting Logged In User Details");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserData = async () => {
    try {

        const res = await axios.get(`${API}/snackr/user/data`, {
            headers: headers()
        })
        const userData = res?.data?.user || {}

        //   console.log(res.data)
        
        // _store_wallet.dispatch({
        //     type: 'initUser',
        //     payload: { user: userData }
        // })
        // setvideos((oldVideos) => [...oldVideos, ...snacks]);
        setUserData(userData)
        console.log("User: ", userData)
        
    } catch (ex) {
      console.log(ex);
    }
}

  const handleUser = (user) => {
    setUserData(user);
  };

  const loadUserData = async () => {
    setIsLoading(true);
    console.log("Loaduserdata")
    try {
      const userData = await fetchUserData();
      // console.log(userData, "userData get it ---------------")
      // _store_wallet.dispatch({
      //   type: 'initUser',
      //   payload: { user: userData }
      // })    

    } catch (error) {
      
      await removeToken();
      // _store_wallet.dispatch({ type: 'LOGOUT' });
      // route.replace('/login_screen')

    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchUserData()
      // fetchLoggedInUser(authToken);
    }
  }, [authToken]);

  return (
    <AuthContext.Provider
      value={{ user: userData, setUser: handleUser, loadUserData: fetchUserData, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;