import React, { useEffect, useState } from 'react';
// import { Form, Button, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
// import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5';
// import { Toast } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const Test = () => {
  const [selectedBranch, setSelectedBranch] = useState('');
  const [inheritFrom, setInheritFrom] = useState('');
  const [keyValuePairs, setKeyValuePairs] = useState([]);
  const [branches, setBranches] = useState([]);
  const [inheritValues, setInheritValues] = useState([]);
  const [selectedProject, setSelectedProject] = useState('cs-v3-frontend');
  const [inheritLabel, setInheritLabel] = useState('Copy environment variable from');
  const [deploying, setDeploying] = useState(false);
  const [deploySuccess, setDeploySuccess] = useState(null);
  const [buildStatusLinkVisible, setBuildStatusLinkVisible] = useState(false);
  const [deployedUrl, setDeployedUrl] = useState('');
  const [aborted, setAborted] = useState(false);
  const [buildNumber, setBuildNumber] = useState(null);
  const [buildCheckInterval, setBuildCheckInterval] = useState(null);
  const [showBranchNamePopup, setShowBranchNamePopup] = useState(false); // Step 1
  const [showBranchNameToast, setShowBranchNameToast] = useState(true);
  const toggleBranchNameToast = () => setShowBranchNameToast(!showBranchNameToast);
  const [maskedValues, setMaskedValues] = useState(() => Array(keyValuePairs.length).fill(true));
  const [showFirstTimePopup, setShowFirstTimePopup] = useState(false);
  const [showSecondTimePopup, setShowSecondTimePopup] = useState(false);
  const [checkBuildStatusApiLoading, setCheckBuildStatusApiLoading] = useState(false);

  const fetchBranches = async () => {
    try {
      const response = await fetch('https://devlift.stage.multisafe.finance/api/get-branches');
      if (response.ok) {
        const data = await response.json();
        if (Array.isArray(data.branches) && data.branches.length > 0) {
          setBranches(data.branches);
        } else {
          console.error('Invalid branches format in API response:', data);
        }
      } else {
        throw new Error('Failed to fetch branches');
      }
    } catch (error) {
      console.error('Error fetching branches:', error);
    }
  };


  //========================================================================================================

  const fetchInheritValues = async () => {
    try {
      const response = await fetch('https://devlift.stage.multisafe.finance/api/get-secrets');

      if (response.ok) {

        const data = await response.json();
        if (Array.isArray(data.secretKeys) && data.secretKeys.length > 0) {
          setInheritValues(data.secretKeys);
          // Check if there exists a secret specific to the selected branch
          // const branchSpecificSecret = `cs/v3/frontend/${selectedBranch}`;
          // if (data.secretKeys.includes(branchSpecificSecret)) {
          //setInheritFrom(branchSpecificSecret);
          //deployApiForInherit(branchSpecificSecret);
          // setInheritLabel('Environment variable');
          const branchSpecificSecret = `cs/v3/frontend/${selectedBranch}`;
          const lowerbranchSpecificSecret = branchSpecificSecret.toLowerCase();

          if (data.secretKeys.includes(lowerbranchSpecificSecret)) {
            setInheritFrom(lowerbranchSpecificSecret);
            deployApiForInherit(lowerbranchSpecificSecret);
            setInheritLabel('Environment variable');

          } else {
            // Secret doesn't exist, show all secrets
            setInheritFrom('');
            setInheritLabel('Copy environment variable from');
          }
          // Check the length of the selected branch name
          // const selectedBranchLength = selectedBranch.length;
          // if (selectedBranchLength > 12) {
          // // Show pop-up message
          // //setShowBranchNamePopup(true);
          // } else {
          // // Hide pop-up message
          // setShowBranchNamePopup(false);
          // }
        } else {
          console.error('Invalid inheritValues format in API response:', data);
        }
      } else {
        throw new Error('Failed to fetch inherit values');
      }
    } catch (error) {
      console.error('Error fetching inherit values:', error);
    }
  };

  useEffect(() => {
    fetchBranches();
    fetchInheritValues();
  }, [selectedBranch]);


  //============================================================================================================== 
  const handleBranchChange = async (event) => {
    const selectedBranchValue = event.target.value;
    toggleMasked();
    setSelectedBranch(selectedBranchValue);
    setDeployedUrl('');
    setKeyValuePairs([]);
    setDeploySuccess(null);
    clearInterval(buildCheckInterval);
    // Fetch deployed URL when branch changes
    await fetchDeployedUrl(selectedBranchValue);
    // if another branch select
    setDeploying(false);
    setShowFirstTimePopup(false);
    setShowSecondTimePopup(false);
  };

  const toggleMasked = (index) => {
    console.log('Before Toggle - maskedValues:', maskedValues);

    setMaskedValues((prevValues) => {
      console.log('prevValues Toggle - prevValues:', prevValues);
      const newValues = [...prevValues];
      newValues[index] = !newValues[index];
      console.log('After Toggle - maskedValues:', newValues);
      return newValues;
    });
  };
  //==================================================================
  //==================================================================
  //into array of objects [ { key : "Env", value : "prod"}...]
  //==================================================================
  function convertObjectToArray(obj) {
    return Object.keys(obj).map((key) => ({ key: key, value: obj[key] }));
  }

  function convertArrayToObject(arrayOfObjects) {
    const obj = {};
    arrayOfObjects.forEach((item) => {
      obj[item.key] = item.value;
    });
    return obj;
  }

  //==================================================================
  //==================================================================

  const deployApiForInherit = async (selectedInherit) => {
    try {
      const response = await fetch('https://devlift.stage.multisafe.finance/api/secrets-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          secretName: selectedInherit,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.secrets) {
          const keyValues = convertObjectToArray(data.secrets);
          console.log('keyValues', keyValues);
          setKeyValuePairs(keyValues);
        } else {
          console.error('Invalid secrets data format in API response:', data);
        }
      } else {
        console.error('Failed to fetch data:', response.statusText);
        // Handle the error or throw it if you want to handle it elsewhere
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      // Handle the error
    }
  };

  const addKeyValuePair = (key_name = '_new_key') => {
    setKeyValuePairs([...keyValuePairs, { key: `Key${keyValuePairs.length}`, value: '' }]);
    //========================================================
    //========================================================
  };

  const handleInheritFromChange = (event) => {
    setInheritFrom(event.target.value);
    //deployApiForInherit(event.target.value);
  };

  const deleteKeyValuePair = (index) => {
    const updatedPairs = keyValuePairs.filter((_, i) => i !== index);
    setKeyValuePairs(updatedPairs);
  };

  const handleKeyChange = (key, value) => {
    console.log(key);
    setKeyValuePairs((prevArray) =>
      prevArray.map((obj) => (obj.key === key ? { ...obj, key: value } : obj))
    );
  };

  const handleValueChange = (key, value) => {
    setKeyValuePairs((prevArray) =>
      prevArray.map((obj) => (obj.key === key ? { ...obj, value: value } : obj))
    );
  };
  //=================================================================================================================================

  const checkBuildStatus = async () => {
    // console.log("calling...")
    // console.log('build number is', buildNumber);
    setTimeout(() => {
      console.log('Deployment completed. Stopping build status check.', temp);
      clearInterval(buildCheckInterval);

    }, 5000)
    // await new Promise(resolve => setTimeout(resolve, 10000));
    // // if (!deploying || true) {
    // if (true) {
    //   return;
    // }
    //
    // if (checkBuildStatusApiLoading === true) {
    //   console.log("waiting for api response");
    //   return;
    // }
    // setCheckBuildStatusApiLoading(true);
    // try {
    //   const requestBody = {
    //     buildNumber: buildNumber,
    //   };
    //   const jsonString = JSON.stringify(requestBody);
    //   console.log(jsonString);

    //   const response = await fetch('https://devlift.stage.multisafe.finance/api/get-build-status', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: jsonString,
    //   });

    //   if (response.ok) {
    //     console.log('Response is OK');
    //     const data = await response.json();
    //     const { buildNumber } = data;
    //     setBuildNumber(buildNumber);
    //     console.log("in bcheck fun ", buildNumber);
    //     const buildStatus = data.buildStatus;
    //     switch (buildStatus) {
    //       case 'SUCCESS':
    //         console.log('Build successful!');
    //         clearInterval(buildCheckInterval);
    //         setDeploySuccess(true);
    //         setBuildStatusLinkVisible(true);
    //         fetchDeployedUrl();
    //         break;

    //       case 'FAILURE':
    //         console.error('Build failed!');
    //         clearInterval(buildCheckInterval);
    //         console.log("build check interval", buildCheckInterval);
    //         setDeploySuccess(false);
    //         setBuildStatusLinkVisible(true);
    //         break;

    //       case 'ABORTED':
    //         console.error('Build aborted!');
    //         clearInterval(buildCheckInterval);
    //         setAborted(true);
    //         setBuildStatusLinkVisible(true);
    //         break;

    //       default:
    //         console.error('Unknown build status:', buildStatus);
    //     }
    //     setBuildStatusLinkVisible(true);
    //   } else {
    //     console.error('Failed to check build status:', response.statusText);
    //   }
    // } catch (error) {
    //   console.error('Error checking build status:', error);
    // }
    setCheckBuildStatusApiLoading(false);
  };

  //===================================

  const fetchDeployedUrl = async (branch) => {
    try {
      const response = await fetch('https://devlift.stage.multisafe.finance/api/get-url', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          branch: branch, // Use the parameter 'branch' here
        }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data && typeof data === 'object' && data['branch-url']) {
          setDeployedUrl(data['branch-url']);
          return deployedUrl;
        } else {
          console.error('Invalid URL format in API response:', data);
        }
      } else {
        throw new Error('Failed to fetch deployed URL');
      }
    } catch (error) {
      console.error('Error fetching deployed URL:', error);
    }
  };
  //===================================================================================================================

  const deployApi = async () => {
    try {
      // Check if the URL already exists
      console.log("selectedbranch1", selectedBranch);
      const deployedUrl = await fetchDeployedUrl(selectedBranch);
      console.log("deployedurl", deployedUrl);
      setDeployedUrl(deployedUrl);
      // Show pop-up message based on whether the URL exists or not
      if (deployedUrl) {
        // URL exists, show the second-time pop-up message
        setShowBranchNamePopup(false);
        setShowSecondTimePopup(true);
        console.log("firstplace");
      } else {
        // URL doesn't exist, show the first-time pop-up message
        setShowBranchNamePopup(false);
        setShowFirstTimePopup(true);

      }

      const payload = {
        branch: selectedBranch,
        secretName: `cs/v3/frontend/${selectedBranch}`,
        secretValue: convertArrayToObject(keyValuePairs),
      };

      const response = await fetch('https://devlift.stage.multisafe.finance/api/deploy', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('API deployed successfully:', payload);

        if (buildNumber === null) {
          const { buildNumber } = responseData;
          setBuildNumber(buildNumber);
          console.log('Build Number:', buildNumber);
        }

        setDeploying(true);
        setBuildStatusLinkVisible(true);
        if (deploySuccess === null) {
          console.log("iam here");
          checkBuildStatus(buildNumber);
        }

      } else {
        console.error('Failed to deploy API:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating API:', error);
    }
  };

  useEffect(() => {
    if (inheritFrom) {
      deployApiForInherit(inheritFrom);
    }
  }, [inheritFrom]);

  useEffect(() => {
    fetchBranches();
    fetchInheritValues();
  }, []);

  const [temp, setTemp] = useState(0)

  const  setTempCall = async() => {
    const intervalId =  setInterval( async() => {
      await checkBuildStatus(buildNumber);
    }, 3000);
    console.log(intervalId, "here")
    setTemp(intervalId)
  }

  useEffect(() => {
    // setTempCall()



    // setBuildCheckInterval(intervalId);
    // if (buildNumber !== null || true) {

      // console.log(intervalId)
      // Save the interval ID for cleanup


      // Perform initial check
      // checkBuildStatus(buildNumber);
      // Optionally, you may want to clear the interval on component unmount
      // return () => clearInterval(intervalId);
    // }
  }, []);


  const [data, setData] = useState(null);

  const fetchData = async (intervalId) => {
    console.log(intervalId)
    try {
      // const response = await fetch('your_api_url_here');
      const result = {};
      if (result.success) {
        setData(result.data);
      } else {
        clearInterval(intervalId); // Clear interval if response is false
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval((intervalId) => fetchData(intervalId), 5000); // Call the API every 5 seconds

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
    
  }, []); // Empty dependency array to run effect only once on mount




  // console.log(buildCheckInterval)

  return (
    <div className='bg-white text-black'>
      
    </div>
  );
};

export default Test;
