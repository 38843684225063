import React from "react";
import { useNavigate } from "react-router-dom";
import SnackCard from "./SnackCard"; // Adjust import path based on your project structure

const HorizontalSliderCards = ({ items = [], heading = "", type}) => {
  const navigate = useNavigate();

  const handleSnackButtonPress = (item) => {
    navigate("/snack_game", { state: { type, ...item } }); // Pass parameters via state
  };

  return (
    <div className="flex overflow-x-auto py-4 hide-scrollbar">
      {items.map((item) => (
        // <div
        //   key={item.id}
        //   className="w-[200px] h-[300px] flex-shrink-0 rounded-lg overflow-hidden cursor-pointer"
        // >
          <SnackCard item={item} type={type}/>
        // </div>
      ))}
    </div>
  );
};

export default HorizontalSliderCards;
