import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { motion } from "framer-motion";

export default function RegistrationPageOne({
  user = {},
  loadUserData,
  handlePageState = () => {},
  inputVals,
  setInputVals,
}) {
  const navigate = useNavigate();
  const [isChecked, setChecked] = useState(false);

  const handleInputChange = (text, name) => {
    setInputVals({
      ...inputVals,
      [name]: text,
    });
  };

  const nextPage = async () => {
    if (!inputVals.phone_number) {
      alert("Please fill in your mobile number");
      return;
    }
    if (!isChecked) {
      alert("Please agree to the terms and conditions");
      return;
    }
    handlePageState("registration-two");
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
        style={{backgroundColor: "#7871FF", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    <div style={styles.container}>
      {/* Header Section */}
      <header style={styles.header}>
        <button
          onClick={() => handlePageState("terms-and-condition")}
          style={styles.backButton}
        >
          <img
            src={require("../../Assets/left-arrow.png")}
            alt="Back"
            style={styles.backIcon}
          />
        </button>
        <h1 style={styles.headerTitle}>Creator Profile Registration</h1>
      </header>

      <div style={styles.content}>
        <div style={styles.intro}>
          <h2>Sign up as a creator for publishing your First Snack!</h2>
          <img
            src={require("./assets/snackr-logo.png")}
            alt="Snackr Logo"
            style={styles.logo}
          />
        </div>

        {/* Input Fields */}
        <div style={styles.form}>
          <label style={styles.formLabel}>Display Name</label>
          <input
            type="text"
            name="username"
            value={user.username}
            onChange={(e) => handleInputChange(e.target.value, "username")}
            placeholder="Display Name"
            style={styles.formInput}
          />

          <label style={styles.formLabel}>Email</label>
          <input
            type="email"
            name="email"
            value={user.email}
            readOnly
            placeholder="Email"
            style={styles.formInput}
          />

          <label style={styles.formLabel}>Mobile</label>
          <div style={styles.phoneContainer}>

          <style>{`
            .react-tel-input .flag-dropdown .country-list {
              background-color: #ffffff; /* Optional: Set background color of the dropdown */
              color: black; /* Default text color for dropdown items */
            }

            .react-tel-input .flag-dropdown .country-list .country {
              color: black; /* Set text color for each country item */
            }

            .react-tel-input .flag-dropdown .country-list .country:hover {
              background-color: #f0f0f0; /* Optional: Highlight on hover */
              color: black; /* Ensure hover text color remains black */
            }
          `}</style>
            <PhoneInput
              placeholder="12345 67890"
              country="India"
              defaultValue={user.phone_number}
              onChange={(value) => handleInputChange(value, "phone_number")}
              countryCodeEditable={false}
              inputStyle = {styles.textInput}
            />
          </div>

          <div style={styles.checkboxContainer}>
            <input
              type="checkbox"
              id="whatsappConsent"
              checked={isChecked}
              onChange={() => setChecked(!isChecked)}
              style={styles.checkbox}
            />
            <label htmlFor="whatsappConsent" style={styles.checkboxLabel}>
              I agree to be connected via WhatsApp for App and Content-related
              communication.
            </label>
          </div>
          <div className="flex justify-center" style={{marginBottom: "80px"}}>
            <button onClick={nextPage} 
              className="w-[100px] px-8 py-3 text-lg font-bold text-white bg-transparent border border-white rounded-lg hover:bg-white hover:text-purple-700 transition"
              style={{margin: 10, padding: 6}}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
}

const styles = {
  container: {
    background: "#6242F9",
    color: "white",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Arial, sans-serif",
    
  },
  header: {
    background: "#7871FF",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    position: "sticky",
    top: 0,
    borderRadius: "0px 0px 5px 5px"
  },
  backButton: {
    background: "none",
    border: "none",
    cursor: "pointer",
    marginRight: "20px",
  },
  backIcon: {
    width: "12px",
    height: "16px",
  },
  headerTitle: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  content: {
    padding: "20px",
    flex: 1,
    overFlowY: "auto",
    
  },
  intro: {
    textAlign: "center",
    marginBottom: "20px",
    fontWeight: "bold"
  },
  logo: {
    width: "320px",
    height: "100px",
    margin: "0px 20px",
  },
  form: {
    maxWidth: "400px",
    margin: "0 auto",
  },
  formLabel: {
    display: "block",
    marginBottom: "8px",
    fontSize: "16px",
  },
  formInput: {
    width: "100%",
    padding: "10px",
    marginBottom: "20px",
    border: "1px solid #969696",
    borderRadius: "8px",
  },
  phoneInput: {
    width: "100%",
    marginBottom: "20px",
  },
  phoneContainer: {
    width: "100%",
    height: 50,
    borderRadius: 12,
    border: "1px solid #969696",
    backgroundColor: "#F5F5F5",
    display: "flex",
    alignItems: "center",
    padding: "0 10px",
  },
  textInput: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    border: "none",
    fontSize: 16,
    color: "#969696",
    outline: "none",
    paddingLeft: 50,
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    margin: "20px 0px 20px 0px",
  },
  checkbox: {
    marginRight: "8px",
  },
  checkboxLabel: {
    fontSize: "14px",
  },
  nextButton: {
    width: "100px",
    padding: "12px",
    background: "transparent",
    border: "2px solid white",
    color: "white",
    borderRadius: "12px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background 0.3s",
    marginBottom: "100px"
  },
  nextButtonHover: {
    background: "white",
    color: "#7871FF",
  },
};
