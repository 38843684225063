import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SnackContainer from "./SnackContainer";
import axios from "axios";
import { API, BASE_URL } from "constant";
import { Store as walletStore } from 'StoreContext/wallatStore'
import { headers } from "helpers";


//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================


function Reel(props) {

      //==============================================
      const location = useNavigate()
      const history = useLocation()

      const {  snack_id, snack_name, snack_type } = props

    //====================================================
    //Analytics
    //====================================================
    //====================================================


    useEffect(() => {
        sendAnalytics(`Snacked shots`, {
            // game_name : snack_name,
            [snack_name]: _store_wallet?.state?.user?.username || "Guest"
        })
        sendAnalytics(`screen_view`, {
            firebase_screen: snack_name,
            firebase_screen_class: "Snack Page"
        })
    }, []);

    //====================================================
    //====================================================

      const [reelData, setReelData] = useState({})
  
      const closeCallback = () => {
          //    console.log(location)
          if (history.key !== "default") {
  
              location(-1)
          } else {
              location("/")
          }
          // console.log(location(-1))
      }

      const getReelData = async() => {
        try{

            const res = await axios.get(`${API}/reel/one/${snack_id}`)
            setReelData(res?.data?.data || {})

            

            // console.log(res)
        } catch(ex){

        }
      }

      useEffect(() => {
        
        getReelData()

      }, [])

    
    //==================================================
    //==================================================
    //function to update user snack Score
    let _store_wallet = useContext(walletStore)

    const updateWallet = async(point = 0) => {

        try{

            //call api to update strapi
            const res = await axios.post(`${API}/snackr/user/blahcoin`,{
                point : point,
                snack_type : snack_type,
                snack_id : snack_id
            }, { headers: headers() })

            // get coin number

            const BlahCoin = res?.data?.BlahCoin

            if(BlahCoin){

                _store_wallet.dispatch({
                    type: 'updateBlahCoin',
                    payload: { BlahCoin }
                })

            }
                
        } catch(ex){

        }
    }

    const updateInterval = 3000;  //update snack count every 30 seconds

    useEffect(() => {
       
        const intervalId = setTimeout(() => {
            updateWallet(5)
        }, updateInterval);


      
        return () => {
            clearTimeout(intervalId);
         
        };
    }, []);

    const increasePlayCount = async () => {
        try {
            const response = await axios.get(`${API}/snackr/updateSnackPlayCount?id=${snack_id}&type=${snack_type}`);

            // console.log(data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        increasePlayCount()
    }, [])



      return (
        <SnackContainer closeCallback={closeCallback} >
            <div className="w-full h-full lg:max-w-[400px]  bg-black flex flex-col justify-center items-center">
                {reelData?.video?.url &&
                <video className="" controls autoPlay={true} >
                    {console.log(reelData)}
                    <source src={`${BASE_URL}${reelData?.video?.url}`} type="video/mp4" />
                </video>
                }
            </div>
        </SnackContainer>
    );
}

export default Reel;