import React from "react";

export const CommonTextInputV2 = ({
  value = "",
  name = "inputKey",
  handleInputChange,
  placeholder = "",
  secureTextEntry = false,
  _className = "",
  ...props
}) => {
  return (
    <input
      type={secureTextEntry ? "password" : "text"}
      value={value}
      onChange={(e) => handleInputChange(e.target.value, name)}
      className={`rounded-[10px] w-full h-[45px] border-2 border-zinc-200 pl-[10px] font-bold text-zinc-800 placeholder:text-slate-400 focus:border-violet-300 ${_className}`}
      placeholder={placeholder}
      {...props}
    />
  );
};


export const CommonTextFieldV2 = ({
  value = "",
  name = "inputKey",
  handleInputChange,
  placeholder = "",
  secureTextEntry = false,
  _className = "",
  ...props
}) => {
  return (
    <textarea
      value={value}
      onChange={(e) => handleInputChange(e.target.value, name)}
      className={`rounded-[10px] h-[100px] w-full align-top py-[10px] border-2 border-zinc-200 pl-[10px] font-bold placeholder:text-slate-400 focus:border-violet-300 ${_className}`}
      placeholder={placeholder}
      {...props}
    ></textarea>
  );
};

