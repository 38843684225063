
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { AvatarRound } from "../../Components/AvatarRound";
import { API, Frontend_URL } from "../../constant";
import { PrimaryButton } from "Components/ButtonPrimary";
import { TruncateText } from "../../helpers";
import HorizontalSliderCards from "../../Components/Discover/HorizontalSliderCard";
import { getToken } from "../../helpers";
import { useAuthContext } from "Auth/AuthContext";
import { useUI } from "UIContext/UIContext";

function Publisher() {
  // const { id } = useParams();
  const {setModal} = useUI()
  const location = useLocation();
  const { id } = location.state || {};
  console.log(id)
  const navigate = useNavigate()
  const { user } = useAuthContext();

  const [publisherData, setPublisherData] = useState({});
  const [followStatus, setFollowStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  const games = publisherData?.published_games || [];
  const comics = publisherData?.published_comics || [];
  const reels = publisherData?.published_reels || [];
  const _avatarImageURL =
    publisherData?.avatar_publisher_url ||
    `${Frontend_URL}/extra/images/profile/human/Asset 4.png`;

  const isFollowed = user?.following_publishers?.find(
    (item) => item.id === publisherData?.id
  );

  useEffect(() => {
    setFollowStatus(!!isFollowed);
  }, [isFollowed]);

  const fetchDetails = async () => {
    try {
      const res = await axios.get(`${API}/snackr/publisher/${id}`);
      setPublisherData(res?.data?.publisher || {});
    } catch (err) {
      console.error(err.response);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const onFollow = async () => {
    if (!user) {
      setModal("login_prompt")
      // navigate("/login")
      // return;
    }

    setLoading(true);

    try {
      const token = await getToken();
      const res = await axios.post(
        `${API}/snackr/user/updateFollowingPublishers`,
        {
          status: !followStatus,
          publisher_id: publisherData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const newFollowingPublishers = res?.data?.following_publishers;
      user.dispatch({
        type: "SET_FOLLOWING_PUBLISHERS",
        payload: newFollowingPublishers,
      });
      await fetchDetails();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const following_publishers = publisherData?.following_publishers?.length || 0;
  const followers = publisherData?.followers?.length || 0;
  const submitted_user_snacks = publisherData?.submitted_user_snacks || [];
  const published_user_snacks = submitted_user_snacks.filter(
    (snack) => snack.status === "Published"
  );
  const published_user_snacks_count = published_user_snacks.length;

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll bg-white' 
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    <div className="flex-1 bg-[#0E0C1C] min-h-screen">
      <div className="pt-[50px]">
        {/* Page content */}
        <div className="px-[20px]">
          {/* Profile Section */}
          <div className="bg-[#412AAB] pb-2.5 rounded-[20px] overflow-hidden">
            <div className="h-[280px] px-[10px] py-[10px] flex flex-col justify-center bg-[#1A1A1A] border-2 rounded-[20px] border-[#8A6EFF]">
              <div className="h-full w-full px-[10px] py-[20px] flex flex-col justify-center">
                <div className="flex justify-between items-center">
                  {/* Avatar Section */}
                  <div className="mr-5">
                    <AvatarRound profilePic={_avatarImageURL} onPress={() => {}} />
                  </div>

                  {/* Stats Section */}
                  <div className="w-[300px]">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-[1.5rem] font-bold text-white text-center">
                          {published_user_snacks_count}
                        </p>
                        <p className="text-xs text-white text-center">Snacks</p>
                      </div>
                      <div>
                        <p className="text-[1.5rem] font-bold text-white text-center">
                          {followers}
                        </p>
                        <p className="text-xs text-white text-center">Followers</p>
                      </div>
                      <div>
                        <p className="text-[1.5rem] font-bold text-white text-center">
                          {following_publishers}
                        </p>
                        <p className="text-xs text-white text-center">Following</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Publisher Description */}
                <div className="flex justify-between items-center w-full mt-[20px]">
                  <div className="px-[10px]">
                    <p className="text-white">{publisherData?.username}</p>
                    <p className="text-xs w-full text-white">
                      {TruncateText(`${publisherData?.publisher_description || ""}`, 160)}
                    </p>
                  </div>
                </div>

                {/* Follow Button */}
                <div className="mt-[20px] w-full">
                  <PrimaryButton
                    className="w-full"
                    label={followStatus ? "Following" : "Follow"}
                    isLoading={loading}
                    onPress={onFollow}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Horizontal Cards */}
          <div className="mt-[10px]">
            <HorizontalSliderCards items={games} heading="Games" type="game" />
          </div>
          <div className="mt-[10px]">
            <HorizontalSliderCards items={comics} heading="Comics" type="comic" />
          </div>
          <div className="mt-[10px]">
            <HorizontalSliderCards items={reels} heading="Shots" type="reel" />
          </div>
        </div>

        {/* Footer Spacer */}
        <div className="h-[100px]"></div>
      </div>
    </div>
    </motion.div>
    </div>
  );
}

export default Publisher;
