

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BASE_URL } from "constant";
import { ImagePlaceholder } from "../../Components/ImagePlaceholder";

const CarouselComponent = ({ images = [] }) => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    centerMode: true, // Enables showing parts of the previous and next slides
    centerPadding: "15%", // Adjust padding to control the visibility of adjacent slides
    arrows: false, // Hide navigation arrows
    pauseOnHover: true,
    className: "custom-carousel",
  };

  return (
    <div className="mt-[10px] pt-[30px] pb-[30px] bg-white shadow-[3_35px_40px_-15px_rgba(0,0,0,0.8)] rounded-lg overflow-hidden"
    style={{
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
    }}
    >
      <Slider {...settings}>
        {images.map((item, index) => (
          <div
            key={index}
            className="transition-transform duration-300 flex justify-center items-center"
          >
            <ImagePlaceholder
              src={`${BASE_URL}${item?.cover_image?.url}`}
              alt={`Image ${index + 1}`}
              className="carousel-image w-full h-full rounded-lg cursor-pointer"
              onClick={() => {
                if (item.onPress) item.onPress(); // Handle item press if specified
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;

