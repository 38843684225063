import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Auth Utilities
import { getToken } from 'helpers';
import { API } from 'constant';

// Image Import
import leftArrow from '../../../Assets/left_arrow.png';

const SubmittedSnack = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { item } = location.state || {}; // Passed params

  const [data, setData] = useState({});

  // Fetch Snack Details
  const fetchDetails = async (snackId) => {
    try {
      const token = await getToken();
      const response = await axios.get(`${API}/submitted-user-snacks/${snackId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setData(response?.data?.data?.attributes);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (item?.id) fetchDetails(item?.id);
  }, [item?.id]);

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="bg-[#0E0C1C] min-h-screen">
      <div className="max-w-screen-md mx-auto px-6 py-4">
        {/* Header Section */}
        <div className="border-b border-purple-300 py-4 flex items-center">
          <button onClick={goBack} className="min-w-[48px] min-h-[48px] flex items-center justify-center">
            <img src={leftArrow} alt="Go Back" className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold text-white ml-4">Submitted Snack</h1>
        </div>

        {/* Snack Details */}
        <div className="mt-6">
          <p className="text-white text-lg mt-4">
            <strong>Name:</strong> {data?.snack_name || 'N/A'}
          </p>
          <p className="text-white text-lg mt-4">
            <strong>Game Description:</strong>
          </p>
          <p className="text-white text-lg mt-2">{data?.snack_description || 'No description available.'}</p>

          <p className="text-blue-600 bg-blue-100 py-2 px-4 rounded-lg mt-6 inline-block">
            {data?.play_count || 0} users snacked this content
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubmittedSnack;
