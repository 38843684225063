import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Frontend_URL } from "../../constant";
import { TruncateText } from "../../helpers";

import defaultImage from "../../Assets/user2.png"; // Update with the correct path to your image

const TopCreators = ({ title, creators, bgColor = "#f1f1f1" }) => {
  const [imageLoaded, setImageLoaded] = useState({});
  const navigate = useNavigate();
  console.log(creators)
  // Function to update the imageLoaded state when an image has been successfully loaded
  const handleImageLoad = (id, success) => {
    setImageLoaded((prev) => ({ ...prev, [id]: success }));
  };

  const handlePress = (publisher) => {
    // Navigate to the publisher's page
    navigate(`/publisher_screen`, { state: publisher });
  };

  return (
    <div style={{ backgroundColor: bgColor, paddingInline: "20px", borderRadius: "8px", marginBottom: "14px" }}>
      <h2 style={{ color: "#0E0C1C", fontSize: "15px", marginBottom: "12px" }}>
        {title}
      </h2>
      <div style={{ display: "flex", overflowX: "auto" }} className="hide-scrollbar">
        {creators.map((creator) => (
          <div
            key={creator.id}
            style={{ marginRight: "16px", display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <CreatorImage
              avatar_url={creator.avatar_publisher_url}
              handlePress={() => handlePress(creator)}
              id={creator.id}
              onLoad={(success) => handleImageLoad(creator.id, success)}
              isLoaded={imageLoaded[creator.id]}
            />
            <p style={{ color: "#0E0C1C", marginTop: "8px", textAlign: "center", fontSize: "12px" }}>
              {TruncateText(creator.username, 8)}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const CreatorImage = ({ avatar_url, id, onLoad = () => {}, isLoaded, handlePress }) => {
  const [hasError, setHasError] = useState(false);

  //const profile_url = `${Frontend_URL}${avatar_url || "/extra/images/profile/human/Asset 4.png"}`;
  const profile_url = avatar_url

  useEffect(() => {
    if (isLoaded === false) {
      // Re-attempt to load the image if it's not loaded yet
      setHasError(false);
    }
  }, [isLoaded]);

  return (
    <button
      onClick={handlePress}
      style={{
        border: "none",
        backgroundColor: "transparent",
        padding: "0",
        cursor: "pointer",
        outline: "none",
      }}
    >
      <div
        style={{
          width: "55px",
          height: "55px",
          borderRadius: "50%",
          backgroundColor: "white",
          border: "2px solid #e2e8f0",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          overflow: "hidden", // Ensure the image doesn't overflow the container
        }}
      >
        <img
          src={hasError ? defaultImage : profile_url}
          alt="Creator"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover", // Ensures the image covers the entire container
            objectPosition: "center", // Centers the image
          }}
          onError={() => {
            setHasError(true);
            onLoad(false); // Update the parent that the image failed
          }}
          onLoad={() => onLoad(true)} // Notify the parent when the image is loaded successfully
        />
      </div>
    </button>
  );
};

export default TopCreators;
