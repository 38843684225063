import React, {useContext} from "react";
import { Store as walletStore } from "StoreContext/wallatStore";

export default function CoinBadge() {
  let _store_wallet = useContext(walletStore)
  let coin_amount = _store_wallet?.state?.BlahCoin || 0
  const _coinImage = require("../../Assets/coin.png");

  return (
    <div
      className="coin-badge rounded-full"
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#0070E1",
        border: "2px solid #70B7FF",
        padding: "3px 3px",
        minWidth: "70px",
      }}
    >
      <img
        src={_coinImage}
        alt="Coin"
        style={{ width: "15px", height: "15px", marginRight: "5px" }}
      />
      <span className="text-white text-sm text-right">
        {coin_amount}
      </span>
    </div>
  );
}
