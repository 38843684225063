import { WalletButton } from "./WalletButton";

import {useContext, useEffect} from 'react'
import { Store as walletStore } from 'StoreContext/wallatStore'


function TopNav() {


   


    return (
        <div className="fixed top-0 left-0 w-full z-[400]">
        <div className="w-full mx-auto md:max-w-[400px] md:left-auto">
        {/* {console.log(_store_wallet)} */}
        
            <div className="z-[500] w-full  px-[12px] top-0 left-0 flex flex-col justify-top py-[5px] _bg-zinc-900 bg-gradient-to-b to-transparent _via-transparent from-black/50">
                <div className="flex justify-between items-center h-full w-full mt-[5px] ml-[8px]">
                    <WalletButton  />
                   
                </div>
            </div>
        </div>
        </div>

    );
}

export default TopNav;



