import React from "react";
import { useNavigate } from "react-router-dom";
import { WalletButton } from "Components/Navs/WalletButton";
import { Frontend_URL } from "constant";
import CoinBadge from "Components/Profile/CoinBadge";

export const ProfileDetailsCard = ({ user = {}, isLoggedIn }) => {
  const navigate = useNavigate();

  const following_publishers = user?.following_publishers?.length || 0;
  const followers = user?.followers?.length || 0;

  const submitted_user_snacks = user?.submitted_user_snacks || [];
  const published_user_snacks =
    submitted_user_snacks?.filter((snack) => snack.status === "Published") || [];
  const published_user_snacks_count = published_user_snacks.length;

  const gotoSettings = () => {
    console.log("Navigating to Settings");
    navigate("/settings");
  };

  const shareSnackRDetails = async () => {
    try {
      await navigator.share({
        title: "SnackR snacks",
        text: "Hey Check out SnackR.",
        url: "https://banzan.co/snackR_handler?redirectURL=snackr%3A%2F%2F",
      });
    } catch (ex) {
      console.error("Error sharing details:", ex);
    }
  };

  const handleFollowingClick = () => {
    navigate("/following_publishers")
  }

  const gotoEdit = () => {
    console.log("Navigating to Update Profile");
    navigate("/settings_update_profile");
  };

  const gotoAvatarEdit = () => {
    console.log("Navigating to Change Avatar");
    navigate("/settings_change_avatar");
  };

  const _avatarImageURL = `${Frontend_URL}${user?.avatar_url || "/extra/images/profile/human/Asset 4.png"}`;

  return (
    <div className="w-[100%]">
      <div>
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-xl font-bold text-black">
              {user?.username || "Guest user"}
            </h1>
            <p className="text-sm text-gray-500">
              {user?.email || "guestuser"}
            </p>
          </div>
          <div className="flex items-center">
            <span className="text-xs text-black">
              {user?.blahcoin_day_streak_count || "1"}🔥
            </span>
            <CoinBadge />
          </div>
        </div>
      </div>
      <div className="mt-6">
        <div className="px-4 py-4 shadow-xl bg-white rounded-xl">
          <div className="flex justify-between items-center">
            <div className="w-full">
              <div className="flex justify-between">
                <div>
                  <p className="text-xs text-gray-500 text-center">Snacks</p>
                  <p className="text-xl font-semibold text-black text-center">
                    {published_user_snacks_count}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-gray-500 text-center">Followers</p>
                  <p className="text-xl font-semibold text-black text-center">
                    {followers}
                  </p>
                </div>
                <div onClick={handleFollowingClick} className="cursor-pointer">
                  <p className="text-xs text-gray-500 text-center">Following</p>
                  <p className="text-xl font-semibold text-black text-center" >
                    {following_publishers}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsCard;
