import { useState, useContext, useEffect } from "react";
import axios from "axios";

import { Store as snackStore } from 'StoreContext/snackStore'
import { API } from "constant";

import Carousal from "./Carousal";
import Search from "./Search";
import MultipleSlider from "./MultipleSlider";
import { motion } from "framer-motion";

import { sendAnalytics } from 'firebaseConfig';
import TopCreators from "Components/Discover/TopCreators";
import HorizontalSliderCards from "Components/Discover/HorizontalSliderCard";
import { useTopSnackersList } from "TopSnackerContext/TopSnackersList";

function Discover() {
  const { allTimeCreators = [] , setAllTimeCreators } = useTopSnackersList()
  const [activeTab, setActiveTab] = useState("game");

    const fetchTopCreators = async() => {
        try{
          console.log("calling ...")
          const res = await axios.get(`${API}/snackr/topcreators`);
          console.log(res?.data?.top_creators_daily)

          const top_creators = res?.data?.top_creators_daily || [];
          // const top_creators = top_creator?.top_creator || [];
          // console.log(top_creators)
          setAllTimeCreators(top_creators)
          // console.log(allTimeCreators)
          
    
        }catch(ex){
          console.log(ex)
        }
      }

    useEffect(() => {
        sendAnalytics(`screen_view`, {
          firebase_screen: "Discover page",
          firebase_screen_class: "Discover page"
        })
      }, [])

    let _store_snack = useContext(snackStore)

    // const [Carousalimages, setCarousalImages] = useState([])


    const getTopSnack = (array = [], count) => {

        let val = _store_snack?.state?.allGames?.sort((a, b) => b.snack_count - a.snack_count) || [];

        if (val.length > 4) {
            val = val.slice(0, 4);
        }

        return val

    }

    const fetchAllSnacks = async () => {
        try {

            const res = await axios.get(`${API}/snackr/allSnacks`, {})
            let allGames = res?.data?.data?.games || []
            let allComics = res?.data?.data?.comics || []
            let allReels = res?.data?.data?.reels || []

            allGames = allGames.map(item => ({ ...item, type: "game" }))
            allComics = allComics.map(item => ({ ...item, type: "comic" }))
            allReels = allReels.map(item => ({ ...item, type: "reel" }))


            // console.log(res)

            _store_snack.dispatch({
                type: 'updateallGames',
                payload: { allGames: allGames }
            })
            _store_snack.dispatch({
                type: 'updateallComics',
                payload: { allComics: allComics }
            })
            _store_snack.dispatch({
                type: 'updateallReels',
                payload: { allReels: allReels }
            })

            // setvideos((oldVideos) => [...oldVideos, ...snacks]);


        } catch (ex) {

        }
    }

    useEffect(() => {
        fetchTopCreators()
        fetchAllSnacks()

    }, [])

    

    // console.log(_store_snack?.state?.allReels)

    
    return (
    <div className="fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[401] h-full bg-[#f1f1f1]">
    <motion.div 
        initial={{ opacity: 0, _translateY: "100vh" }}
        animate={{ opacity: 1, _translateY: "0vh" }}
        exit={{ opacity: 0, _translateY: "100vh" }}
        transition={{ type: "Tween", stiffness: 50 }}
        className='fixed  md:max-w-[400px] md:left-auto  top-0 left-0 right-auto z-[550] bg-[#f1f1f1] _bg-zinc-900 h-full w-full  '>
        {/* Search */}
        {/* {console.log(getTopSnack())} */}
        <Search />

        
        <div className="overflow-y-scroll h-[90vh]">
        <TopCreators title={"Top Creators"} creators={allTimeCreators}/>
        <Carousal images={getTopSnack()} />
        {/* slider */}
        {/* {console.log(_store_snack.state)} */}

        
            <div className="mt-[20px]"></div>
            <DiscoverTab
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                heading="Trending"
            />
            {
            activeTab === "game" && <HorizontalSliderCards items={_store_snack.state?.allGames} heading="Games" />
            }
            { 
            activeTab === "comic" && <HorizontalSliderCards items={_store_snack.state?.allComics} heading="Comics" />
            }
            {
            activeTab === "shot" && <HorizontalSliderCards items={_store_snack.state?.allReels} heading="Shots" />
            }
            {/* <MultipleSlider items={_store_snack.state?.allGames} heading="Games" />
            <MultipleSlider items={_store_snack.state?.allComics} heading="Comics" />
            <MultipleSlider items={_store_snack?.state?.allReels} heading="Shots" /> */}
            <div className="h-[100px]"></div>
        </div>



        {/* category */}

    </motion.div>
    </div>
    );
}

export const DiscoverTab = ({ activeTab, setActiveTab, heading = "" }) => {
  const updateActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const Tab = ({ tabName, label, activeColor, inactiveColor, borderColor }) => {
    const isActive = activeTab === tabName;

    return (
      <div
        className={`grow ${
          isActive
            ? "border-t-4 border-x-4 rounded-t-xl"
            : "border-b-4"
        } border-slate-200 p-2  py-1 h-[120%]`}
      >
        <button
          onClick={() => updateActiveTab(tabName)}
          className={`${
            isActive
              ? `${activeColor} shadow-xl`
              : `${inactiveColor}`
          } ${borderColor} border-2 px-2 py-1 mt-2 rounded-3xl w-full`}
        >
          <span
            className={`text-center text-base ${
              isActive ? "text-white" : "text-black"
            }`}
          >
            {label}
          </span>
        </button>
      </div>
    );
  };

  return (
    <div>
      {heading && (
        <div className="pt-1 px-4">
          <h1 className="text-black text-center text-2xl font-bold">{heading}</h1>
        </div>
      )}
      <div className="w-full h-12 flex mt-4">
        <Tab
          tabName="game"
          label="Games"
          activeColor="bg-pink-500"
          inactiveColor="bg-pink-200"
          borderColor="border-[#FF668E]"
        />
        <Tab
          tabName="comic"
          label="Comics"
          activeColor="bg-blue-500"
          inactiveColor="bg-blue-200"
          borderColor="border-[#1089FB]"
        />
        <Tab
          tabName="shot"
          label="Shots"
          activeColor="bg-yellow-500"
          inactiveColor="bg-yellow-200"
          borderColor="border-[#FFA724]"
        />
      </div>
    </div>
  );
};

export const ImageCard = ({ item = {}, onPress = () => {} }) => (
  <button
    onClick={() => onPress(item || {})}
    className="h-[45vh] w-full bg-gray-200 rounded-md overflow-hidden"
  >
    {item?.carousal_image?.url && (
      <img
        src={`${process.env.REACT_APP_BASE_URL}${item.carousal_image.url}`}
        alt="carousel"
        className="w-full h-full object-cover"
      />
    )}
  </button>
);


export default Discover;

