export const ButtonSecondary = ({ onClick = () => {}, children, className, ...props }) => {

    return (<div
        onClick={(e) => onClick(e)}
        className={` p-[5px] px-[10px] bg-slate-300 rounded-[20px] w-fit text-[12px] font-bold  ${className} `}
        {...props}
        >
        {children}
    </div>);


};

export const SecondaryButton = ({ isLoading, label, className = "", onClick }) => {
  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className={`rounded-full overflow-hidden bg-gradient-to-r from-gray-300 to-gray-100 text-center py-2 font-bold text-white ${
        isLoading ? "opacity-50 cursor-not-allowed" : "hover:from-gray-400 hover:to-gray-200"
      } ${className}`}
    >
      {isLoading ? "..." : label}
    </button>
  );
};

export const SecondaryButtonBoxyBlack = ({
  disabled,
  isLoading,
  label,
  _className = "",
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`rounded-[10px] bg-black border-2 border-slate-400 text-white font-bold text-[1rem] text-center py-[1vh] px-[4vw] ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
      } transition duration-200 ${_className}`}
    >
      {isLoading ? "..." : label}
    </button>
  );
};

export const SecondaryButtonBoxyWhite = ({
  disabled,
  isLoading,
  label,
  _className = "",
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`rounded-[10px] bg-white text-black text-[1rem] text-center py-[14px] px-[30px] ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
      } transition duration-200 ${_className}`}
    >
      {isLoading ? "..." : label}
    </button>
  );
};

export const SecondaryButtonBoxyBlue = ({
  disabled,
  isLoading,
  label,
  _className = "",
  onClick,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`rounded-[10px] bg-[#7355FF] overflow-hidden font-bold text-white text-[1rem] text-center py-[6px] px-[30px] ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
      } transition duration-200 ${_className}`}
      style={{backgroundColor: "#7355FF"}}
    >
      {isLoading ? "..." : label}
    </button>
  );
};