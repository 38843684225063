import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export default function LoginPrompt() {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    // Slide up animation on mount
    setIsVisible(true);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      // Additional cleanup if needed
    }, 400);
  };

  const goToLogin = () => {
    handleClose();
    console.log("Navigating to login...");
    // Replace with your routing logic
    navigate("/login")
  };

  const pressNotNow = () => {
    handleClose();
    console.log("Not now pressed");
  };

  const promptText = [
    "Coins and progress, both need saving! Log in to grab 'em.",
    "Coins and progress? One login seals the deal!",
    "Log in now. Your coins are lonely without you!",
    "Don’t miss out! Log in, earn coins, and lock in your progress.",
  ];

  const getRandomFromPrompt = (givenArray = []) => {
    const index = Math.floor(Math.random() * givenArray.length);
    return givenArray[index];
  };

  return (
    <div className="fixed inset-0 bg-black/80 flex items-end justify-center z-50">
      <motion.div
        className="w-full max-w-md bg-white rounded-t-3xl p-6 shadow-lg"
        initial={{ translateY: "100%" }}
        animate={{ translateY: isVisible ? "40%" : "100%" }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <div className="relative">
          {/* Close Button */}
          <button
            className="absolute top-[-10px] right-[-10px] w-10 h-10 bg-gray-300 rounded-full flex items-center justify-center text-black font-bold"
            onClick={handleClose}
          >
            ✖
          </button>

          {/* Heading and Prompt */}
          <h2 className="text-center text-xl font-bold">LOGIN</h2>
          <p className="text-center text-sm text-gray-700 mt-2">
            {getRandomFromPrompt(promptText)}
          </p>

          {/* Buttons */}
          <div className="mt-6 space-y-4">
            <button
              className="w-full bg-blue-600 text-white py-3 rounded-lg font-bold"
              onClick={goToLogin}
            >
              Login
            </button>
            <button
              className="w-full bg-white border border-blue-600 text-blue-600 py-3 rounded-lg font-bold"
              onClick={pressNotNow}
            >
              Not now
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
