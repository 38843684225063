import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant";
import bgLeaderboard from "../../Assets/bg_leaderboard.png";
import iconLeft from "../../Assets/left-arrow.png";
import { motion } from "framer-motion";

const SavedSnacks = ({handlePageState = () => {}, user}) => {
  const navigate = useNavigate();
  const bookmarkedGames = user?.bookmarked_games || [];
  const bookmarkedReels = user?.bookmarked_reels || [];
  const bookmarkedComics = user?.bookmarked_comics || [];
  const reverseArr = (input) => input.slice().reverse();

  const reversedGames = reverseArr(bookmarkedGames);
  const reversedReels = reverseArr(bookmarkedReels);
  const reversedComics = reverseArr(bookmarkedComics);

  const goToSnack = (item) => {
    if (item.type == "game" && item.game_url) {
      navigate(`/?game_url=${item.game_url}&&snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "comic") {
      navigate(`/?snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "reel") {
      navigate(`/?snack_type=${item.type}&&item.id=${item.id}&&snack_name=${item.title}`)
    }
  };

  const goBack = () => {
    handlePageState("settings");
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
    style={{backgroundImage: `url(${bgLeaderboard})`,}}
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto'>
    <div className="flex flex-col min-h-screen bg-[#0E0C1C]">
      <div
        className="flex-grow relative"
        style={{
          backgroundImage: `url(${bgLeaderboard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Header */}
        <div className="px-[10px] py-[5px]">
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={iconLeft}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">Saved Snacks</h1>
        </div>
      </div>

        {/* Content */}
        <div className="px-4 pb-20">
          {/* Games */}
          {reversedGames.length > 0 && (
            <div>
              <h2 className="text-white font-bold text-lg mt-4 mb-2">Games</h2>
              {reversedGames.map((item, index) => {
                const avatarImageURL = `${BASE_URL}${item?.cover_image?.url || "/extra/images/profile/human/Asset 4.png"}`;

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-[#412AAB] py-3"
                  >
                    <button
                      onClick={() => goToSnack(item)}
                      className="flex items-center w-full space-x-4"
                    >
                      <img
                        src={avatarImageURL}
                        alt="Game Cover"
                        className="w-10 h-10 rounded"
                      />
                      <span className="text-white">{item?.title || "Name"}</span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          {/* Comics */}
          {reversedComics.length > 0 && (
            <div>
              <h2 className="text-white font-bold text-lg mt-4 mb-2">Comics</h2>
              {reversedComics.map((item, index) => {
                const avatarImageURL = `${BASE_URL}${item?.cover_image?.url || "/extra/images/profile/human/Asset 4.png"}`;

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-[#412AAB] py-3"
                  >
                    <button
                      onClick={() => goToSnack(item)}
                      className="flex items-center w-full space-x-4"
                    >
                      <img
                        src={avatarImageURL}
                        alt="Comic Cover"
                        className="w-12 h-12 rounded"
                      />
                      <span className="text-white">{item?.title || "Name"}</span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}

          {/* Shots */}
          {reversedReels.length > 0 && (
            <div>
              <h2 className="text-white font-bold text-lg mt-4 mb-2">Shots</h2>
              {reversedReels.map((item, index) => {
                const avatarImageURL = `${BASE_URL}${item?.cover_image?.url || "/extra/images/profile/human/Asset 4.png"}`;

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-[#412AAB] py-3"
                  >
                    <button
                      onClick={() => goToSnack(item)}
                      className="flex items-center w-full space-x-4"
                    >
                      <img
                        src={avatarImageURL}
                        alt="Reel Cover"
                        className="w-12 h-12 rounded"
                      />
                      <span className="text-white">{item?.title || "Name"}</span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default SavedSnacks