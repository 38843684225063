import axios from "axios";
import { GoogleLogin } from "@react-oauth/google";
import { API } from "constant";
// import { useUser } from "./AuthContext";
// import { useEconomy } from "../StateManagement/EconomyContext";
import { setToken } from "helpers";
import { useAuthContext } from "./AuthContext";
import { useNavigate } from "react-router-dom";

export const SignInWithGoogle = async (googleToken) => {

    const { setUser } = useAuthContext()
    const navigate = useNavigate()
  try {
    // Send the Google token to the backend for verification and to receive app JWT
    const response = await axios.post(`${API}/snackr/user/google`, {
      token: googleToken,
    });

    const data = response?.data;

    // Store the app's JWT token
    await setToken(data.jwt);

    // Load user and economy data
    // await loadUserData();
    // await initEconomyData();

    // set the user
    setUser(data.user);
        
    console.log(`Welcome back ${data.user.username}!`);
    navigate("/")

    console.log("Google Sign-In successful!");
  } catch (error) {
    console.error("Error during Google Sign-In:", error);
    alert("Something went wrong during Google Sign-In. Please try again.");
  }
};