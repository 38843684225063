import React, { useState } from "react";
import { Frontend_URL } from "constant";
import { TruncateText } from "helpers";
import { useAuthContext } from "Auth/AuthContext";
const placeholderUserImage = "https://via.placeholder.com/150";

const _bg_inner = "../../Assets/TopSnacker/bg_inner.png";
const _arrow_right = "./../Assets/TopSnacker/right-arrow.png";
const _arrow_left = "./../Assets/TopSnacker/left-arrow2.png";

const SnackerList = ({ daily = [], allTime = [] }) => {
  const { user } = useAuthContext();
  const [activeTab, setActiveTab] = useState("Daily");

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const isToday = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    return (
      date.getUTCFullYear() === today.getUTCFullYear() &&
      date.getUTCMonth() === today.getUTCMonth() &&
      date.getUTCDate() === today.getUTCDate()
    );
  };

  const findRanking = (data, user) => {
    const index = data.findIndex((item) => item.username === user.username);
    return index === -1 ? data.length : index;
  };

  const userData = {
    username: user?.username,
    blahcoin_today_earning:
      activeTab === "Daily"
        ? user?.blahcoin_today_earning
        : user?.blah_coin,
    avatar_url: user?.avatar_url,
  };

  return (
    
    <div className="md:max-w-[400px] w-full">
      <DisplyBubbleIndicator
        activeTab={activeTab}
        handleTabChange={setActiveTab}
      />
      <div className="px-4 pt-2 w-full rounded-[50px] h-[500px] overflow-y-auto hide-scrollbar bg-[#BFBDFF]">
          <div className="flex justify-between px-2">
            <h2 className="text-lg font-bold text-black text-center m-4">
            {activeTab === 'Daily' ? 'Daily Standings' : 'Monthly Standings'}
          </h2>
          <button
            onClick={() => changeActiveTab(activeTab === 'Daily' ? 'Monthly' : 'Daily')}
            className="w-5 h-5 mr-8"
          >
            {activeTab === 'Daily' ? (
              <img src={require("../../Assets/TopSnacker/right-arrow.png")} alt="Right Arrow" className="w-full h-full m-4" />
            ) : (
              <img src={require("../../Assets/TopSnacker/left-arrow2.png")} alt="Left Arrow" className="w-full h-full m-4" />
            )}
          </button>
        </div>
        <div className="h-[330px] overflow-auto hide-scrollbar">
          <ul>
            {(activeTab === "Daily" ? daily : allTime).map((item, index) => (
              <RenderItem item={item} index={index} />
            ))}
          </ul>
        </div>
        <div className="mt-4">
          <RenderItem
            index={findRanking(
              activeTab === "Daily" ? daily : allTime,
              user
            )}
            item={{ ...userData }}
            color_code="you"
          />
        </div>
      </div>
    </div>
  );
};

const DisplyBubbleIndicator = ({ activeTab, handleTabChange }) => {
  return (
    <div className="relative">
      <div className="w-[80px] mx-auto bg-[#BFBDFF] rounded-t-full top-1 pt-2">
        <div className="flex items-center justify-center rounded-t-md px-2 py-1 pb-2">
          <div
            className={`w-3 h-3 rounded-full ${
              activeTab === "Daily" ? "bg-[#6141F8]" : "bg-[#A693FF]"
            }`}
          />
          <div className="w-3 h-3 rounded-full bg-[#736FFF] ml-2" />
        </div>
      </div>
    </div>
  );
};

const RenderItem = ({ item, color_code = "default", index }) => {
  const avatarImageURL = `${Frontend_URL}${
    item?.avatar_url || "/extra/images/profile/human/Asset 4.png"
  }`;

  let color_index = color_code;

  if (index === 0) color_index = "1";
  else if (index === 1) color_index = "2";
  else if (index === 2) color_index = "3";

  const colors = {
    "1": "bg-[#FFCC00]",
    "2": "bg-[#DBDBDB]",
    "3": "bg-[#BF6D00]",
    you: "bg-[#6141F8]",
    default: "bg-white",
  };

  const textColors = {
    "1": "text-black",
    "2": "text-black",
    "3": "text-black",
    you: "text-[#FDBE60]",
    default: "text-black",
  };

  return (
    <li
      className={`flex items-center px-4 py-2 m-2 rounded-lg ${
        colors[color_index]
      } shadow-lg`}
    >
      <div className="w-8 h-8 rounded-full bg-white border-[1px] border-black flex items-center justify-center mr-3">
        <span className="font-bold text-black text-sm text-center">{index + 1 || ""}</span>
      </div>
      <img
        src={avatarImageURL}
        alt={item.username}
        className="w-10 h-10 rounded-full"
      />
      <p
        className={`ml-4 ${textColors[color_index]} text-base font-bold truncate`}
      >
        {TruncateText(item.username, 20)}
        {color_code === "you" ? " (YOU)" : ""}
      </p>
      <span
        className={`ml-auto text-base ${textColors[color_index]} font-bold`}
      >
        {item.blahcoin_today_earning || item.blah_coin}
      </span>
    </li>
  );
};

export default SnackerList;
