import React, { useEffect, useContext, useState } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { API } from 'constant';
// import Container from '../../Component/Container';

import { Store as wallatStore } from 'StoreContext/wallatStore'

import PopOverlay from 'Components/PopupOverlay';
import { Noitems } from './Noitems';
import { StoreItems } from './StoreItems';
import { RedeemPage } from './RedeemPage';
import { useAuthContext } from 'Auth/AuthContext';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from 'Components/ButtonPrimary';
import { WalletButton } from 'Components/Navs/WalletButton';
import Store_avatar from "Assets/BaBlah_instore.png"
import Bg from "Assets/profileBG.JPG"

//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================

import _image from "./assets/snacky.png";
import _bg from "./assets/bg.png";

function Store() {

    // let _store_wallet = useContext(wallatStore)

    const { user } = useAuthContext();



    useEffect(() => {
        sendAnalytics(`screen_view`, {
            firebase_screen: "Store page",
            firebase_screen_class: "Store page"
        })
    }, [])



    // const fetchStoreData = async () => {
    //     try {

    //         const res = await axios.get(`${API}/snackr/store/all`, {
    //             // headers: headers()
    //         })

    //         const storeData = res?.data?.storeData || []

    //         console.log(storeData)

    //         _store_wallet.dispatch({
    //             type: 'initStoreData',
    //             payload: { storeData: storeData }
    //         })
    //         // setvideos((oldVideos) => [...oldVideos, ...snacks]);


    //     } catch (ex) {

    //     }
    // }

    useEffect(() => {
        // fetchStoreData()
    }, [])

    // const _storeData = _store_wallet.state?.store_storeData || []


    //===========================================
    //TOp users
    //===========================================


    const [isCart, setIsCart] = useState(false)
    const [cartItem, setCartItem] = useState({})

    const openCartPage = (item = {}) => {
        setIsCart(true)
        setCartItem(item)
    }

    const closeCartPage = () => {
        setIsCart(false)
    }

    //===========================================
    //===========================================



    return (<div className='fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[600] h-full overflow-x-hidden '>
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 w-full left-0 top-0 bg-white z-[600] h-full " >
           

        <div className="flex-1 bg-white">
        {/* Header */}
        <div className="h-[80px] w-full bg-[#7871FF] ">
            <div>
            <h1 className="text-white text-xl font-bold px-[20px] pt-[25px]">
                Store
            </h1>
            </div>
        </div>

        {/* Content */}
        <div className="w-full h-full relative">
        <div classname="absolute top-[20px]">
            <img src={_bg} alt="Background" className="pt-[140px] w-full h-full scale-125 object-cover " />
            </div>
            {/* Snack Section */}
            <div className="absolute w-full top-0 bg-[#19113F] rounded-b-[20px] flex px-[30px] pt-[10px] items-end z-9">
            <div>
                <img
                src={_image}
                alt="Snacky"
                className="w-[150px] h-[100px] "
                />
            </div>
            <div className="mb-[20px] w-full items-right">
                <div className="relative flex justify-end items-center mr-[90px]">
                <WalletButton  />
                </div>
                <p className="text-right text-white mt-[30px] font-bold text-md">
                Snack shop coming soon...
                </p>
            </div>
            </div>

            {/* Background Image */}
            
        </div>
        
        </div>


                {/* </div> */}
                {/* </Container> */}


            </motion.div>

            {/* <PopOverlay isVisible={isCart} onClose={() => closeCartPage()} >

                <RedeemPage item={cartItem} onClose={() => closeCartPage()} />
            </PopOverlay> */}
        </div>);
}

const PromptLogin = () => {

    return (
        <div className="h-full _flex _flex-col justify-center items-center p-[20px] mt-[100px]">

            <div className=' w-full  bg-zinc-800 text-black  rounded-lg p-[20px] text-white text-center ' >
                <div className="">

                    Login for more  options

                </div>
                <div className="">

                    <div className="w-fit mx-auto mt-[20px]">
                        <Link to="/login">
                            <ButtonPrimary > Login </ButtonPrimary>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Store;


