import Share from "../Buttons/UI_Images/rightNav/share2.png"

export default function IconPlane  (props)  {

    const {snackType, gamename, id, game_url=""} = props

    return (<div className="cursor-pointer">
        <a href={`whatsapp://send?text=Checkout ${gamename} in SnackR. %0Ahttps%3A%2F%2Fsnackr.banzan.co%3Fgame_url%3D${game_url}%26%26snack_type%3D${snackType}%26%26snack_id%3D${id}%26%26snack_name%3D${gamename}%26%26`}
        data-action="share/whatsapp/share"
        target="_blank">
       
       <img src={Share} alt="Share Icon" className="w-[40px] h-[35px] m-[5px]" />    </a>
       
    </div>);
};
