import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BASE_URL } from 'constant';
import SnackButtonHook from '../../Hooks/SnackButtonHook';
import { ImagePlaceholder } from "../../Components/ImagePlaceholder";

function MultipleSlider(props) {

    const { items = [], heading = "" } = props

    const { onSnackClick } = SnackButtonHook()

    const sliderSettings = {
        dots: false,
        // centerMode: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 3, // Adjust the number of slides shown at once
        slidesToScroll: 1,
        swipeToSlide: true,
        focusOnSelect: true,
        // speed: 200,
        arrows: false, // Hide arrows
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    //   slidesToShow: 3,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    //   slidesToShow: 3,
                },
            },
        ],
    };

    // console.log(items)


    return (<div>
        <div className="max-w-screen-xl mx-auto px-[20px] pb-[20px] text-zinc-900">
            {
            (heading && items.length > 0) &&
                <div className="font-bold text-[15px] pb-[10px]">
                    {heading}
                </div>
            }
            <Slider {...sliderSettings}>
                {/* Your individual slides go here */}
                {items.map((item, index) => {
                    return (<div 
                        onClick={() => onSnackClick(item)}
                        className="px-[5px] " key={item?.id || index}>
                        <div className="h-[100px] rounded-md overflow-hidden bg-slate-200 ">

                            <ImagePlaceholder
                                className="w-full h-full object-cover   "
                                src={`${BASE_URL}${item?.cover_image?.url}`}
                                alt="Slide 1"
                            />
                        </div>
                    </div>)
                })}


                {/* Add more slides as needed */}
            </Slider>
        </div>

    </div>);
}

export default MultipleSlider;