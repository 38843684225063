import Comments from "../Buttons/UI_Images/rightNav/comment2.png"
import Like_active from "../Buttons/UI_Images/rightNav/like_active.png"

export default  function IconComment  ({ onClick=() => {} })  {

    return (<div 
        className="cursor-pointer">
        <div onClick={onClick} className="mx-auto w-fit ">

            <img src={Comments} alt="Comment Icon" className="w-[35px] h-[35px] m-[5px]" />
        </div>
    </div>);
};
 