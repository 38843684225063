import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SnackContainer from "./SnackContainer";
import axios from "axios";
import { API, BASE_URL } from "constant";
import { Store as walletStore } from 'StoreContext/wallatStore'
import { headers } from "helpers";


//=============================================
//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================



function Comic(props) {



    //==============================================
    const location = useNavigate()
    const history = useLocation()

    const { snack_id, snack_name, snack_type } = props


    let _store_wallet = useContext(walletStore)
    //====================================================
    //Analytics
    //====================================================
    //====================================================
    const [startTime, setStartTime] = useState(Date.now());

    
    
    useEffect(() => {
        sendAnalytics(`Snacked Comic`, {
            // game_name : snack_name,
            [snack_name]: _store_wallet?.state?.user?.username || "Guest"
        })
        sendAnalytics(`screen_view`, {
            firebase_screen: snack_name,
            firebase_screen_class: "Snack Page"
        })

        return () => {
            const endTime = Date.now();
            const durationInSeconds = (endTime - startTime) / 1000;
      
            // Log custom event to track time spent on screen/page
            sendAnalytics('Snacked comic timespent', {
             
              [snack_name]: durationInSeconds
            });
          };

    }, []);
    
    //====================================================
    //====================================================
    
    const [comicData, setComicData] = useState({})

    const closeCallback = () => {
        //    console.log(location)
        if (history.key !== "default") {

            location(-1)
        } else {
            location("/")
        }
        // console.log(location(-1))
    }

    const getComicData = async () => {
        try {

            const res = await axios.get(`${API}/comic/one/${snack_id}`)
            setComicData(res?.data?.data || {})



            // console.log(res)
        } catch (ex) {

        }
    }



    useEffect(() => {

        getComicData()

    }, [])

    //==================================================
    //==================================================
    //function to update user snack Score

    const updateWallet = async (point = 0) => {

        try {

            //call api to update strapi
            const res = await axios.post(`${API}/snackr/user/blahcoin`, {
                point: point,
                snack_type: snack_type,
                snack_id: snack_id
            }, { headers: headers() })

            // get coin number

            const BlahCoin = res?.data?.BlahCoin

            if (BlahCoin) {

                _store_wallet.dispatch({
                    type: 'updateBlahCoin',
                    payload: { BlahCoin }
                })

            }

        } catch (ex) {

        }
    }

    const updateInterval = 3000;  //update snack count every 30 seconds

    useEffect(() => {

        const intervalId = setTimeout(() => {
            updateWallet(5)
        }, updateInterval);



        return () => {
            clearTimeout(intervalId);

        };
    }, []);


    const increasePlayCount = async () => {
        try {
            const response = await axios.get(`${API}/snackr/updateSnackPlayCount?id=${snack_id}&type=${snack_type}`);

            // console.log(data)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        increasePlayCount()
    }, [])






    return (
        <SnackContainer closeCallback={closeCallback} >
            <div className="w-full h-full min-h-screen lg:max-w-[400px] bg-slate-100 overflow-scroll py-[50px] bg-gradient-to-br from-violet-100 to-violet-300">
                {comicData?.ComicStrip?.map(item => {
                    return (<div className="border-[5px] rounded-xl shadow-xl mb-[20px]">
                        <img src={`${BASE_URL}${item?.image?.url}`} className="w-full max-w-[400px] mx-auto  rounded-xl" />
                    </div>)
                })}
                <div className="w-full px-[20px]">

                    <div
                        onClick={() => closeCallback()}
                        className="text-center mx-auto rounded-xl my-[20px] text-white bg-violet-500 max-w-[400px]  py-[5px]">
                        Close
                    </div>
                </div>

            </div>
        </SnackContainer>
    );
}

export default Comic;