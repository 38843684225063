import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FilePicker } from 'react-file-picker';

export default function RegistrationPageThree({
  user = {},
  loadUserData,
  handlePageState = () => {},
  selectedFiles = [],
  setSelectedFiles,
  apicall_register,
}) {

  // Handle file selection
  const pickFiles = async (file) => {
    try {
      setSelectedFiles([...selectedFiles, file]);
    } catch (error) {
      console.error('Error picking document:', error);
    }
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
        style={{backgroundColor: "#7871FF", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    <div className="flex flex-col min-h-screen" style={{backgroundColor: "#6242F9"}}>
      <div
        className="bg-[#7871FF] flex flex-col w-full rounded-b-lg"
        style={{ padding: "20px" }}
      >
        <div className="p-4 flex items-center">
          <button
            className="p-2"
            onClick={() => {
              handlePageState('registration-two');
            }}
          >
            <img
              src={require("../../Assets/left-arrow.png")}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">
            Creator Profile Registration
          </h1>
        </div>
      </div>

      <div className="flex-1 p-6" style={{padding: 10}}>
        <div className="text-center">
          <h2 className="text-white text-xl font-bold" style={{margin: 10}}>Show us your work</h2>
        </div>

        <div
          className="flex flex-col items-center border-dashed border-4 border-white rounded-lg p-6 my-6"
          style={{ height: '300px', padding: 10, gap: 15, borderStyle: "dashed" }}
        >
          <img
            src={require("./assets/show-work.png")}
            alt="Show Work"
            className="w-1/2 h-40 my-4"
          />
          <p className="text-white font-semibold text-center">
            Upload a sample of the content you are planning to upload. It can be
            a gameplay video, comic PDF, or a short animation.
          </p>
          <FilePicker
            extensions={['pdf', 'mp4', 'jpg']}
            onChange={pickFiles}
            onError={(err) => console.log('FilePicker Error:', err)}
          >
            <button className="bg-black text-white px-4 py-2 mt-4 rounded-lg">
              Choose File
            </button>
          </FilePicker>
        </div>

        <div className="mt-4">
          {selectedFiles.map((file, index) => (
            <div
              key={index}
              className="bg-blue-100 text-blue-600 p-2 rounded my-2"
            >
              Selected File: {file.name}
            </div>
          ))}
        </div>

        <div className="text-center mt-8">
          <button
            onClick={apicall_register}
            className="relative px-6 py-3 text-lg font-semibold text-black rounded-lg shadow hover:opacity-90 transition"
            style={{backgroundImage: "linear-gradient(to right, #FFC94B, #FFBF3D)", height: "40px", width: "300px", padding: 5, marginTop: "70px" }}

          >
            Submit Your Application
          </button>
        </div>

      </div>
    </div>
    </motion.div>
    </div>
  );
}
