// contexts/ThemeContext.js
import React, { createContext, useState, useContext } from 'react';

const TopSnackersListContext = createContext();

export const TopSnackersProvider = ({ children }) => {

  const [dailySnackers, setdailySnackers] = useState([]);
  const [allTimeSnackers, setAllTimeSnackers] = useState([]);

  const [dailyCreators, setDailyCreators] = useState([]);
  const [allTimeCreators, setAllTimeCreators] = useState([]);

  return (
    <TopSnackersListContext.Provider 
        value={{ 
          dailySnackers, 
          setdailySnackers,  
          allTimeSnackers, 
          setAllTimeSnackers ,
          dailyCreators,
          setDailyCreators,
          allTimeCreators,
          setAllTimeCreators,
          
        }}>
      {children}
    </TopSnackersListContext.Provider>
  );
};

export const  useTopSnackersList = () => useContext(TopSnackersListContext);