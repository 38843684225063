

import { useContext, useEffect, useState } from "react";
import ChangePassword from "./ChangePasswordPage";
import SavedSnacks from "../SavedSnacks";
import DeleteUser from "./DeleteUser";
// import PrivacyPolicy from "./Settings/PrivacyPolicy";
import Feedback from "./Feedback";
// import RepeatTutorial from "./Settings/RepeatTutorial";
import { removeToken } from "helpers";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Store as wallatStore } from "StoreContext/wallatStore";
import axios from "axios";
import { headers } from "helpers";
import { API } from "constant";
import { useAuthContext } from "Auth/AuthContext";
import { useUI } from "UIContext/UIContext";

const _left_arrow = require("../../../Assets/left-arrow.png");
const _logout_icon = require("../../../Assets/logout_icon.png");
const _right_arrow = require("../../../Assets/right-arrow.png")

const SettingsPage = () => {
//   const { setFtueState } = useUI();
  // const [user, setUser] = useState()
  const navigate = useNavigate()
  const [pageState, setPageState] = useState("settings");
  const { user, loadUserData } = useAuthContext()
  let _store_wallet = useContext(wallatStore)
  const { setFtueState } = useUI()
  const fetchUserData = async () => {
    try {

        const res = await axios.get(`${API}/snackr/user/data`, {
            headers: headers()
        })
        const userData = res?.data?.user || {}

        //   console.log(res.data)

        _store_wallet.dispatch({
            type: 'initUser',
            payload: { user: userData }
        })
        // setvideos((oldVideos) => [...oldVideos, ...snacks]);
        // setUser(userData)
        console.log("user data: ",userData)
    } catch (ex) {

    }
  }

  useEffect(() => {
    loadUserData()
  }, [])

  const handleLogout = async () => {
    removeToken()
    navigate("/")
    window.location.reload(); // Reset to main after logout
  };

  const handlePageState = (newState) => {
    setPageState(newState);
    
  };

  const repeatTutorial = () => {
    handlePageState("repeat-tutorial")
    setFtueState("Home")
    navigate("/")
  }


//   return (
//     <div style={{ flex: 1, backgroundColor: "#7871FF" }}>
//       {pageState === "main" && (
//         <MainSettings handlePageState={handlePageState} handleLogout={handleLogout} />
//       )}
//       {pageState === "change-password" && <ChangePassword handlePageState={handlePageState} />}
//       {pageState === "saved-snacks" && <SavedSnacks handlePageState={handlePageState} />}
//       {pageState === "delete-account" && <DeleteUser handlePageState={handlePageState} />}
//       {pageState === "privacy-policy" && <PrivacyPolicy handlePageState={handlePageState} />}
//       {pageState === "give-feedback" && <GiveFeedback handlePageState={handlePageState} />}
//       {pageState === "repeat-tutorial" && <RepeatTutorial handlePageState={handlePageState} />}
//     </div>
//   );


// const MainSettings = ({ handlePageState, handleLogout }) => (
    return(
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll bg-white' 
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    {/* <div className="settings-container"> */}
    {pageState === "settings" && (
      <div style={styles.settingsPage} >
        {/* Header */}
        <div style={styles.settingsHeader} >
          <button onClick={() => navigate("/profile")}>
          <img
              src={_left_arrow}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          {/* <h1 style={styles.settingsTitle}>SETTINGS</h1> */}
          <h1 className="text-white text-lg font-bold ml-4">
            SETTINGS
          </h1>
        </div>

        {/* Content */}
        <div style={styles.settingsContent}>
          <Section title="ACCOUNT">
            <Item
              text="Change Password"
              onClick={() => handlePageState("change-password")}
            />
            <Item
              text="Saved Snacks"
              onClick={() => handlePageState("saved-snacks")}
            />
            <Item text="Privacy Policy" disabled />
            <Item
              text="Delete Account"
              onClick={() => handlePageState("delete-account")}
              danger
            />
          </Section>

          <Section title="ABOUT">
            <Item text="Contact" disabled />
            <Item text="Credits" disabled />
          </Section>

          <Section title="SUPPORT">
            <Item
              text="Repeat Tutorial"
              onClick={() => repeatTutorial()}
            />
            <Item
              text="Give Feedback"
              onClick={() => handlePageState("feedback")}
            />
            <Item
              text="Report An Issue"
              onClick={() => handlePageState("report-issue")}
            />
          </Section>

          {/* Logout Button */}
          <div style={{marginBottom: "100px"}} >
            <button style={styles.logoutButton} className="flex flex-row w-full" onClick={() => {handleLogout()}}>
            <img
                src={_logout_icon}
                alt="logout"
                style={{
                    width: "18px",
                    height: "18px",
                    marginRight: 8
                }} />
            <p style={{ fontWeight: "bold", color: "red" }}>Log out</p>

            </button>
          </div>
        </div>
      </div>
      
    )}

    {/* Dynamic Rendering of Pages */}
    {pageState === "change-password" && (
      <ChangePassword handlePageState={handlePageState}/>
    )}
    {pageState === "saved-snacks" && (
      <SavedSnacks handlePageState={handlePageState} user={user}/>
    )}
    {pageState === "delete-account" && (
      <DeleteUser handlePageState={handlePageState} user={user}/>
    )}
    {/* {pageState === "repeat-tutorial" && (
      <RepeatTutorial handlePageState={handlePageState} />
    )} */}
    {pageState === "feedback" && <Feedback handlePageState={handlePageState} user={user}/>}
    {pageState === "report-issue" && <Feedback handlePageState={handlePageState} user={user} title={"Report An Issue"}/> }
  {/* </div> */}
  </motion.div>
  </div>
// );
)
}

const Section = ({ title, children }) => (
    <div style={{ marginBottom: 16 }}>
      <p style={{ fontSize: 16, marginBottom: 8, color: "black" }}>{title}</p>
      {children}
      <div style={{ height: 1, backgroundColor: "#ccc", marginTop: 8, marginBottom: 8 }} />
    </div>
  );
  

const Item = ({ text, onClick, disabled, danger }) => (
    <div>
        <button
            onClick={onClick}
            disabled={disabled}
            className="w-full"
            style={{
                display: "flex",
                alignItems: "center",
                background: "white",
                justifyContent: "space-between",
                border: "0.1px solid rgba(222, 226, 230, 0.8)",
                padding: "10px 20px",
                textAlign: "left",
                fontSize: 16,
                borderRadius: 8,
                marginBottom: 10,
                cursor: disabled ? "not-allowed" : "pointer",
                opacity: disabled ? 0.5 : 1,
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
            }}
        >
            <p style={{ color: danger ? "red" : "#7871FF" }}>{text}</p>
            
        <img
            src={_right_arrow}
            alt="Arrow"
            style={{
            width: "12px",
            height: "16px",
            marginLeft: "auto",
           
            }}
        />

        </button>
  </div>
);


const styles = {
    settingsPage: {
      flex: 1,
    },
    settingsHeader: {
      display: "flex",
      alignItems: "center",
      padding: 22,
      paddingLeft: 30,
      backgroundColor: "#7871FF",
      borderRadius: "0px 0px 8px 8px"
    },
    settingsTitle: {
      fontSize: 24,
      fontWeight: "bold",
    },
    settingsContent: {
      padding: 20,
    },
    section: {
      marginBottom: 20,
    },
    sectionTitle: {
      fontSize: 18,
      fontWeight: "bold",
      marginBottom: 10,
    },
    item: {
      display: "block",
      background: "white",
      color: "#7871FF",
      border: "none",
      padding: 15,
      textAlign: "left",
      fontSize: 16,
      borderRadius: 8,
      marginBottom: 10,
      cursor: "pointer",
    },
    danger: {
      color: "red",
    },
    disabled: {
      background: "#ccc",
      color: "#888",
      cursor: "not-allowed",
    },
    logoutSection: {
      margin: 20,
      marginBottom: "100px"
    },
    logoutButton: {
      display: "flex-row",
      alignItems: "center",
      justifyContent: "left",
      color: "white",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",
      padding: "15px 20px",
      textAlign: "left",
      fontSize: 16,
      borderRadius: 8,
      cursor: "pointer",
      border: "0.1px solid rgba(222, 226, 230, 0.8)",
     
    },
  };

export default SettingsPage;
