import React from "react";

export const IconSettings = ({ onClick = () => {} }) => {
  const _icon = require("../../Components/Profile/assets/btn_settings.png"); // Adjust the path as needed

  return (
    <button
      onClick={onClick}
      className="w-[32px] h-[32px] rounded-full flex justify-center items-center"
    >
      <div className="bg-[#916EFF] rounded-full border-[#BCA7FF] border-2 overflow-hidden">
        <img src={_icon} alt="Settings" className="" />
      </div>
    </button>
  );
};

export const IconSettingsGear = ({ onClick = () => {} }) => {
  const _icon = require("../../Components/Profile/assets/gear.png"); // Adjust the path as needed

  return (
    <button
      onClick={onClick}
      className="w-[35px] h-[35px] rounded-full flex justify-center items-center"
    >
      <div className="bg-[#916EFF] p-[6px] rounded-full border-[#BCA7FF] border-2 ">
        <img src={_icon} alt="Settings Gear" className="" />
      </div>
    </button>
  );
};

export const IconDiscord = ({ onClick = () => {} }) => {
  const _icon = require("../../Components/Profile/assets/discord.png"); // Adjust the path as needed

  return (
    <button
      onClick={onClick}
      className="w-[35px] h-[35px] rounded-full flex justify-center items-center"
    >
      <div className="bg-[#916EFF] p-[6px] rounded-full border-[#BCA7FF] border-2">
        <img src={_icon} alt="Discord" className="h-[18px]" />
      </div>
    </button>
  );
};

export const IconFeedback = ({ onClick = () => {} }) => {
  const _icon = require("../../Components/Profile/assets/star.png"); // Adjust the path as needed

  return (
    <button
      onClick={onClick}
      className="flex flex-col items-center bg-[#412AAB] rounded-xl overflow-hidden"
    >
      <img src={_icon} alt="Feedback" className="w-[25px] h-[25px]" />
      <span className="ml-[10px] text-white text-[0.8rem] font-bold">
        Feedback
      </span>
    </button>
  );
};
