import React, { useEffect } from "react";
import { useUI } from "UIContext/UIContext";
import { SecondaryButtonBoxyBlue } from "Components/ButtonSecondary";
export default function MessageBox(props) {
  const { title = "", message = "" } = props;
  const { setModal } = useUI();

  const handleClose = () => {
    setModal("");
  };


  return (
    <div className="w-full h-full bg-black/80 absolute left-0 top-0 flex flex-col items-center justify-center">
      <div className="bg-white py-4 rounded-lg w-4/5 mx-auto shadow-lg px-6">
        {/* Title and Message */}
        <div className="mb-4">
          <h1 className="text-black text-xl font-bold mb-2">
            {title || "Title"}
          </h1>
          <p className="text-black">{message || "Message"}</p>
        </div>

        {/* OK Button */}
        <div className="py-2">
          <SecondaryButtonBoxyBlue
            _className="py-2"
            label="OK"
            onPress={handleClose}
          />
        </div>
      </div>
    </div>
  );
}
