import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from "framer-motion";


import BottomNav from "./Components/Navs/BottomNav"; 

import "./App.css"
import TopNav from "./Components/Navs/TopNav";
import Home from "./Pages/Home";
import PageLoader from "./Pages/PageLoader";

import { Routes, Route, Navigate } from "react-router-dom";

import { StateProvider as WallatProvider } from './StoreContext/wallatStore'
import { StateProvider as SnackProvider } from './StoreContext/snackStore'

import Login from "Pages/Auth/login";
import SignUp from "./Pages/Auth/SignUp";

import { Protector } from "helpers";
import Test from "./Test";

import SplashScreen from 'Components/SplashScreen';
// import ForgerPassword from 'Pages/Auth/ForgerPassword';
import ResetPassword from 'Pages/Auth/ResetPassword';
import OfflineDetector from 'OfflineDectector';
import { GoogleOAuthProvider } from '@react-oauth/google';
//=============================================
//Analytics
//=============================================
//=============================================
import { app, sendAnalytics } from 'firebaseConfig';
import { SettingsPage } from 'Pages/Profile/SettingsPage';
import { TopSnackersProvider } from 'TopSnackerContext/TopSnackersList';
import Profile from 'Pages/Profile';
import ForgetPassword from 'Pages/Auth/ForgerPassword';
import { UIProvider } from 'UIContext/UIContext';
import ModalLayout from "./Pages/Modals"
import FTUE from 'Pages/FTUE';
//=============================================
//=============================================


function App() {
  
  const webClientId = "337888192497-bs50dde665907o17i7vgkcagf7d5d3tn.apps.googleusercontent.com"

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading process or check some conditions
    setTimeout(() => {
      setIsLoading(false);
      // gaEventTracker("Splash Screen loaded")
    }, 5000); // Adjust the timeout as needed
  }, []);

  useEffect(() => {
   
    // analytics.logEvent('homepage_visit');
    // console.log(analytics)
    sendAnalytics( "App_opened" )
  }, []);

 

  return (
    <div className="">
         <AnimatePresence mode='wait'>
      {isLoading ?
        <motion.div
          key="modal"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <SplashScreen />
        </motion.div>
        :
        <AppContainer />}
    </AnimatePresence>
    </div>
   
  );
}

const AppContainer = () => {

  return( 
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/reset-password" element={<ResetPassword />} />
    <Route path="/forgot-password" element={<ForgetPassword />} />
    <Route path="/test" element={<Test />} />
    <Route path="*" element={<Protector Component={MainApp} />} />
 </Routes>)
}

const MainApp = () => {
  return(<main>
    <WallatProvider>
    <TopSnackersProvider>
      <UIProvider >
      <SnackProvider>
            <Home />   
            <PageLoader />
            <FTUE />
            <ModalLayout />
      </SnackProvider>
      <TopNav />
      <BottomNav />
      <OfflineDetector />
      </UIProvider>
      </TopSnackersProvider>
    </WallatProvider>
  </main>)
}

export default App;