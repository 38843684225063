import { SecondaryButtonBoxyBlue } from "Components/ButtonSecondary"



export const PublishedSnacksStatsCard = ({ 
    published_count = 0, 
    in_review_count = 0, 
    actionFunction = () => { },
    actionLabel = "Add Snack"
}) => {

    return (
        <div className="flex flex-row items-center  justify-between   bg-white rounded-full px-[20px] py-[10px] shadow-xl">
            <div>
                <h2 className="text-gray-500 font-bold text-xs">Published</h2>
                <h2 className="text-black text-lg text-center"> {published_count || "0"}</h2>
            </div>
            <div>
                <h2 className="text-gray-500 font-bold text-xs">In review</h2>
                <h2 className="text-black text-lg text-center"> {in_review_count || "0"}</h2>

            </div>
            {/* {console.log("activeTab", activeTab)}    */}
            <div>
                <SecondaryButtonBoxyBlue label={actionLabel} onClick={() => { actionFunction() }} />
            </div>
        </div>
    )
}