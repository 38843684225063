import React, { useState } from 'react';
import TabHeader from './TabHeader';
import SnackerList from './SnackerList';
import CreatorList from './CreatorList';
import { useTopSnackersList } from '../../TopSnackerContext/TopSnackersList';
import { motion } from 'framer-motion';

import _bg_leaderboard from '../../Assets/TopSnacker/bg_leaderboard.png';
import _icon_left from '../../Assets/TopSnacker/left-arrow.png';
import { useNavigate } from 'react-router-dom';

const TopSnackerScreen = () => {
  const navigate = useNavigate()
  const { dailySnackers = [], allTimeSnackers = [], dailyCreators = [], allTimeCreators = [] } =
    useTopSnackersList();
  const [activeTab, setActiveTab] = useState('Top Snackers');
  const sortedDailySnackers = [...dailySnackers].sort(
    (a, b) => b.blahcoin_today_earning - a.blahcoin_today_earning
  );
  const sortedAllTimeSnackers = [...allTimeSnackers].sort((a, b) => b.blah_coin - a.blah_coin);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll overflow-x-hidden' 
    style={{ backgroundImage: `url(${_bg_leaderboard})` }}
    >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto'>
    <div className="flex flex-col h-full overflow-y-auto overflow-x-hidden">
      <div className="flex pb-[100px] flex-1">
        {/* Background image */}
        {/* <div
          className="flex flex-1 bg-cover bg-center"
          style={{ backgroundImage: `url(${_bg_leaderboard})` }}
        > */}
          <div className="flex flex-1 flex-col rounded-xl">
            {/* Header Section */}
            <div className="w-full h-20 bg-transparent flex items-center px-5">
              <div className="flex items-center space-x-4">
              <button
                    onClick={() => navigate("/profile")}
                    className="p-2"
                >
                    <img
                    src={_icon_left}
                    alt="Back"
                    style={{width: "12px", height: "16px"}}
                    />
                </button>
                <h1 className="text-white text-lg font-bold ml-4">LEADERBOARD</h1>
              </div>
            </div>

            {/* Tabs Section */}
            <div className="px-5">
              <TabHeader activeTab={activeTab} onTabChange={handleTabChange} />
            </div>

            {/* Content Section */}
            <div className="mt-4 px-5 flex-1 overflow-y-auto">
              {activeTab === 'Top Creators' && (
                <CreatorList daily={dailyCreators} allTime={allTimeCreators} />
              )}
              {activeTab === 'Top Snackers' && (
                <SnackerList daily={sortedDailySnackers} allTime={sortedAllTimeSnackers} />
              )}
            </div>
          </div>
        {/* </div> */}
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default TopSnackerScreen;
