import React, { createContext, useReducer } from 'react';

const stateModal = {
    BlahCoin: 1,
    exchangeRate: 1,
    totalGameScore: 0,
    user : {
      
    },
    topSnackr : [],
    
    //store
    store_storeData : [],
    store_cartData : [],

    reward_streak_amount : 0,
    reward_streak_message : "",
    
    favouriteGames : []

}

const initialState = { ...stateModal };

const Store = createContext(initialState);

const { Provider } = Store;

const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
      let newState = {}
      switch(action.type) {
        case 'initWallat':
          newState = {...state, ...action?.payload }
          return newState;
        
        case 'increasBlahCoin':
            let increasedBlahCoin = parseFloat(state?.BlahCoin) + parseFloat(action?.payload?.BlahCoin)
            newState = {...state, BlahCoin : increasedBlahCoin }
            return newState;
            
        case 'updateBlahCoin':

            let _updatedBlahCoin =  parseFloat(action?.payload?.BlahCoin)
            newState = {...state, BlahCoin : _updatedBlahCoin }
            return newState;

        case "SET_REWARD_STREAK":
            return { 
              ...state, 
              reward_streak_amount: action.payload.reward_streak_amount || 1 ,
              reward_streak_message: action.payload.reward_streak_message || ""
            };
        //===========================================================
        //userderails
        //===========================================================
        case 'initUser':
        newState = {...state, user : action?.payload?.user }
        return newState;
        
        case 'SET_USER_DATA':
          return {
            ...state,
            user: action.payload,
            isLoggedIn: true,
            loading: false,
          };
        case 'SET_FOLLOWING_PUBLISHERS':
          // console.log("hereee")
          return {
            ...state,
            user: {
              user :{
                    ...state.user?.user,
                    following_publishers: action.payload,
              }
            },
          };
        case 'LOGOUT':
          return {
            ...initialState,
            loading: false,
          };
        case 'SET_LOADING':
          return {
            ...state,
            loading: action.payload,
          };
        
        
        //===========================================================
        //===========================================================
        
        case 'updateTopSnackr':
        newState = {...state, topSnackr : action?.payload?.topSnackr }
        return newState;
        //===========================================================
        //===========================================================

         
        case 'initStoreData':
        newState = {...state, store_storeData : [...action?.payload?.storeData] }
        return newState;

        case 'initCartData':
        newState = {...state, store_cartData : [...action?.payload?.cartData] }
        return newState;
        //===========================================================
        //===========================================================
        
        case 'addGameToFavourite':
        
          //accept { favouriteGame : Type OBJ }
          const id = action?.payload?.favouriteGame?.id
          const index = state?.favouriteGames?.findIndex((item) => item.id === id )

          if(index >= 0) {
            return state
          }

        return { ...state, favouriteGames : [...state?.favouriteGames , action?.payload?.favouriteGame]  }
        

        case 'removeGameFromFavourite':
        
          //accept { favouriteGame : Type OBJ }
          const _id = action?.payload?.favouriteGame?.id
          const indexToRemove = state?.favouriteGames?.findIndex((item) => item.id === _id )
          
          if(indexToRemove < 0) {
            return state
          }

          const updatedFavouriteList = [...state?.favouriteGames]

          updatedFavouriteList.splice(indexToRemove, 1);




          return { ...state, favouriteGames : [...updatedFavouriteList]  }

        case 'clearWallat' :
          
          return { ...state, ...stateModal }
     
        default:
          throw new Error();
      };
    }, initialState);
  
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };

  export { Store , StateProvider }