import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant";
import bgLeaderboard from "../../Assets/bg_leaderboard.png";
import iconLeft from "../../Assets/left-arrow.png";
import { motion } from "framer-motion";
import { useAuthContext } from "Auth/AuthContext";

const FollowingPublishers = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext()
  const items = user?.following_publishers || []
  console.log(items)

  const goToPublisher = (item) => {
    navigate('/creator', { state: { id: item?.id } })
  };

  const goBack = () => {
    navigate("/profile")
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
    style={{backgroundImage: `url(${bgLeaderboard})`,}}
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto'>
    <div className="flex flex-col min-h-screen bg-[#0E0C1C]">
      <div
        className="flex-grow relative"
        style={{
          backgroundImage: `url(${bgLeaderboard})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/* Header */}
        <div className="px-[10px] py-[5px]">
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={iconLeft}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">Following</h1>
        </div>
      </div>

        {/* Content */}
        <div className="px-4 pb-20">
          {/* Games */}
          {items.length > 0 && (
            <div>
              {items.map((item, index) => {
                const avatarImageURL = item?.avatar_publisher_url || ""

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between border-b border-[#412AAB] py-3"
                  >
                    <button
                      onClick={() => goToPublisher(item)}
                      className="flex items-center w-full space-x-4"
                    >
                      <img
                        src={avatarImageURL}
                        alt="Publisher"
                        className="w-10 h-10 rounded"
                      />
                      <span className="text-white">{item?.username || "Publisher Name"}</span>
                    </button>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default FollowingPublishers