import React, { useState } from "react";
import { motion } from "framer-motion";

const TabComponent = ({ onTabChange = () => {} }) => {
  const [selectedTab, setSelectedTab] = useState("Top Snackers");

  const handleTabPress = (tab) => {
    setSelectedTab(tab);
    onTabChange(tab);
  };

  const getTranslateX = () => {
    switch (selectedTab) {
      case "Top Snackers":
        return "0%";
      case "Top Creators":
        return "100%";
      default:
        return "0%";
    }
  };

  return (
    <div className="flex justify-center items-center w-full">
      {/* Tab Container */}
      <div className="relative w-[300px] max-w-[400px] bg-[#4E42A9] rounded-[20px] shadow-xl overflow-hidden border-4 border-[#4E42A9]">
        {/* Highlight Animation */}
        <motion.div
          className="absolute top-0 left-0 w-1/2 h-full bg-gradient-to-r from-[#7B59F8] to-[#A783F7] rounded-[15px] z-0"
          animate={{ x: getTranslateX() }}
          transition={{ type: "tween", duration: 0.3 }}
        />

        {/* Tabs */}
        <button
          onClick={() => handleTabPress("Top Snackers")}
          className={`w-1/2 py-2 relative text-sm text-white font-bold ${
            selectedTab === "Top Snackers" ? "z-20" : "z-10"
          }`}
        >
          Top Snackers
        </button>
        <button
          onClick={() => handleTabPress("Top Creators")}
          className={` w-1/2 py-2 relative text-sm text-white font-bold ${
            selectedTab === "Top Creators" ? "z-20" : "z-10"
          }`}
        >
          Top Creators
        </button>
      </div>
    </div>
  );
};

export default TabComponent;
