import React from 'react';
import { useNavigate } from 'react-router-dom'; // For routing
import { PublishedSnacksStatsCard } from 'Pages/SubmitSnack/PublishedSnacksStatsCard';
import { motion } from 'framer-motion';

import bgSnackCard from '../../../../Assets/leaderboard_bg.png';

export default function Games({ handlePageState = () => {}, data = [] }) {
  const navigate = useNavigate();

  const goToAddGame = () => {
    navigate('/add_game');
  };

  const publishedCount = data.filter((item) => item?.status === 'Published').length;
  const inReviewCount = data.filter((item) => item?.status === 'Under-review').length;

  const goToDetails = (item) => {
    navigate('/submitted_snack_details', { state: item });
  };

  const goToHelp = () => {
    const item = {
      title: 'Request help',
      subtext: 'What is your issue?',
      placeholder: 'Enter your issue here',
    };
    navigate('/settings_feedback', { state: item });
  };

  return (
    
    <div className="container mx-auto">
      <div className="pb-24">
        {/* Heading */}
        <div className="mt-8 mb-[10px] w-full">
          <p className="text-center text-black text-md">The list of games you have published so far.</p>
        </div>

        {/* Stats Card */}
        <PublishedSnacksStatsCard
          published_count={publishedCount}
          in_review_count={inReviewCount}
          actionFunction={goToAddGame}
          actionLabel="Add Game"
        />

        {/* Game List */}
        <div
          className="rounded-xl mt-4 px-2 overflow-hidden"
          style={{
            backgroundImage: `url(${bgSnackCard})`,
            backgroundSize: 'cover',
          }}
        >
          <div className="h-[300px] overflow-y-scroll">
            <div className="pb-24">
              {data.map((item, index) => (
                <button
                  key={index}
                  onClick={() => goToDetails(item)}
                  className="mt-2 w-full bg-violet-100 py-2 px-5 rounded-lg shadow-lg flex justify-between items-center"
                >
                  {/* Game Details */}
                  <div className="w-1/2">
                    <p className="text-black text-lg">{item?.snack_name || ''}</p>
                    <p className="text-black text-sm truncate">{item?.snack_description || ''}</p>
                  </div>

                  {/* Status */}
                  <div
                    className={`w-1/3 py-1 rounded-full text-center ${
                      item?.status === 'Published'
                        ? 'bg-green-100 text-green-500'
                        : item?.status === 'Under-review'
                        ? 'bg-blue-100 text-blue-500'
                        : 'bg-red-100 text-red-500'
                    }`}
                  >
                    {item?.status || ''}
                  </div>
                </button>
              ))}

              {/* No Data Message */}
              {data.length === 0 && (
                <div className="w-full h-[100px] flex items-center justify-center">
                  <p className="text-black text-xl">No games submitted yet!</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Help Link */}
        <div className="text-center py-[10px]">
          <button onClick={goToHelp} className="text-black hover:underline" 
          style={{
            color: '#3b82f6', 
            textDecorationLine: 'underline', 
            textDecorationStyle: 'solid', 
            cursor: 'pointer', 
            transition: 'color 150ms ease-in-out'
          }}
          >
            Need Help? Click here to contact us
          </button>
        </div>
      </div>
    </div>
  );
}
