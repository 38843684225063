import React, { memo } from "react";
import { BASE_URL } from "../../constant";
import { useNavigate } from "react-router-dom"; // For navigation
import RecentSnackCard from "../../Components/Profile/RecentSnackCard"; // Ensure this is React-compatible

export const HistorySnacks = ({ user = {} }) => {
  const games_history = user?.games_history || [];
  const reels_history = user?.reels_history || [];
  const comics_history = user?.comics_history || [];

  let full_history = [...games_history, ...reels_history, ...comics_history] || [];
  full_history.sort((a, b) => b.earned_points - a.earned_points);

  const sliced_history = full_history.slice(0, 5);
  const navigate = useNavigate();

  const goToSnack = (item = {}) => {
    console.log(item, "=======");
    // navigate(`/?game_url=${item.}`)
    // navigate(`/snack_game`, { state: item }); // Adjusted for React Router
  };

  return (
    <div className="px-[20px] pb-[100px] mt-[10px] overflow-y-scroll bg-white">
      <div>
        <h2 className="text-black text-[1.2rem] font-bold text-center mb-[20px]">HISTORY</h2>
      </div>
      <div className="h-full">
        {sliced_history.map((item, index) => (
          <RecentSnackCard key={index} item={item} lastPlayed={"10m ago"} />
        ))}
      </div>
    </div>
  );
};

const SnackItem = memo(({ item, goToSnack }) => {
  return (
    <div className="py-[1.2vh] w-full border-b-4 border-[#dfe2f3]">
      <button
        onClick={() => goToSnack(item.played_snack)}
        className="flex flex-row px-[4vw] w-full"
      >
        <div className="flex items-center w-[65vw]">
          <div className="w-[6vw] h-[3vh] bg-violet-300 rounded-[1vw] overflow-hidden">
            <img
              src={`${BASE_URL}${item?.played_snack?.cover_image?.url}`}
              alt={item?.played_snack?.title || "Snack"}
              className="w-[6vw] h-[3vh]"
            />
          </div>
          <div className="ml-[4vw]">
            <p className="text-white">{item?.played_snack?.title || "Name"}</p>
          </div>
        </div>
        <div>
          <p className="text-white">{item?.earned_points || "0 pts"}</p>
        </div>
      </button>
    </div>
  );
});
