import React, { useState, useEffect } from 'react';

function OfflineDetector() {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (<>
      {isOnline ? null
       : (
          <div className='fixed z-[1000000] bg-red-800 w-full h-full left-0 top-0 opacity-[0.9]'>
                {/* <p>You are currently offline.</p> */}
                <div className="flex flex-col items-center justify-center w-full h-full">

                    <div className="w-[50px]">
                    <svg fill='white' clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m2.095 19.886 9.248-16.5c.133-.237.384-.384.657-.384.272 0 .524.147.656.384l9.248 16.5c.064.115.096.241.096.367 0 .385-.309.749-.752.749h-18.496c-.44 0-.752-.36-.752-.749 0-.126.031-.252.095-.367zm9.907-6.881c-.414 0-.75.336-.75.75v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5c0-.414-.336-.75-.75-.75zm-.002-3c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1z" fill-rule="nonzero"/></svg>
                    </div>
                    <div className="text-center mt-[10px] text-[18px]">
                       You are offline!
                    </div>
                    <div className="text-center mt-[10px] text-[14px]">
                       Active internet connection is <br/> required to use the app.
                    </div>
                </div>
        </div>
        )
        }
        </>
  );
}

export default OfflineDetector;
