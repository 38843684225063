
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from 'Auth/AuthContext';
import { API } from 'constant';
import { useUI } from 'UIContext/UIContext';
import Bg from '../../Assets/bg.png';
import { setToken } from 'helpers';
import { SecondaryButtonBoxyBlack } from 'Components/ButtonSecondary';
import { sendAnalytics } from 'firebaseConfig';
const SignUpScreen = () => {

   useEffect(() => {
        sendAnalytics( `screen_view`, {
            firebase_screen: "Signup page", 
            firebase_screen_class: "Auth page"
        })
    }, [])

  const { setUser } = useAuthContext();
  const {setModal} = useUI()
//   const { loadUserData } = useContext(AuthContext);
//   const { initEconomyData } = useEconomy();
//   const { setModal } = useUI();
  const navigate = useNavigate();

  const [inputVals, setInputVals] = useState({
    email: '',
    password: '',
    username: '',
    confirmPass: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [hidePass, setHidePass] = useState(true);

  const handleInputChange = (value, key) => {
    if (key === 'username') {
      value = value.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 50);
    }
    setError('');
    setInputVals((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handlePaste = (key, event) => {
    const pastedData = event.clipboardData.getData('text');
    const cleanedData = key === 'username' ? pastedData.replace(/[^a-zA-Z0-9 ]/g, '').slice(0, 50) : pastedData.slice(0, 50);
    handleInputChange(cleanedData, key);
  };

  const onSignUp = async (values) => {

            // callback()
            //         window.location.reload();
            //         return
               
            if (values.password !== values.confirmPass) {
                setError("Password confirm failed")
                return
            }
            setIsLoading(true);
            try {

                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(inputVals.email)) {
                    setError('Invalid email format');
                    setIsLoading(false);
                    return;
                }
                if (inputVals.password !== inputVals.confirmPass || inputVals.password === '') {
                    setError('Password confirm failed');
                    setIsLoading(false);
                    return;
                }
                const value = {
                    username: values.username,
                    email: values.email,
                    password: values.password,
                    avatar_url : "/extra/images/profile/Vegies/Asset 4.png",
                    blah_coin : 25
                    // blah_coin : 0,
                };
                const response = await fetch(`${API}/auth/local/register`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(value),
                });
    
                const data = await response.json();
                console.log(data)
                if (data?.error) {
                    throw data?.error;
                } else {
                    // set the token
                    setToken(data.jwt);
                    setModal("signup_bonus")
    
                    // set the user
                    setUser(data.user);
    
                    console.log(`Welcome back ${data.user.username}!`);
    
                    // navigate("/", { replace: true });
                    // callback()
                    window.location.reload();
    
                }
            } catch (ex) {
                // console.log(ex?.details?.errors?.[0]?.message);
                setError(ex?.details?.errors?.[0]?.message ?? ex?.message ?? ex.response?.data?.error?.message ??   "Something went wrong!");
            } finally {
                setIsLoading(false);
            }
        };

//   const handleSignUp = async () => {
//     setIsLoading(true);
//     try {
//       const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//       if (!emailPattern.test(inputVals.email)) {
//         setError('Invalid email format');
//         setIsLoading(false);
//         return;
//       }
//       if (inputVals.password !== inputVals.confirmPass || inputVals.password === '') {
//         setError('Password confirm failed');
//         setIsLoading(false);
//         return;
//       }

//       const value = {
//         username: inputVals.username,
//         email: inputVals.email,
//         password: inputVals.password,
//         avatar_url: '/extra/images/profile/Vegies/Asset 4.png',
//         blah_coin: 250,
//       };

//       const response = await fetch(`${API}/auth/local/register`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(value),
//       });

//       const data = await response.json();
//       if (data?.error) {
//         throw data?.error;
//       }

//       await storeToken(data.jwt);
//       loadUserData();
//       initEconomyData();
//       setModal('signup_bonus');
//       navigate('/home');
//       analyticsLogSignUp({ method: 'Web App' });
//     } catch (ex) {
//       setError(
//         ex?.details?.errors?.[0]?.message ??
//           ex?.message ??
//           ex.response?.data?.error?.message ??
//           'Something went wrong!'
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

  const gotoLogin = () => {
    navigate('/login');
  };

  return (
    <div className="bg-[#0E0C1C] min-h-screen">
      <div className="h-full flex flex-col">
      <div
          className="w-full h-screen bg-cover bg-center p-5"
          style={{ backgroundImage: `url(${Bg})` }}
        >
        <div className="text-left mt-[50px]">
          <h1 className="text-2xl p-2 font-bold text-white">SIGN UP</h1>
        </div>
        <div className="bg-black/50 p-[25px] rounded-xl">
        <div className="space-y-6">
        <p className="text-white text-xs text-left">Create your SnackR account.</p>

          <input
            type="email"
            value={inputVals.email}
            onChange={(e) => handleInputChange(e.target.value, 'email')}
            onPaste={(e) => handlePaste('email', e)}
            maxLength={50}
            placeholder="Email"
            className="w-full p-[10px] px-5 rounded-xl bg-gray-200 text-gray-800 focus:ring-2 focus:ring-violet-300 outline-none"
          />

          <input
            type="text"
            value={inputVals.username}
            onChange={(e) => handleInputChange(e.target.value, 'username')}
            onPaste={(e) => handlePaste('username', e)}
            maxLength={24}
            placeholder="Full name"
            className="w-full p-[10px] px-5 rounded-xl bg-gray-200 text-gray-800 focus:ring-2 focus:ring-violet-300 outline-none"
          />

          <div className="relative">
            <input
              type={hidePass ? 'password' : 'text'}
              value={inputVals.password}
              onChange={(e) => handleInputChange(e.target.value, 'password')}
              placeholder="Password"
              className="w-full p-[10px] px-5 rounded-xl bg-gray-200 text-gray-800 focus:ring-2 focus:ring-violet-300 outline-none"
            />
            <button
              onClick={() => setHidePass(!hidePass)}
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-gray-600"
            >
              👁️
            </button>
          </div>

          <input
            type="password"
            value={inputVals.confirmPass}
            onChange={(e) => handleInputChange(e.target.value, 'confirmPass')}
            placeholder="Confirm Password"
            className="w-full p-[10px] px-5 rounded-xl bg-gray-200 text-gray-800 focus:ring-2 focus:ring-violet-300 outline-none"
          />

          {error && <p className=" m-0 text-red-500 text-left">{error}</p>}

          <p className="text-xs font-semibold text-white-400 text-left">
            By selecting "Agree and Continue" below, <br />
            I agree to the Terms of Service and Privacy Policy
            {/* <span className="text-violet-400 text-left">I agree to the Terms of Service</span> and{" "}
            <span className="text-violet-400">Privacy Policy</span>. */}
          </p>

          {/* <button
            onClick={onSignUp}
            disabled={isLoading}
            className="w-full py-3 rounded-lg bg-violet-500 text-white font-bold hover:bg-violet-600"
          >
            {isLoading ? '...' : 'Agree and Continue'}
          </button> */}

            <SecondaryButtonBoxyBlack
                _className="py-[14px] w-full"
                onClick={() => onSignUp(inputVals)}
                isLoading={isLoading}
                label={isLoading ? "..." : "Agree and Continue"}

            />
        </div>

        <div className="mt-6 text-left">
          <span className="text-white">Already have an account?</span>{" "}
          <button
            onClick={gotoLogin}
            className="text-white font-bold hover:underline"
          >
            Sign in
          </button>
        </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpScreen;
