import React from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constant";
import heartIcon from "./assets/heart-icon.png";
import playIcon from "./assets/ph_play-fill.png";

const SnackCard = ({ item, type, snackClick = () => {} }) => {
  const navigate = useNavigate();
  const _likecount = item?.liked_users?.length || 0; // Fallback to 0 if undefined
  const plays = "10k"; // Hardcoded for now
  const handleSnackButtonPress = () => {
    // navigate("/snack_game", { state: { type, ...item } }); // Pass parameters via state  
    if (item.type == "game" && item.game_url) {
      navigate(`/?game_url=${item.game_url}&&snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "comic") {
      navigate(`/?snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "reel") {
      navigate(`/?snack_type=${item.type}&&item.id=${item.id}&&snack_name=${item.title}`)
    }
  };

  return (
    <div className="w-[300px] mx-[10px] py-[20px]">
      <button
        onClick={handleSnackButtonPress}
        className="w-[130px] h-[200px] bg-white rounded-lg shadow-md flex flex-col justify-between p-2"
        style={{
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.4)",
        }}
      >
        {/* Game Image */}
        <img
          src={`${BASE_URL}${item.cover_image.url}`}
          alt={item.title}
          className="w-full h-[70%] object-cover rounded-md"
        />

        {/* Game Title */}
        <p className="text-center text-black mt-2 truncate">
          {item.title}
        </p>

        {/* Likes and Play Count */}
        <div className="flex justify-between items-center mt-2">
          {/* Likes */}
          <div className="flex items-center mx-3">
            <img src={heartIcon} alt="Likes" className="w-[15px] h-[15px] mr-1" />
            <span className="text-gray-600 text-sm">{_likecount}</span>
          </div>

          {/* Play Count */}
          <div className="flex items-center mx-3">
            <img src={playIcon} alt="Play Count" className="w-[12px] h-[15px] mr-1" />
            <span className="text-gray-600 text-sm">{plays}</span>
          </div>
        </div>
      </button>
    </div>
  );
};

export default SnackCard;
