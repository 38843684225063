import React from "react";
import { TruncateText } from "helpers";

const RankCard = ({ item, rank }) => {
  // Define the image source for the medals based on rank
  let medalSource;
  switch ((rank+1)) {
    case 1:
      medalSource = require('../Buttons/UI_Images/rankCard/first.png');
      break;
    case 2:
      medalSource = require('../Buttons/UI_Images/rankCard/second.png');
      break;
    case 3:
      medalSource = require('../Buttons/UI_Images/rankCard/third.png');
      break;
    case 4:
      medalSource = require('../Buttons/UI_Images/rankCard/fourth.png');
      break;
    default:
      medalSource = null;
  }


  return (
    <div className="flex items-center bg-white rounded-xl p-1 shadow-md w-[150px] h-15">
      {/* Avatar with Medal */}
      <div className="relative">
        <img
          src={`${item?.avatar_url || "../../Assets/user2.png"}`}
          alt="Avatar"
          className="w-12 h-12 rounded-lg object-cover"
        />
        {/* Medal Image */}
        {medalSource && (
          <img
            src={medalSource}
            alt="Medal"
            className="absolute top-1 right-[-10px] w-6 h-6"
          />
        )}
      </div>

      {/* Username and Points */}
      <div className="ml-4 flex-shrink">
        <p className="text-xs text-[#666AFF] truncate">
          {TruncateText((item?.username || "Uknown User"), 12)}
        </p>
        <p className="mt-1 text-sm font-bold text-left text-gray-700">
          {item.blahcoin_today_earning}
        </p>
      </div>
    </div>
  );
};

export default RankCard;
