import React, { createContext, useReducer } from 'react';

// import {fakeData} from "./data"

const stateModal = {
    
    snacks : [],
    allGames : [],
    allComics : [],
    allReels : [],
    gamesPlayed : []

}

const initialState = { ...stateModal };

const Store = createContext(initialState);

const { Provider } = Store;

const StateProvider = ( { children } ) => {
    const [state, dispatch] = useReducer((state, action) => {
      let newState = {}
      let id
      let index
      // let payload

      switch(action.type) {
        case 'initSnack':
          newState = {...state, snacks : [...action?.payload?.snacks] }
          return newState;

        case 'addMoreSnacks':
          newState = {...state, snacks : [...state?.snacks ,...action?.payload?.snacks] }
          return newState;

        case 'updateallGames':
          newState = {...state, allGames : [ ...action?.payload?.allGames] }
          return newState;

        case 'updateallComics':
          newState = {...state, allComics : [ ...action?.payload?.allComics] }
          return newState;
        case 'updateallReels':
          newState = {...state, allReels : [ ...action?.payload?.allReels] }
          return newState;


        case 'updateSnackbyId' :
          const updatedSnacks = [...state?.snacks]
          index = updatedSnacks.findIndex(item => item.id === action?.payload?.snackId);

          if (index !== -1) {
            updatedSnacks[index] = {
              ...updatedSnacks[index],
              ...action?.payload?.snackObj
            };
          }

          newState = {...state, snacks : [...updatedSnacks] }
          return newState;

        case 'updateGameHighScore' :

          const gamePlayed = action?.payload?.gamePlayed
          id = gamePlayed?.id
          
          index = state?.gamesPlayed?.findIndex((item) => item.id === id )

          let updatedgamesPlayed 

          if(index < 0) {
            updatedgamesPlayed = [...state?.gamesPlayed, gamePlayed]
          } else {
            updatedgamesPlayed = [...state?.gamesPlayed]
            updatedgamesPlayed[index] = {
              ...updatedgamesPlayed[index],
              ...gamePlayed
            }
          }

          return  { ...state, gamesPlayed : [...updatedgamesPlayed] }



        case 'clearWallat' :
          
          return { ...state, ...stateModal }
     
        default:
          throw new Error();
      };
    }, initialState);
  
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
  };

  export { Store , StateProvider }