import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // for navigation
import axios from "axios";
import { API } from "constant"; // Assuming API constants exist
import { CommonTextFieldV2, CommonTextInputV2 } from "Components/TextInput";
import { SecondaryButtonBoxyBlue, SecondaryButtonBoxyWhite } from "Components/ButtonSecondary";
import PrimaryButtonBoxyYellow from "Components/Buttons/ButtonsV2";
import { FilePicker } from 'react-file-picker';
import { useAuthContext } from "Auth/AuthContext";
import { getToken } from "helpers";
import { motion } from "framer-motion";

const PublishSnackComic = () => {
    const _back_button = require("../../../../Assets/left-arrow.png")
    const { user } = useAuthContext()
    const navigate = useNavigate()
    const [gameName, setGameName] = useState("");
    const [snackDescription, setSnackDescription] = useState("");
    const [gameUrl, setGameUrl] = useState("");
    const [checkValue, setCheckValue] = useState("");
    const [selectedFiles, setSelectedFiles] = useState({ creatives_zip: [], game_zip: [] });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

  const goBack = () => navigate(-1);

  const handleCheckbox = (value) => setCheckValue(value);

  const handleError = (error) => {
    console.error("Error selecting file:", error);
    alert("Failed to pick the file");
  };

  const pickCreativesFile = (file) => {
    if (file) {
    setSelectedFiles((prevFiles) => ({
        ...prevFiles,
        creatives_zip: [file],
    }));
    }
  };

    const pickGameZipFile = (file) => {
        if (file) {
        setSelectedFiles((prevFiles) => ({
            ...prevFiles,
            game_zip: [file],
        }));
        }
    };

    const submitData = async () => {

        if (!gameName || !snackDescription || selectedFiles.creatives_zip.length === 0) {
        alert('Validation Error', 'Please fill in all required fields and upload files.');
        return;
        }

        // if (checkValue === 'fast' && !gameUrl) {
        // alert('Validation Error', 'Please enter the game URL.');
        // return;
        // }

        // if (checkValue === 'banzan' && selectedFiles.game_zip.length === 0) {
        // alert('Validation Error', 'Please upload the game ZIP file.');
        // return;
        // }


        const query = {
        snack_name: gameName,
        snack_description: snackDescription,
        // publishing_type: checkValue,
        // game_url: checkValue === 'fast' ? gameUrl : '',
        users_permissions_user: user?.user?.id,
        snack_type: 'comic',
        status: "Under-review"

        };

        const formData = new FormData();
        formData.append('data', JSON.stringify(query));

        /// Append creatives file
        selectedFiles.creatives_zip.forEach((file) => {
        formData.append('files.creatives_zip', {
            uri: file?.assets[0]?.uri,
            name: file?.assets[0]?.name,
            type: file?.assets[0]?.mimeType || 'application/octet-stream', // Default MIME type if not specified
        });
        });

        // Append game ZIP file
        selectedFiles.game_zip.forEach((file) => {
        formData.append('files.game_zip', {
            uri: file?.assets[0]?.uri,
            name: file?.assets[0]?.name,
            type: file?.assets[0]?.mimeType || 'application/octet-stream', // Default MIME type if not specified
        });
        });

        setLoading(true);

        try {
        const token = await getToken();
        const response = await axios.post(`${API}/submitted-user-snacks`, formData, {
            headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            },
        });

        alert('Success', 'Your Snack has been submitted successfully!');
        
        // Clear form and files
        setGameName('');
        setSnackDescription('');
        setGameUrl('');
        setSelectedFiles([]);
        navigate(-1)

        } catch (ex) {
        console.error('Error submitting form:', ex);
        setError(ex?.response?.data?.error?.message || 'Submission failed');
        alert('Error', 'Failed to submit the form. Please try again.');
        } finally {
        setLoading(false);
        }
    };

    const goToGuideLines = () => {
        // console.log("goToGuideLines")
        const external_url = "https://banzan.co/snackr_guidelines.pdf"
        window.open(`https://docs.google.com/viewer?url=${external_url}&&embedded=true`, "_blank");
    }

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full ' 
        style={{backgroundColor: "#6242F9", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full '>
    <div className="bg-[#6242F9] min-h-screen mb-[100px]">
      {/* Header Section */}
      <div
        className="bg-[#7871FF] flex flex-col w-full rounded-b-lg"
        style={{ padding: "5px" }}
      >
        <div className="p-4 flex items-center">
          <button
            className="p-2"
            onClick={goBack}
          >
            <img
              src={_back_button}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">
            Publish Your Snack
          </h1>
        </div>
      </div>

      {/* Main Content */}
      <div className="px-[20px] pt-[20px]">
        <div className="overflow-y-auto hide-scrollbar " style={{ height: "90vh" }}>
          <div className="pb-[100px] mt-[30px]">
            {/* Guidelines */}
            <div>
              <h2 className="text-white text-[1.2rem] font-bold text-center">
                Click to view Snackr Creator Guidelines
              </h2>
            </div>
            <div className="mt-[10px] text-center">
            <SecondaryButtonBoxyWhite label="View Guidelines" onClick={goToGuideLines} _className="py-[12px]"/>

            </div>

            {/* Instructions */}
            <div className="mt-[20px] text-center">
              <h3 className="text-white text-[1.2rem] font-bold">
                Follow the below instructions to publish your comic
              </h3>
            </div>

            {/* Steps */}
            <div className="relative mt-[10px]">
              <div className="bg-white absolute" style={{width: "5px", height: "400px", left: "20px"}}></div>


              <div className="w-[45px] h-[45px] bg-[#A887FB] rounded-full absolute z-[10] flex justify-center items-center" style={{backgroundColor: "#A887FB", top: "0px"}}><p className="text-white font-bold">1</p></div>
              <div className="w-[45px] h-[45px] bg-[#A887FB] rounded-full absolute z-[10] flex justify-center items-center" style={{backgroundColor: "#A887FB", top: "110px"}}><p className="text-white font-bold">2</p></div>
              <div className="w-[45px] h-[45px] bg-[#A887FB] rounded-full absolute z-[10] flex justify-center items-center" style={{backgroundColor: "#A887FB", top: "240px"}}><p className="text-white font-bold">3</p></div>

            </div>

            {/* Form */}
            <div className="mt-[10px]" style={{marginLeft: "60px", marginBottom: "50px"}}>
              {/* Game Name */}
              <div>
                <label className="text-white font-bold text-[1.2rem]">Title of your snack</label>
                <CommonTextInputV2
                    value={gameName}
                    handleInputChange={setGameName}
                    _className="bg-white"
                    placeholder="Enter your game name "
                  />
                <p className="text-white text-[0.8rem] mt-[10px]">
                  You will not be able to edit this later on
                </p>
              </div>

              {/* Creatives */}
              <div className="mt-[10px]">
                <label className="text-white font-bold text-[1.2rem]">Creatives</label>
                <FilePicker
                extensions={["zip"]}
                onChange={(file) => pickCreativesFile(file)}
                onError={(error) => handleError(error)}
                >
                <SecondaryButtonBoxyBlue
                      label={selectedFiles?.creatives_zip?.length > 0 ? "Uploaded" : "Upload"}  _className="shadow-mg border-2 w-[150px] mt-[5px]"/>
                </FilePicker>
                <p className="text-white text-[0.8rem] mt-[10px]">
                  Please upload creatives in the prescribed format (check guidelines)
                </p>
                {selectedFiles?.creatives_zip?.map((file, index) => (
                <p key={index} className="bg-blue-100 text-blue-700 p-2 mt-2 rounded">
                    {file?.assets[0].name}
                </p>
                ))}
              </div>

              {/* Snack Description */}
              <div className="mt-[10px]">
                <label className="text-white font-bold text-[1.2rem]">Snack description</label>
                <CommonTextFieldV2
                    value={snackDescription}
                    handleInputChange={setSnackDescription}
                    _className="bg-white"
                    placeholder="Enter your snack description here"
                    style={{resize: "none"}}
                  />
              </div>

              {/* Submit Button */}
              <div className="mt-[20px] w-full">
              <PrimaryButtonBoxyYellow
                  label={loading ? 'Submitting...' : 'Submit your snack'}
                  onClick={submitData}
                  disabled={loading}
                  className="w-full text-md p-[5px]"
                />
                {error && <p className="text-red-500 mt-2">{error}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default PublishSnackComic;
