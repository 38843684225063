import React, { useState, useEffect } from "react";

const defaultImage = require("../../Assets/user2.png"); // Update with the correct path to your default image

export const ProfileImage = ({ image, id, onLoad = () => {}, isLoaded }) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isLoaded === false) {
      // Re-attempt to load the image if it's not loaded yet
      setHasError(false);
    }
  }, [isLoaded]);

  return (
    <img
      id={id}
      className="w-[80px] h-[80px] rounded-full bg-white border-4 border-white object-cover"
      src={hasError ? defaultImage : image}
      alt="Profile"
      onError={() => {
        setHasError(true);
        onLoad(false); // Notify parent that the image failed
      }}
      onLoad={() => onLoad(true)} // Notify parent when the image loads successfully
    />
  );
};
