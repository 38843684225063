// import SnackButtonImage from "../snackButtonImage.png"
import SnackButtonLogo from "./arrow.png"

const ArrowIcon = () => {
    return (
        <img src={SnackButtonLogo} alt="Arrow" className="w-[18px] h-[18px]" />

    )
}

export const SnackButton = (props) => {

    const { onClick, snackType } = props

    const getButtonText = (type) => {
        let _text = "SNACK NOW"
        if(type === "game"){
            _text = "PLAY NOW"
        } else if(type === "comic"){
            _text = "READ NOW"
        } else if(type === "reel"){
            _text = "WATCH NOW"
        }
        return _text
    }
    return (
        // <div
        //     onClick={onClick}
        //     className="relative _z-[51] ml-[15px] mt-[15px] p-[10px] w-fit bg-[#a07af6] ring-[5px] ring-white _bg-zinc-900 rounded-full min-w-[280px] text-center font-bold  max-h-[50px]"
        // >
        //     {/* <img src={SnackButtonImage} className="w-full max-w-[300px]" alt="" /> */}
        //     <div className="flex w-full items-center">
        //         <div className="flex w-full justify-center  items-center ">
        //         <div className=" ">

        //             <div className="w-[30px]">
        //                 <img src={SnackButtonLogo} className="h-[30px]" alt="" />
        //             </div>
        //         </div>

        //             <div className="text-[16px] font-bold text-white ml-[10px]">
        //                 <p
        //                     style={{
        //                         filter: "drop-shadow(0px 0px 2px grey)"
        //                     }}
        //                     className=" ">

        //                     {getButtonText(snackType)}
        //                 </p>
        //             </div>
        //             <div className="w-[25px] h-[25px] ">
        //                 <ArrowIcon />
        //             </div>
        //                         </div>
        //     </div>
        // </div>

        <div
            onClick={onClick}
            className="m-[10px] mb-0 rounded-[20px] overflow-hidden w-[80%] bg-white cursor-pointer"
        >
            {/* <img src={SnackButtonImage} className="w-full max-w-[300px]" alt="" /> */}
            <div className="bg-gradient-to-r from-white to-[#999999] 
        rounded-[20px] overflow-hidden py-[1.5vh] border border-[#9A9A9A]">
                <div className="flex flex-row justify-center align-items justify-content">

                    <span className="font-bold text-center text-[#726FFF] text-[1.2rem] mr-[10px]">
                        {getButtonText(snackType)}
                    </span>
                    <div className="w-[25px] h-[25px] mt-[5px]">
                        <ArrowIcon />
                    </div>
                </div>
            </div>
        </div>
    );
};
