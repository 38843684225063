// contexts/ThemeContext.js
import React, { createContext, useState, useContext } from 'react';

const UIContext = createContext();

export const UIProvider = ({ children }) => {

  const [modal, setModal] = useState('');
  const [ftueState, setFtueState] = useState('none')

  return (
    <UIContext.Provider value={{ modal, setModal, ftueState, setFtueState }}>
      {children}
    </UIContext.Provider>
  );
};

export const  useUI = () => useContext(UIContext);