
import RankCard from "../../Components/Profile/RankCard";
import React from "react";
import { Frontend_URL } from "../../constant";
import { TruncateText } from "helpers";
import { useNavigate } from "react-router-dom";

const _coinImage = require("../../Assets/coin.png")
const bg_snack_card = require("../../Assets/leaderboard_bg.png")

const TopSnackCard = ({ refetchData = () => {}, loadingData }) => {
  const navigate = useNavigate()
  const _newDailySnackers = [...loadingData].slice(0, 4);
  _newDailySnackers.sort((a, b) => b.blahcoin_today_earning - a.blahcoin_today_earning);

  const viewTopSnackersList = () => {
    // Replace with routing logic for the web
    navigate("/top_snackers");
  };

  return (
    <>
      {_newDailySnackers.length > 0 && (
        <div style={styles.cardContainer} className="rounded-lg">
          <div style={styles.cardBackground} className="rounded-lg">
            <div style={styles.cardContent}>
              <div style={styles.header}>
                <h2 style={styles.title} className="text-center">LEADERBOARD</h2>
                <button
                  onClick={refetchData}
                  disabled={loadingData}
                  style={styles.refreshButton}
                  className="rounded-full text-[#666AFF] text-right"
                >
                  Refresh
                </button>
              </div>
              <div style={styles.snackersList} className="flex-row flex-wrap">
                {_newDailySnackers.map((item, index) => (
                  <button
                    key={index}
                    onClick={viewTopSnackersList}
                    style={styles.snackerButton}
                  >
                    <RankCard item={item} rank={index} />
                  </button>
                ))}
              </div>
              <button
                onClick={viewTopSnackersList}
                style={styles.viewAllButton}
                className="rounded-lg"
              >
                View All
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const SnackerNameCard = ({ item = {}, rank }) => {
  const _avatarImageURL = `${Frontend_URL}${item?.avatar_url || "/extra/images/profile/human/Asset 4.png"}`;

  return (
    <div style={styles.snackerCard}>
      <div style={styles.snackerRow}>
        <div style={styles.rank}>
          <span style={styles.rankText}>{rank + 1 || "_"}</span>
        </div>
        <img
          src={_avatarImageURL}
          alt="Avatar"
          style={styles.avatarImage}
        />
        <div style={styles.usernameContainer}>
          <span style={styles.username}>
            {TruncateText(item.username || "Username", 10)}
          </span>
        </div>
        <div style={styles.pointsContainer}>
          <img src={_coinImage} alt="Coin" style={styles.coinImage} />
          <span style={styles.pointsText}>
            {item.blahcoin_today_earning || "0 pts"}
          </span>
        </div>
      </div>
    </div>
  );
};

// Styling in JavaScript
const styles = {
  cardContainer: {
    width: "100%",
    height: "300px",
    overflow: "hidden",
  },
  cardBackground: {
    background: `url(${bg_snack_card}) no-repeat center/cover`,
    height: "100%",
    padding: "20px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", 
    width: "100%", 
    position: "relative", 
  },
  title: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    color: "#453DBD",
    margin: "0 auto", 
    textAlign: "center",
  },
  refreshButton: {
    backgroundColor: "white",
    fontSize: "0.7rem",
    padding: "5px 10px 5px 10px", 
    margin: "10px",
    cursor: "pointer",
    position: "absolute", 
    right: "0",
  },
  snackersList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "space-between",
    marginTop: "20px", 
  },
  snackerButton: {
    width: "150px", 
    marginBottom: "10px", 
    background: "none",
    border: "none",
    padding: 0,
    cursor: "pointer",
  },
  viewAllButton: {
    marginTop: "20px", 
    width: "80px",
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#7A3EFF",
    padding: "8px", 
    cursor: "pointer",
  },
  snackerCard: {
    height: "50px", 
    backgroundColor: "white",
    borderRadius: "16px", // 1rem
    boxShadow: "0 3px 15px rgba(0,0,0,0.2)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 76.8px", // 4vw
    overflow: "hidden",
  },
  snackerRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  rank: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    color: "#8B6EFF",
  },
  avatarImage: {
    width: "115.2px", // 6vw
    height: "115.2px", // 6vw
    borderRadius: "50%",
    objectFit: "cover",
  },
  usernameContainer: {
    marginLeft: "19.2px", // 1vw
  },
  username: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  pointsContainer: {
    display: "flex",
    alignItems: "center",
  },
  coinImage: {
    width: "57.6px", // 3vw
    height: "57.6px", // 3vw
  },
  pointsText: {
    fontSize: "0.8rem",
  },
};


// Fake Data
const fake_consumers = [
  { id: 1, username: "KingBling98", blahcoin_today_earning: 2100 },
  { id: 2, username: "dashamoolam19", blahcoin_today_earning: 1820 },
  { id: 3, username: "bikerJoe_lol", blahcoin_today_earning: 1010 },
  { id: 4, username: "You", blahcoin_today_earning: 508 },
];

export default TopSnackCard;
