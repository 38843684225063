// // import { Children } from "react";
// import {motion} from "framer-motion"
// import { WalletButton } from "../Components/Navs/WalletButton";


// function SnackContainer({ children, ...props }) {

//     const { closeCallback } = props

//     return (< motion.div
//         initial={{ opacity: 1, translateY: "100vh" }}
//         animate={{ opacity: 1, translateY: "0vh" }}
//         exit={{ opacity: 1, translateY: "100vh" }}
//         // transition={{ type: "Tween", stiffness: 50 }}
//         className="fixed fixedmd:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen ">
//         <div className="_container _mx-auto md:max-w-[400px]  ">


//             <div className="flex justify-between items-center py-[5px] px-[12px] bg-violet-400">
//                 <WalletButton holdClick={true} />

//                 <div onClick={() => closeCallback()} className="w-fit bg-white px-[12px] py-[3px] shadow rounded-lg text-slate-900 font-bold">
//                     Close
//                     {/* <svg className="h-[35px] w-[35px]" fill="white" clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-8.991 6.932 2.717-2.718c.146-.146.338-.219.53-.219.405 0 .751.325.751.75 0 .193-.073.384-.219.531l-2.718 2.717 2.728 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.531-.219l-2.728-2.728-2.728 2.728c-.146.146-.339.219-.531.219-.401 0-.75-.323-.75-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .384.073.53.219z" fill-rule="nonzero" /></svg> */}
//                 </div>
//             </div>

//         </div>
//         {children}
//     </motion.div>);
// }

// export default SnackContainer;

import { WalletButton } from "Components/Navs/WalletButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

export default function SnackContainer({ children, ...props }) {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate()
  const { closeCallback } = props
  useEffect(() => {
    const handleBackPress = (event) => {
      event.preventDefault();
      setModal(true);
    };

    window.addEventListener("popstate", handleBackPress);

    return () => {
      window.removeEventListener("popstate", handleBackPress);
    };
  }, []);

  const exitfunction = () => {
       navigate(-1)
    
  }
  const clickBackUiButton = () => {
    setModal(true);
  };

  return (
    
    < motion.div
            initial={{ opacity: 1, translateY: "100vh" }}
            animate={{ opacity: 1, translateY: "0vh" }}
            exit={{ opacity: 1, translateY: "100vh" }}
            // transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen hide-scrollbar">
            
        <div className="_container _mx-auto md:max-w-[400px]  ">

        <div
          style={{
            position: "absolute",
            left: "10px",
            top: "2px",
            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <button onClick={clickBackUiButton} style={{ marginTop: "12px", background: "none", border: "none", cursor: "pointer" }}>
              <img
                src={require("../Assets/back_button.png")}
                alt="Back"
                style={{ width: "30px", height: "30px" }}
              />
            </button>
            <div style={{ marginLeft: "30px" }}>
              <WalletButton />
            </div>
          </div>
        </div>

        
      {modal && (
        <div className="h-screen w-full"
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            background: "rgba(0, 0, 0, 0.5)",
            // height: "100px",
            // width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            zIndex: "7010"
          }}
        >
          <div
            style={{
              padding: "10px 20px",
              background: "white",
              borderRadius: "1rem",
              position: "relative",
              margin: "20px"
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0px",
                width: "90%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "10px",
                  width: "100px",
                  borderRadius: "50%",
                  margin: "0 auto",
                }}
              >
                <img
                  src={require("../Assets/Logo.png")}
                  alt="Logo"
                  style={{ width: "50px", height: "50px", margin: "0 auto" }}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <h2 style={{ fontSize: "1.2rem", fontWeight: "bold" }}>Do you want to exit the Snack?</h2>
            </div>
            <div>
              <button
                onClick={() => setModal(false)}
                style={{
                  padding: "20px",
                  marginTop: "20px",
                  background: "#7A3EFF",
                  borderRadius: "1rem",
                  color: "white",
                  fontWeight: "bold",
                  width: "100%",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                No, Keep snacking
              </button>
              <button
                onClick={exitfunction}
                style={{
                  color: "black",
                  padding: "20px",
                  marginTop: "10px",
                  border: "1px solid #7A3EFF",
                  borderRadius: "1rem",
                  fontWeight: "bold",
                  width: "100%",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                Yes, Quit for now
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    {children}
    </motion.div>
    
  );
}
