

import { useAuthContext } from "Auth/AuthContext";
import React, { useState, useEffect, useContext } from "react";

import { headers } from 'helpers';
import { ProfileDetailsCard } from "./ProfileDetailsCard";
import TopSnackCard from "./TopSnack";
import { HistorySnacks } from "./HistorySnacks";
import { Link, useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { Store as wallatStore } from "StoreContext/wallatStore";
import { API, Frontend_URL } from "../../constant";
import axios from "axios";
import { getToken } from "../../helpers";
import LoginPrompt from "./LoginPrompt";
import { useTopSnackersList } from "TopSnackerContext/TopSnackersList";
import WalletButton from "../../Components/Navs/WalletButton";
// import { useTopSnackersList } from "../StateManagement/TopSnackersList";

import {
  generateFakeDataSnackerList,
  generateFakeDataAllTImeSnackerList,
  generateFakeDataCreatorList,
  generateFakeDataAllTimeCreatorList,
} from "../helpers/Faker";

import {
  IconSettingsGear,
  IconFeedback,
  IconDiscord,
  IconSettings,
} from "./IconSettings";

import { motion } from 'framer-motion';

import { BeatLoader } from "react-spinners";
import CoverImage from "../../Components/Profile/CoverImage";
import { ProfileImage } from "../../Components/Profile/ProfileImage";

//Analytics
//=============================================
//=============================================
import { sendAnalytics } from 'firebaseConfig';
//=============================================
//=============================================


const _fakeSnackerList = generateFakeDataSnackerList(4);
const _fakeAllTimeSnackerList = generateFakeDataAllTImeSnackerList(5);
const _fakeCreatorList = generateFakeDataCreatorList(4);
const _fakeAllTimeCreatorList = generateFakeDataAllTimeCreatorList(5);

function Profile() {

    useEffect(() => {
        sendAnalytics(`screen_view`, {
            firebase_screen: "Profile page",
            firebase_screen_class: "Profile page"
        })
    }, [])

  const { user, loadUserData } = useAuthContext();
  console.log("User: ", user)
  const isLoggedIn = true
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation();
 
  let _store_wallet = useContext(wallatStore)

  const [loadtopSnakersDaily, settopSnakersDaily] = useState([]);
  // const [dailySnackers, setdailySnackers] = useState([]);
  // const [allTimeSnackers, setAllTimeSnackers] = useState([]);
  // const [dailyCreators, setDailyCreators] = useState([]);
  // const [allTimeCreators, setAllTimeCreators] = useState([]);
  // const [user, setUser] = useState()
  const [loadtopSnakersallTime, settopSnakersallTime] = useState([]);
  const [lastFetchTime, setLastFetchTime] = useState(null);
  const [error, setError] = useState(false);
  const { 
    dailySnackers, setdailySnackers,
    allTimeSnackers, setAllTimeSnackers,
    dailyCreators, setDailyCreators,
    allTimeCreators, setAllTimeCreators } = useTopSnackersList()

  const [selectedTab, setSelectedTab] = useState("Saved");

  

    const fetchUserData = async () => {
        try {

            const res = await axios.get(`${API}/snackr/user/data`, {
                headers: headers()
            })
            const userData = res?.data?.user || {}

            //   console.log(res.data)
            
            _store_wallet.dispatch({
                type: 'initUser',
                payload: { user: userData }
            })
            // setvideos((oldVideos) => [...oldVideos, ...snacks]);
            // setUser(userData)
            console.log("User: ", userData)
            
        } catch (ex) {
          console.log(ex);
        }
    }

  const fetchTopCreators = async () => {
    try {
      console.log("calling ...");
      const res = await axios.get(`${API}/snackr/topcreators`);
      const top_creators = res?.data || [];
      setAllTimeCreators([...top_creators.top_creators_monthly, ..._fakeAllTimeCreatorList]);
      setDailyCreators([...top_creators.top_creators_daily, ..._fakeCreatorList]);
    } catch (ex) {
      console.log(ex);
    }
  };

  const fetchTopSnackRDailyData = async () => {
    settopSnakersDaily(true);
    setError(false);
    try {
      const token = await getToken();
      const res = await axios.get(`${API}/snackr/user/topSnackrofday`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const topSnackr = res?.data?.topSnackrs || [];
      setdailySnackers([...topSnackr, ..._fakeSnackerList]);
      setLastFetchTime(new Date());
    } catch (ex) {
      console.log(ex);
      setError(true);
    }
    settopSnakersDaily(false);
  };

  const getTopSnackerAllTime = async () => {
    settopSnakersallTime(true);
    setError(false);
    try {
      const token = await getToken();
      const res = await axios.get(`${API}/snackr/user/getTopSnackerAllTime`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const topSnackr = res?.data?.topSnackrs || [];
      setAllTimeSnackers([...topSnackr, ..._fakeAllTimeSnackerList]);
      fetchTopCreators();
      setLastFetchTime(new Date());
    } catch (ex) {
      console.log(ex);
      setError(true);
    }
    settopSnakersallTime(false);
  };

  const fetchSnackersList = () => {
    console.log("loading...list..topsnacker");
    fetchTopSnackRDailyData();
    getTopSnackerAllTime();
  };

  const RefreshALLData = () => {
    fetchSnackersList();
    // fetchUserData();
  };

  const shouldRefreshPage = () => {
    if (error) return true;
    if (!lastFetchTime) return false;

    const MinuteAgo = new Date(new Date().getTime() - 2 * 60 * 1000);
    return lastFetchTime < MinuteAgo;
  };


  // useEffect(() => {
  //   setdailySnackers(_fakeSnackerList);
  //   setAllTimeSnackers(_fakeAllTimeSnackerList);
  //   setDailyCreators(_fakeCreatorList);
  //   setAllTimeCreators(_fakeAllTimeCreatorList);
  // }, []);

  useEffect(() => {
    loadUserData()
    // fetchUserData()
    if (user) {
      fetchSnackersList();
    }
  }, []);


  const gotoSettings = () => {
    navigate("/settings")
  };

  const openDiscorURL = () => {
    window.open("https://discord.gg/qgPdWgDYpp", "_blank");
  };

  const gotoEdit = () => {
    navigate("/profile/settings_update_profile");
  };


  const _avatarImageURL = `${Frontend_URL}${user?.avatar_url || "/extra/images/profile/human/Asset 4.png"}`;
  try {
    return (
        
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-[90vh] overflow-y-scroll overflow-x-hidden' >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}

            // initial={{ opacity: 1, translateY: "100vh" }}
            // animate={{ opacity: 1, translateY: "0vh" }}
            // exit={{ opacity: 1, translateY: "100vh" }}

            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto bg-white'>
            {/* <Container> */}
            {/* {console.log(_store_wallet.state)} */}
            <div className="flex-1 bg-white h-full overflow-y-auto">
    <div className="h-full">
    <div className="scroll-container">
      <CoverImage>
        <div className="absolute h-[100px] w-full bottom-[10px] z-[2]">
          <div className="flex-row justify-between items-center">
            <div className="relative top-[70px] right-[20px] px-[40px]">
              <ProfileImage image={_avatarImageURL} id={user?.id} />
            </div>
            <div className="relative pl-[1px] flex items-center top-[12px] justify-end mr-[15px]">
              <div className="ml-[15px]">
                <IconDiscord onClick={openDiscorURL} />
              </div>
              <div className="ml-[15px]">
                <IconSettingsGear onClick={gotoSettings} />
              </div>
              <div className="ml-[15px]">
                <IconSettings onClick={gotoEdit} />
              </div>
            </div>
          </div>
        </div>
      </CoverImage>

      <div className="px-[30px] pt-[70px]">
        <ProfileDetailsCard user={user} isLoggedIn={true} />
      </div>

      {user ? (
        <>
          <div className="px-[15px] pt-[30px]">
            <TopSnackCard loadingData={dailySnackers || allTimeSnackers} />
          </div>
          <div className="mb-[30px] ">
            <HistorySnacks user={user} />
          </div>
        </>
      ) : (
        <div className="mt-[20px] h-[500px]">
          <div className="px-[20px]">
            <LoginPrompt />
          </div>
        </div>
      )}
    </div>
  </div>
</div>
            {/* </Container> */}

        </motion.div>
        </div>
  );

} catch (error) {
    console.log(error)
}
}

export default Profile;
