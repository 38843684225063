import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
// Custom components
import { PublishedSnacksStatsCard } from 'Pages/SubmitSnack/PublishedSnacksStatsCard';

// Background image for the snack card
import bgSnackCard from '../../../../Assets/leaderboard_bg.png';

const Shots = ({ handlePageState = () => {}, data = [] }) => {
  const navigate = useNavigate();

  const goToAddGame = () => {
    navigate('/add_shot');
  };

  const goToDetails = (item) => {
    navigate('/submitted_snack_details', { state: item });
  };

  const goToHelp = () => {
    const item = {
      title: 'Request help',
      subtext: 'What is your issue?',
      placeholder: 'Enter your issue here',
    };
    navigate('/settings_feedback', { state: item });
  };

  const publishedCount = data.filter((item) => item?.status === 'Published').length;
  const inReviewCount = data.filter((item) => item?.status === 'Under-review').length;

  return (
    
    <div className="pb-24">
      {/* Page Heading */}
      <div className="mt-8 mb-[10px] w-full">
        <p className="text-black text-md text-center">The list of shots you have published so far.</p>
      </div>

      {/* Stats Card */}
      <PublishedSnacksStatsCard
        published_count={publishedCount}
        in_review_count={inReviewCount}
        actionFunction={goToAddGame}
        actionLabel="Add Shot"
      />

      {/* Snack List */}
      <div
        className="rounded-xl mt-4 px-2 overflow-hidden"
        style={{
          backgroundImage: `url(${bgSnackCard})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="h-[300px] overflow-auto">
          <div className="pb-24">
            {data.length > 0 ? (
              data.map((item, index) => (
                <button
                  key={index}
                  onClick={() => goToDetails(item)}
                  className="mt-2 w-full flex items-center justify-between bg-violet-100 py-2 px-5 rounded-lg shadow"
                >
                  <div className="w-1/2">
                    <p className="text-black font-medium">{item?.snack_name || ''}</p>
                    <p className="text-black truncate">{item?.snack_description || ''}</p>
                  </div>
                  <div
                    className={`w-1/3 py-1 rounded-full text-center ${
                      item?.status === 'Published'
                        ? 'bg-green-100 text-green-500'
                        : item?.status === 'Under-review'
                        ? 'bg-blue-100 text-blue-500'
                        : item?.status === 'Rejected'
                        ? 'bg-red-100 text-red-500'
                        : ''
                    }`}
                  >
                    {item?.status || ''}
                  </div>
                </button>
              ))
            ) : (
              <div className="w-full h-[100px] flex items-center justify-center">
                <p className="text-black text-xl">No Shots submitted yet!</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Help Link */}
      <div className="text-center py-[10px]">
        <button onClick={goToHelp} className="text-blue-500 hover:underline"
        style={{
            color: '#3b82f6', 
            textDecorationLine: 'underline', 
            textDecorationStyle: 'solid', 
            cursor: 'pointer', 
            transition: 'color 150ms ease-in-out'
        }}
        >
          Need Help? Click here to contact us
        </button>
      </div>
    </div>
  );
};

export default Shots;
