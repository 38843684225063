import React, { useState, useEffect, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Store as wallatStore } from "StoreContext/wallatStore";
import { headers } from 'helpers';
import { motion } from "framer-motion";
// Pages
import Prompt from "./Prompt";
import TermsAndConditions from "./TermsAndConditions";
import RegistrationPageOne from "./RegistrationPageOne";
import RegistrationPageTwo from "./RegistrationPageTwo";
import RegistrationPageThree from "./RegistrationPageThree";
import UnderReview from "./UnderReview";
import Accepted from "./Accepted";
import Rejected from "./Rejected";
import Dashboard from "./Dashboard";

// Utils and context
import { getToken } from "../../helpers";
import { API } from "../../constant";
import axios from "axios";

export default function Index() {
  const [pageState, setPageState] = useState("");
  const [user, setUser] = useState();
  const publisher_registration_status = user?.user?.publisher_registration_status || "";
  const navigate = useNavigate();

  let _store_wallet = useContext(wallatStore)

  // Input values
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const [selectedRole, setSelectedRole] = useState({
    is_game_dev: false,
    is_animator: false,
    is_comic_artist: false,
  });

  const [inputVals, setInputVals] = useState({
    phone_number: "",
    porfolio_link: "",
    Discord: "",
    Youtube: "",
    Instagram: "",
    X: "",
    Telegram: "",
    Facebook: "",
  });

  const [selectedFiles, setSelectedFiles] = useState([]);

  const clearAllInputStates = () => {
    setInputVals({
      phone_number: "",
      porfolio_link: "",
      Discord: "",
      Youtube: "",
      Instagram: "",
      X: "",
      Telegram: "",
      Facebook: "",
    });
    setSelectedRole({
      is_game_dev: false,
      is_animator: false,
      is_comic_artist: false,
    });
    setSelectedFiles([]);
  };

  const fetchUserData = async () => {
    try {
        
        const res = await axios.get(`${API}/snackr/user/data`, {
            headers: headers()
        })
        const userData = res?.data?.user || {}

        //   console.log(res.data)

        _store_wallet.dispatch({
            type: 'initUser',
            payload: { user: userData }
        })
        // setvideos((oldVideos) => [...oldVideos, ...snacks]);
        setUser(userData)
        console.log("user data: ",userData)
    } catch (ex) {
      console.log(ex)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, []);

  
  const apicall_register = async () => {
    const query = {
      is_developer: selectedRole.is_game_dev,
      is_animator: selectedRole.is_animator,
      is_comic_artist: selectedRole.is_comic_artist,
      porfolio_link: inputVals.porfolio_link,
      Discord: inputVals.Discord,
      Youtube: inputVals.Youtube,
      Instagram: inputVals.Instagram,
      X: inputVals.X,
      Telegram: inputVals.Telegram,
      Facebook: inputVals.Facebook,
      agreed_to_contact: true,
      name: user?.user?.username,
      email: user?.user?.email,
      phone: inputVals.phone_number,
      users_permissions_user: user?.user?.id,
    };

    
    
    const formData = new FormData();
    formData.append("data", JSON.stringify(query));

    selectedFiles.forEach((file) => {
      formData.append("files.sample_file", file);
    });

    setLoading(true);

    try {
      const token = await getToken();
      await axios.post(`${API}/submitted-user-datas`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });

      await apicall_change_status("under-review");
      clearAllInputStates();
      handlePageState("under-review");
    } catch (ex) {
      console.error("Error submitting form:", ex);
      setError(ex?.response?.data?.error?.message || "Submission failed");
    } finally {
      setLoading(false);
    }
  };

  const apicall_change_status = async (status = "") => {
    const token = await getToken();
    try {
      await axios.post(
        `${API}/snackr/user/update_under_review_status`,
        { publisher_registration_status: status },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      // loadUserData();
    } catch (ex) {
      console.error("Error submitting form:", ex?.response?.data);
      setError(ex?.response?.data?.error?.message || "Submission failed");
    }
  };

  const handlePageState = (state) => {
    setPageState(state);
  };

  const checkAlreadyRegistered = () => {
    console.log(user)
    if (!user) {
      navigate("/login");
      return;
    }
    handlePageState("terms-and-condition");
  };

  useEffect(() => {
    if (publisher_registration_status === "under-review") {
      setPageState("under-review");
    } else if (publisher_registration_status === "accepted") {
      setPageState("accepted");
    } else if (publisher_registration_status === "rejected") {
      setPageState("rejected");
    } else if (publisher_registration_status === "registered") {
      setPageState("dashboard");
    } else {
      setPageState("prompt");
    }
  }, [publisher_registration_status]);

  

  return (
    <div className="flex flex-col">
      {pageState === "prompt" && <Prompt checkAlreadyRegistered={checkAlreadyRegistered} />}
      {pageState === "terms-and-condition" && <TermsAndConditions handlePageState={handlePageState} />}
      {pageState === "registration-one" && (
        <RegistrationPageOne
          handlePageState={handlePageState}
          user={user?.user || {}}
          // loadUserData={loadUserData}
          loadUserData={user}
          inputVals={inputVals}
          setInputVals={setInputVals}
        />

        
        
      )}
      {pageState === "registration-two" && (
        <RegistrationPageTwo
          handlePageState={handlePageState}
          user={user?.user || {}}
          // loadUserData={loadUserData}
          loadUserData={user}
          inputVals={inputVals}
          setInputVals={setInputVals}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
        />
      )}
      {pageState === "registration-three" && (
        <RegistrationPageThree
          handlePageState={handlePageState}
          user={user?.user || {}}
          // loadUserData={loadUserData}
          loadUserData={user}
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
          apicall_register={apicall_register}
        />
      )}
      {pageState === "under-review" && 
        <UnderReview handlePageState={handlePageState} />
      }
      {pageState === "accepted" && (
        <Accepted handlePageState={handlePageState} apicall_change_status={apicall_change_status} />
      )}
      {pageState === "rejected" && (
        <Rejected handlePageState={handlePageState} apicall_change_status={apicall_change_status} />
      )}
      {pageState === "dashboard" && <Dashboard handlePageState={handlePageState} />}
    </div>
   
  );
}
