import React from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { SignInWithGoogle } from "../../Auth/GoogleSignIn";
// import { useUser } from "../Auth/AuthContext";
const webClientId = "337888192497-bs50dde665907o17i7vgkcagf7d5d3tn.apps.googleusercontent.com"

export default function GoogleLoginButton() {
//   const { loadUserData } = useUser(); // Context method to load user data
//   const { initEconomyData } = useEconomy(); // Context method to initialize economy data
  const handleSuccess = async (response) => {
    const googleToken = response.credential; // Google token from the login
    await SignInWithGoogle(googleToken);
  };

  const handleError = () => {
    console.error("Google Sign-In failed.");
    alert("Google Sign-In failed. Please try again.");
  };

  return (
    // <div className="flex justify-center items-center mt-6">
    //   <GoogleLogin
    //     onSuccess={handleSuccess}
    //     onError={handleError}
    //     useOneTap
    //   />
    // </div>

    <GoogleOAuthProvider clientId={webClientId}>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <h1 className="text-2xl font-semibold text-gray-700 mb-6">Google Sign In</h1>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          useOneTap 
        />
      </div>
    </GoogleOAuthProvider>
  );
}



{/* <GoogleOAuthProvider clientId={webClientId}>
      <div className="flex flex-col items-center justify-center h-screen bg-gray-50">
        <h1 className="text-2xl font-semibold text-gray-700 mb-6">Google Sign In</h1>
        <GoogleLogin
          onSuccess={handleSuccess}
          onError={handleError}
          text="continue_with"
          size="large"
          shape="pill"
          theme="filled_blue"
          className="shadow-lg rounded-lg"
        />
      </div>
    </GoogleOAuthProvider> */}