import { useLocation, useParams, useSearchParams, matchPath } from 'react-router-dom';

import DiscoverPage from "./Discover"
import FavouritePage from "./Favourite"
import StorePage from "./Store"
import Profile from './Profile';
import SubmitSnack from './SubmitSnack';
import { AnimatePresence } from 'framer-motion';
import DeleteUser from './DeleteUser';
import SettingsPage from './Profile/SettingsPage';
import UpdateProfile from './Profile/ProfileSettings';
import TopSnackerScreen from './TopSnackerPage';
import Publisher from './Home/PublisherDetails';
import PublishSnackGame from './SubmitSnack/Dashboard/Games/Add';
import PublishSnackComic from './SubmitSnack/Dashboard/Comics/Add';
import PublishSnackShot from './SubmitSnack/Dashboard/Shots/Add';
import SubmittedSnack from './SubmitSnack/SubmittedSnackDetails';
import Feedback from './Profile/SettingsPage/Feedback';
import FollowingPublishers from './Profile/FollowingPublishers';

function PageLoader() {

    const { pathname } = useLocation();

    const isDiscover = matchPath("/discover", pathname);
    const isFavourite = matchPath("/favourite/*", pathname);
    const isProfile = matchPath("/profile/*", pathname);
    const isStore = matchPath("/store/*", pathname);
    const isCreator = matchPath("/creator/*", pathname);
    const isDeleteUser = matchPath("/profile/settings_delete_user", pathname);
    const isPublisher = matchPath("/submitsnack/*", pathname);
    const isSettings = matchPath("/settings", pathname);
    const isUpdateProfile = matchPath("/profile/settings_update_profile", pathname);
    const isTopSnackerList = matchPath("/top_snackers", pathname);
    const isAddGame = matchPath("/add_game", pathname)
    const isAddComic = matchPath("/add_comic", pathname)
    const isAddShot = matchPath("/add_shot", pathname)
    const isSnackDetails = matchPath("/submitted_snack_details", pathname)
    const isFeedback = matchPath("/settings_feedback", pathname)
    const isFollowing = matchPath("/following_publishers", pathname)

    return (<div className='relative max-w-[400px] mx-auto '>
        {/* <AnimatePresence > */}

        {
            isDiscover && <DiscoverPage  key={"discovery_page"}/>
        }
        {
            isFavourite && <FavouritePage key={"Favourite_page"} />
        }
        {
            isStore && <StorePage key={"Store_page"} />
        }
        {
            isProfile && <Profile key={"Profile_page"} />
        }
        {
            isDeleteUser && <DeleteUser key={"delete_page"} />
        }
        {
            isPublisher && <SubmitSnack key={"Submit_Snack"} />
        }
        {
            isSettings && <SettingsPage key={"Settings_Page"} />
        }
        {
            isUpdateProfile && <UpdateProfile key={"Update_Profile_Page"} />
        }
        {
            isTopSnackerList && <TopSnackerScreen key={"Top_Snacker_Page"} />
        }
        {
            isCreator && <Publisher key={"Publisher_Page"} />
        }
        {
            isAddGame && <PublishSnackGame key={"Add_Game_Page"} />
        }
        {
            isAddComic && <PublishSnackComic key={"Add_Comic_Page"} />
        }
        {
            isAddShot && <PublishSnackShot key={"Add_Shot_Page"} />
        }
        {
            isSnackDetails && <SubmittedSnack key={"Submitted_Snack_Details"} />

        }
        {
            isFeedback && <Feedback key={"Feedback"} />

        }
        {
            isFollowing && <FollowingPublishers key={"Following_Publishers"} />

        }
        
        {/* </AnimatePresence> */}
    </div>);
}

export default PageLoader;