import React, { useState } from "react";
import { motion } from "framer-motion";

export default function RegistrationPageTwo({
  user = {},
  loadUserData,
  handlePageState = () => {},
  inputVals,
  setInputVals,
  selectedRole,
  setSelectedRole,
}) {
  const roleImages = {
    "Game Developer": require("./assets/game-dev.png"),
    "Comic Artist": require("./assets/comic-artist.png"),
    "Animator": require("./assets/animator.png"),
  };

  const socials = [
    { name: "Discord", src: require("../../Assets/socials/discord.png") },
    { name: "Youtube", src: require("../../Assets/socials/youtube.png") },
    { name: "Instagram", src: require("../../Assets/socials/instagram.png") },
    { name: "X", src: require("../../Assets/socials/X.png") },
    { name: "Telegram", src: require("../../Assets/socials/telegram.png") },
    { name: "Facebook", src: require("../../Assets/socials/facebook.png") },
  ];

  const handleInputChange = (text, name) => {
    setInputVals({
      ...inputVals,
      [name]: text,
    });
  };

  const nextPage = async () => {
    if (
      !selectedRole.is_game_dev &&
      !selectedRole.is_animator &&
      !selectedRole.is_comic_artist
    ) {
      alert("Please select a role");
      return;
    }
    handlePageState("registration-three");
  };

  const SelectableButtons = ({ role, label }) => {
    const [selected, setSelected] = useState(true);

    const handleSelect = (button) => {
      let new_roles = { ...selectedRole}
       
      new_roles[button] = !new_roles[button]
      setSelectedRole(new_roles)
      setSelected(!selected)
      
  };

    return (
      <div
        className={`flex flex-col items-center rounded-lg p-4 cursor-pointer`}
        style={{
          backgroundColor: "#1E1032",
          width: "150px",
          height: "100px",
          justifyContent: "center",
          padding: 2,
          marginInline: 4,
          border: selectedRole[role] ? "4px solid white" : "4px solid #7558FF", 
          boxShadow: selectedRole[role] ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
          
        }}
        onClick={() => handleSelect(role)}
      >
        <img
          src={roleImages[label]}
          alt={label}
          style={{ width: "50px", height: "50px" }}
        />
        <p className="text-white text-sm font-bold">{label}</p>
      </div>
    );
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
        style={{backgroundColor: "#6242F9", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    <div className="min-h-screen flex flex-col" style={{ backgroundColor: "#6242F9"}}>
      {/* Header Section */}
      <div className="bg-[#7871FF] flex flex-col w-full rounded-b-lg" style={{padding: "20px"}}>
        <div className="flex items-center">
          <button
            className="mr-4"
            onClick={() => handlePageState("registration-one")}
          >
            <img
              src={require("../../Assets/left-arrow.png")}
              alt="Back"
              style={{ width: "12px", height: "16px" }}
            />
          </button>
          <h1 className="text-white text-xl font-bold">
            Creator Profile Registration
          </h1>
        </div>
      </div>

      {/* Content Section */}
      <div className="flex-1 px-8 py-6" style={{paddingInline: 10, marginBottom: "100px", overFlowY: "auto"}}>
        <h2 className="text-white text-center text-xl font-bold" style={{margin: 15}}>
          Select what Describes you
        </h2>

        {/* Role Selection */}
        <div className="flex justify-center gap-8 mb-8" style={{gap: 2, marginBottom: 15}}>
          <SelectableButtons role="is_game_dev" label="Game Developer" />
          <SelectableButtons role="is_comic_artist" label="Comic Artist" />
          <SelectableButtons role="is_animator" label="Animator" />
        </div>

        {/* Portfolio Section */}
        <div className="mb-8" style={{paddingInline: 10, marginBottom: 15}}>
          <label className="text-white font-semibold mb-2 block">
            Your Portfolio
          </label>
          <input
            type="text"
            name="porfolio_link"
            value={inputVals.porfolio_link || ""}
            onChange={(e) =>
              handleInputChange(e.target.value, "porfolio_link")
            }
            placeholder="Portfolio Link"
            className="w-full h-[40px] rounded-md border border-gray-400 shadow-sm"
            style={{padding: 5}}
          />
        </div>

        {/* Social Media Section */}
        <div style={{paddingInline: 10, marginBottom: 10}}>
          <h3 className="text-white font-semibold mb-2">Select Social Media</h3>
          <div className="space-y-4">
            {socials.map((social, index) => (
              <div key={index} className="flex items-center" style={{gap: 4}}>
                <img
                  src={social.src}
                  alt={social.name}
                  style={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "8px",
                    margin: 8,
                    marginLeft: 0
                  }}
                />
                <input
                  type="text"
                  name={social.name}
                  value={inputVals[social.name] || ""}
                  onChange={(e) => handleInputChange(e.target.value, social.name)}
                  placeholder={`Enter your ${social.name} link here`}
                  className="flex-1 h-[40px] rounded-md border border-gray-400 shadow-sm"
                  style={{padding: 5}}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Next Button */}
        <div className="flex justify-center mt-8">
          <button
            onClick={nextPage}
            className="w-[100px] px-8 py-3 text-lg font-bold text-white bg-transparent border border-white rounded-lg hover:bg-white hover:text-purple-700 transition"
            style={{margin: 10, padding: 6}}
          >
            NEXT
          </button>
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
}

const styles = {
  activeRole: {
    border: 4,
    
  }
}