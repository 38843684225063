import React from 'react';
import { motion } from 'framer-motion';

export default function UnderReview({ handlePageState = () => {} }) {
    return (
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
        style={{backgroundColor: "#6242F9", }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
        {/* <div className="bg-black min-h-screen"> */}
            <div className="flex-1 mt-16 h-full" style={{backgroundColor: "#6242F9"}}>
                <div className="flex-1 overflow-y-scroll">
                    {/* Header Section */}
                    <div className="flex flex-col w-full rounded-b-lg" style={{backgroundColor: "#7871FF", padding: "20px"}}>
                        <div className="flex items-center">
                            {/* Uncomment and add functionality if back button is needed */}
                            {/* <button onClick={() => handlePageState("terms-and-condition")} className="p-2">
                                <img src="../SettingsPage/assets/left-arrow.png" alt="Back" className="w-8 h-8" />
                            </button> */}
                            <h1 className="text-white text-xl font-bold" style={{marginLeft: 8}}>Creator Profile Registration</h1>
                        </div>
                    </div>

                    {/* Main Content */}
                    <div className="flex flex-col items-center justify-center mt-20 " style={{padding: "20px"}}>
                        <h2
                            className="font-bold text-2xl text-white text-center"
                            style={{
                                textShadow: '0 4px 5px rgba(0, 0, 0, 0.5)',
                                marginLeft: 4
                            }}
                        >
                            Your Application is In! 🎉
                        </h2>
                        <img
                            src={require("./assets/under-review.png")}
                            alt="Under Review"
                            className="mt-4 scale-125"
                            style={{width: "200px", height: "250px", margin: 2}}
                        />
                        <p className="font-semibold text-lg text-center text-white">
                            Thank you for submitting your application. Our team is reviewing it, and we’ll get back to
                            you within 7 days.
                        </p>
                        <h2 className="font-bold text-white text-2xl" style={{marginTop: "50px"}}>PROCESSING...</h2>
                    </div>
                </div>
            </div>
        {/* </div> */}
        </motion.div>
        </div>
    );
}
