import React from "react";

export const AvatarRound = ({ profilePic = "", onClick = () => {} }) => {
  return (
    <button
      onClick={onClick}
      style={{
        height: "70px",
        width: "70px",
        backgroundColor: "#C4B5FD", // equivalent to `bg-violet-300`
        borderRadius: "50%",
        border: "5px solid #A5E05C",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        cursor: "pointer",
      }}
    >
      <img
        src={profilePic}
        alt="Avatar"
        style={{
          width: "65px",
          height: "65px",
          margin: "auto",
          objectFit: "cover",
        }}
      />
    </button>
  );
};
