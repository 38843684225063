import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";


//Store
// import { Store } from '../../Store/wallatStore'
// import Container from '../../Component/Container';


// import ReactGA from "react-ga4";
// const TRACKING_ID = "G-98TS1L459T";
// ReactGA.initialize(TRACKING_ID)



function Profile() {

    //Wallat
    // let _store = useContext(Store)

    // useEffect(() => {
    //     ReactGA.send({ hitType: "pageview", page: "/favourites", title: "Favourites page" });
    //   }, []);


    return (<div className='fixed top-0 left-0 z-[200] bg-zinc-900 h-full w-full  '>
        {/* <Container> */}
            <div className="px-[12px] pt-[80px] bg-zinc-900 min-h-full">


        <div className="text-[18px] font-bold text-white  text-center bg-zinc-800 rounded-lg py-[10px] px-[10px]">
            Favourite snacks
            <div className="pt-[10px] text-white">
                {/* {_store?.state?.favouriteGames?.length <= 0 && <div className='text-[14px] font-normal text-slate-500'> Nothing here. Not hungry yet?  </div>} */}
            </div>
            <div className="text-[18px] font-bold text-white py-[10px]">
                <div className="grid grid-cols-3 gap-[15px]">
                    {/* {
                        _store?.state?.favouriteGames?.map(game => {
                            return (<GameCard key={game?.id} item={game} />)
                        })
                    } */}

                </div>
                {/* Score earned */}
            </div>
        </div>
        </div>
        {/* </Container> */}



    </div>);
}

export default Profile;


const GameCard = (props) => {

    const { item = "" } = props

    const location = useNavigate()

    const openSnack = () => {

        const { id, snack_type, game_url, gamename } = item

        if (snack_type == "game") {

            location(`/?game_url=${game_url}&&snack_type=${snack_type}&&snack_id=${id}&&snack_name=${gamename}`)

        } else if (snack_type == "comic") {

            location(`/?snack_type=${snack_type}&&snack_id=${id}&&snack_name=${gamename}`)

        } if (snack_type == "reels") {

            location(`/?game_url=${game_url}&&snack_type=${snack_type}&&snack_id=${id}&&snack_name=${gamename}`)
        }
    }


    // console.log(item)
    
    return (<motion.div 
        initial={{ opacity: 0, _translateY: "100vh" }}
        animate={{ opacity: 1, _translateY: "0vh" }}
        exit={{ opacity: 0, _translateY: "100vh" }}
        // transition={{ type: "Tween", stiffness: 50 }}
        onClick={() => openSnack()}
        className='bg-white w-full rounded-xl h-full min-h-[100px] overflow-hidden text-black relative'>
        {/* {item?.game_cover} */}
        <img src={item?.cover_image} alt="" className='w-full' />

    </motion.div>)
}
