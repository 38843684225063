import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API, Frontend_URL } from "../../../../constant";
import { getToken, setToken } from "../../../../helpers";
import { motion } from "framer-motion";
import { PrimaryButton } from "Components/ButtonPrimary";


const avatarList = [
  "/extra/images/profile/human/Asset 1.png",
  "/extra/images/profile/human/Asset 2.png",
  "/extra/images/profile/human/Asset 4.png",
  "/extra/images/profile/human/Asset 5.png",
  "/extra/images/profile/human/Asset 6.png",
  "/extra/images/profile/human/Asset 7.png",
  "/extra/images/profile/Vegies/Asset 4.png",
  "/extra/images/profile/Vegies/Asset 5.png",
  "/extra/images/profile/Vegies/Asset 6.png",
  "/extra/images/profile/Vegies/Asset 7.png",
  "/extra/images/profile/Vegies/Asset 8.png",
  "/extra/images/profile/Vegies/Asset 9.png",
];

const ChangeAvatar = ({handlePageState = () => {}, user}) => {
  const navigate = useNavigate();

  const [activeImage, setActiveImage] = useState("/extra/images/profile/Asset 1.png");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const goBack = () => {
    handlePageState("update-profile")
  }

  useEffect(() => {
    if (user?.avatar_url) {
      setActiveImage(user.avatar_url);
    }
  }, [user]);

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API}/snackr/user/updateAvatar`,
        { avatarURL: activeImage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = response.data;
      if (data.error) {
        throw new Error(data.error);
      } else {
        await setToken(data.jwt);
        // loadUserData();
        alert("Avatar updated successfully!");
        navigate("/profile");
      }
    } catch (ex) {
      setError(
        ex.response?.data?.error?.message ||
          ex.message ||
          "Something went wrong!"
      );
    }
    setIsLoading(false);
  };


  return (
    
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll' 
    style={{backgroundColor: "#0E0C1C"}}>
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto'>
    <div className="bg-[#0E0C1C] min-h-screen">
      <div className="px-[10px] py-[5px] border-b-2 border-[#b58ef7]" style={{marginBottom: 16}}>
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={require("../../../../Assets/left-arrow.png")}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">Change Avatar</h1>
        </div>
      </div>

      <div className=" px-[15px] py-[5px] ml-4 grid grid-cols-3 sm:grid-cols-3 gap-6 items-center" >
        {avatarList.map((item) => (
          <div
            key={item}
            className={`border-4 overflow-hidden cursor-pointer transition-all w-[90px] h-[90px] ${
                item === activeImage
                  ? "border-violet-500"
                  : "border-gray-300 hover:border-violet-300"
              }`}
            onClick={() => setActiveImage(item)}
          >
            <img
              src={`${Frontend_URL}${item}`}
              alt="Avatar"
              className="w-full h-auto"
            />
          </div>
        ))}
      </div>

      {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      <div className="px-[15px] pt-[25px]">
        <PrimaryButton label={"Change Avatar"} onClick={handleSubmit} isLoading={isLoading} 
            className="w-full"
        />
      </div>
    </div>
    </motion.div>
    </div>
  );

}

export default ChangeAvatar;
