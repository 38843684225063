// import React from 'react';
// import { ChangePassword } from './ChangePassword';
// import { ChangeUserName } from './ChangeUserName';


// export const ProfileSettings = () => {
//     return (
//         <div className="overflow-y-scroll h-[80vh]">
//             <ChangePassword />
//             <ChangeUserName />
//             <div className="my-[200px]"></div>

//         </div>
//     );
// };

import { useAuthContext } from "Auth/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { getToken, setToken } from "helpers";
import { API, Frontend_URL } from "../../../constant";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Store as wallatStore } from "StoreContext/wallatStore";
import _left_arrow from "../../../Assets/left-arrow.png";
// import discordIcon from "../assets/discord.png";
// import youtubeIcon from "../assets/youtube.png";
// import instagramIcon from "../assets/instagram.png";
// import xIcon from "../assets/X.png";
// import telegramIcon from "../assets/telegram.png";
import banner from "../../../Assets/banner_placeholder.png";
import editIcon from "../../../Assets/edit_icon.png";
import { headers } from "helpers";
import ChangeAvatar from "./ChangeAvatarPage";


const UpdateProfile = () => {
  const navigate = useNavigate();
  const [inputVals, setInputVals] = useState({
    username: "",
    email: "",
  });

  let _store_wallet = useContext(wallatStore)

  // const [user, setUser] = useState()
  const {user, loadUserData} = useAuthContext()
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [pageState, setPageState] = useState("update-profile")

  const fetchUserData = async () => {
    try {

        const res = await axios.get(`${API}/snackr/user/data`, {
            headers: headers()
        })
        const userData = res?.data?.user || {}

        //   console.log(res.data)

        _store_wallet.dispatch({
            type: 'initUser',
            payload: { user: userData }
        })
        // setvideos((oldVideos) => [...oldVideos, ...snacks]);
        // setUser(userData)
        console.log("user data: ",userData)
    } catch (ex) {

    }
  }

  useEffect(() => {
    // fetchUserData()
    loadUserData()
  }, [])

  useEffect(() => {
    setInputVals({
      username: user?.username || "",
      email: user?.email || "",
    });
  }, [user]);

  const handlePageState = (newState) => {
    setPageState(newState);
    console.log(pageState)
  };

  const handleInputChange = (value, key) => {
    setError("");
    setInputVals((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleSubmit = async () => {
    alert("Feature not available in beta version");
    return;
    setIsLoading(true);
    try {
      const value = {
        username: inputVals.username,
      };
      const token = await getToken();
      const response = await axios.post(
        `${API}/snackr/user/changePassword`,
        {
          ...value,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response?.data;
      if (data?.error) {
        throw data?.error;
      } else {
        await setToken(data.jwt);
        setIsLoading(false);
        // loadUserData();
        alert("Success", "Profile updated");
        setInputVals({
          username: "",
          email: "",
        });
      }
    } catch (ex) {
      setError(
        ex.response?.data?.error?.message ??
          ex?.message ??
          "Something went wrong!"
      );
    }
    setIsLoading(false);
  };
  
  return (<>
  
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll bg-white' 
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    {pageState === "update-profile" && (
    <div className="flex flex-col bg-[#7871FF] min-h-screen">
      <div>
        <div className="bg-white">
          {/* Banner Section */}
          <div className="relative">
          <div className="absolute w-full bg-[#7871FF] px-4 py-2 rounded-b-lg" style={{padding: 18, paddingLeft: 30}}>
              <div className="flex items-center">
                <button
                  onClick={() => navigate("/profile")}
                  className="p-2 flex items-center"
                >
                  <img
                    src={_left_arrow}
                    alt="Back"
                    className="w-5 h-5 object-contain"
                  />
                </button>
                <h1 className="text-white text-lg font-bold ml-8">
                  UPDATE PROFILE
                </h1>
              </div>
            </div>
            <img src={banner} alt="Banner" className="w-full h-[200px]" />
            

            {/* Profile Picture */}
            <div className="absolute bottom-[-40px] left-1/2 transform -translate-x-1/2">
              <div className="relative">
                <img
                  src={`${Frontend_URL}${user?.avatar_url}`}
                  alt="Profile"
                  className="w-20 h-20 rounded-full border-2 border-white bg-white shadow-md"
                />
                <button
                  onClick={() => handlePageState("change-avatar")}
                  className="absolute bottom-0 right-0 w-8 h-8 bg-white rounded-full flex items-center justify-center shadow-md"
                >
                  <img src={editIcon} alt="Edit Avatar" className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>

          {/* User Info */}
          <div className="text-center mt-12">
            <h2 className="text-lg text-black">{inputVals.username}</h2>
            <p className="text-gray-500 text-xs">{inputVals.email}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 bg-white px-6 py-4">
        {/* Display Name */}
        <div>
          <label className="text-gray-500 text-sm">Display Name</label>
          <input
            type="text"
            name="username"
            value={inputVals.username}
            handleInputChange={(e) => handleInputChange("Name", e.target.value)}
            placeholder="Display Name"
            className="bg-gray-100 text-gray-700 rounded-lg border border-gray-300 px-3 py-2 mt-2 w-full"
            style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",}}
          />
        </div>

        {/* Email */}
        <div className="mt-4">
          <label className="text-gray-500 text-sm">Email (cannot be changed)</label>
          <input
            type="email"
            name="email"
            value={inputVals.email}
            placeholder="Email"
            className="bg-gray-200 text-gray-700 rounded-lg px-3 py-2 mt-2 w-full"
            style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",}}
            disabled
          />
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 text-center mt-4">{error}</p>}

        {/* Save Changes Button */}
        <div className="flex justify-center mt-6">
          <button
            onClick={handleSubmit}
            disabled={isLoading}
            className={`bg-[#6141F8] text-white px-4 py-2 rounded-lg ${
              isLoading ? "opacity-50 cursor-not-allowed" : "hover:bg-[#4A31C7]"
            }`}
          >
            {isLoading ? (
              <p className="text-white">...</p>
            ) : (
              "Save the Changes"
            )}
          </button>
        </div>
      </div>
    </div>
    )}

    {pageState === "change-avatar" && (
        <ChangeAvatar handlePageState={handlePageState} user={user} />
    )}
    </motion.div>
    </div>
    
    </>
  );
};

export default UpdateProfile;

