import React, { useEffect } from "react";
import { useUI } from "UIContext/UIContext";
import { useNavigate } from "react-router-dom";
import { PrimaryButtonBoxy } from "Components/ButtonPrimary";
import bgSnackCard from "./assets/cardBg.png";
import happyFace from "./assets/happyFace.png";
import iconClose from "./assets/close.png";
import { motion } from "framer-motion";
export default function SignupBonus() {
  const { setModal } = useUI();
  const navigate = useNavigate();

  const handleClose = () => {
    setModal("");
  };

  const goToFeedback = () => {
    navigate("/settings_feedback"); // Updated for React Router paths
    handleClose();
  };

  useEffect(() => {
    // analyticsLogEvent("feedback_prompt_displayed");
  }, []);

  return (
    < motion.div
            initial={{ opacity: 1, translateY: "100vh" }}
            animate={{ opacity: 1, translateY: "0vh" }}
            exit={{ opacity: 1, translateY: "100vh" }}
            // transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen hide-scrollbar">
            
        <div className="_container _mx-auto md:max-w-[400px]  ">
    <div className="w-full h-full bg-black/80 fixed left-0 top-0 flex items-center justify-center">
      <div className="relative">
        {/* Close Button */}
        <button
          onClick={handleClose}
          className="absolute top-[-2.5vh] right-[-2.5vw] z-[12] bg-transparent border-none"
        >
          <img
            src={iconClose}
            alt="Close"
            className="w-[6vw] h-[6vw] min-w-[42px] min-h-[42px]"
          />
        </button>

        {/* Card Content */}
        <div className="rounded-xl overflow-hidden">
          <div
            className="py-[1vh] rounded-lg w-[70vw] mx-auto shadow-lg bg-cover bg-center"
            style={{ backgroundImage: `url(${bgSnackCard})` }}
          >
            {/* Feedback Content */}
            <div className="flex flex-col items-center mb-[2vh] mt-[3vh]">
              <img
                src={happyFace}
                alt="Happy Face"
                className="w-[15.1vw] h-[15.1vw] mx-auto"
              />
              <h2 className="text-slate-500 text-[1.2rem] font-bold text-center mb-[1vh]">
                Psst... Got Any Feedbacks?
              </h2>
              <p className="text-slate-500 text-center text-[1rem] mb-[1vh] w-[50vw]">
                You're among the first to experience our app & your opinion
                matters. We'd love to hear your thoughts.
              </p>
            </div>

            {/* Feedback Button */}
            <div className="py-[1.5vh]">
              <PrimaryButtonBoxy
                onClick={goToFeedback}
                label={"Share Feedback"}
                className="w-[50vw] rounded-[10px] mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </motion.div>
  );
}
