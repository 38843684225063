import axios from 'axios';
import { motion, AnimatePresence } from "framer-motion"


import React, { useEffect, useState, useContext } from 'react';
import { matchPath, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";


// import { createClient } from "pexels";

import VideoCard from "Components/VideoCard";
import { useUI } from 'UIContext/UIContext';
import { Store as snackStore } from 'StoreContext/snackStore'
import { Store as walletStore } from 'StoreContext/wallatStore'

import GamePlay from 'Snacking/Game';
import ComicPlay from 'Snacking/Comic';
import ReelPlay from 'Snacking/Reel';

import { API, BASE_URL } from 'constant';
import { headers, randomSort } from 'helpers';
import PopOverlay from 'Components/PopupOverlay';
import PublisherDetails from './PublisherDetails';
import { getToken } from 'helpers';
import BeatLoader from "react-spinners/BeatLoader";
import { useFirstTimeCheck } from './useFirstTimeCheck';
import { SwipeUpAnimation } from './SwipeUpAnimation';
import { useAuthContext } from 'Auth/AuthContext';
import { sendAnalytics } from 'firebaseConfig';



function Home() {

  //Analytics
  useEffect(() => {
    sendAnalytics(`screen_view`, {
      firebase_screen: "Home page",
      firebase_screen_class: "Home page"
    })
  }, [])


  const {user = {}} = useAuthContext()
  const [pageState, setPageState] = useState("home");
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { setModal, setFtueState, ftueState } = useUI()
  const game_url = searchParams.get('game_url');
  // const reel_url = searchParams.get('reel_url');
  const snack_type = searchParams.get('snack_type');
  const snack_id = searchParams.get('snack_id');
  const snack_name = searchParams.get('snack_name');


  let _store_snack = useContext(snackStore)

  // console.log(_store_snack?.state)
  let videos = _store_snack.state.snacks || []


  // const [videos, setvideos] = useState([]);
  const [videosLoaded, setvideosLoaded] = useState(false);


  const randomQuery = () => {
    const queries = ["Funny", "Art", "Animals", "Coding", "Space"];
    return queries[Math.floor(Math.random() * queries.length)];
  };

  // const getVideos = (length) => {
  //   // Replace with your Pexels API Key
  //   const client = createClient("ssGqWHWRuj17kjZhoH9bpb4oR1ncZLYjc9hxk3GdvSPFl08waifGx9Lp");

  //   const query = randomQuery();
  //   client.videos
  //     .search({ query, per_page: length })
  //     .then((result) => {
  //       // setvideos((oldVideos) => [...oldVideos, ...result.videos]);
  //       setvideosLoaded(true);
  //     })
  //     .catch((e) => setvideosLoaded(false));
  // };

  // const url = `http://localhost:1337`

  //==================================================
  //FTUE
  //==================================================

  const { isFirstTime, setRegularUsage } = useFirstTimeCheck()

  const [isFTUEVisible, setIsFTUEVisible] = useState(true);

  const triggerFTUE = (setIsVisible, seconds) => {
    setTimeout(() => {
      setIsVisible(false);
    }, seconds * 1000);
  };

  const getFTUEstate = async () => {
    try {
        // await localStorage.clear();
        const value =  localStorage.getItem('ftue-shown');
        // console.log(value, "here==================")
        if (value !== null) {
            // value previously stored
            return value
        }
    } catch (e) {
        // console.log(e, "error==================")
        // error reading value
    }
  };

  useEffect(() => {

      const checkFTUEState = async () => {

          const value = await getFTUEstate();
          // console.log(value, "FTTUE already shown")
          if (!value) {
              // User is logged in and FTUE state has not been set
              setFtueState('Home');
          }
      };
      // console.log("called ===========================")
      // Call the async function
      checkFTUEState();

  }, [user])




  const fetchNewSnacks = async (start = 1, limit = 3, SplitShuffle = 0) => {
    try {

      const res = await axios.get(`${API}/snackr/feeds`, { params: { start, limit } })
      let snacks = res?.data || []
      snacks = randomSort(snacks, SplitShuffle )

      // console.log(randomSort(snacks))


      _store_snack.dispatch({
        type: 'addMoreSnacks',
        payload: { snacks: snacks }
      })

      // if(isFirstTime()){
      // call FTUE
      // setIsFTUEVisible(true)
      triggerFTUE(setIsFTUEVisible, 3)
      // }
      setRegularUsage()




    } catch (ex) {

    }
  }

  //============================================
  //funcrtion to get coin
  //============================================
  let _store_wallet = useContext(walletStore)

  const getWallet = async () => {
    try {

      const res = await axios.get(`${API}/snackr/user/blahcoin`, { headers: headers() })
      // console.log(res)

      const BlahCoin = res?.data?.BlahCoin

      if (BlahCoin) {

        _store_wallet.dispatch({
          type: 'updateBlahCoin',
          payload: { BlahCoin }
        })

      }

    } catch (ex) {

    }

  }

  const previewStreakReward = async () => {
        
    const token =  getToken();
    if (!token) {
      return;
    }
    
    try {
        const response = await axios.get(
          `${API}/snackr/user/previewStreakReward`,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          }
        );
        console.log('Preview Response:', response.data);
        const is_reward_available = response.data.claimAvailable;
        if (is_reward_available) {
            console.log('Streak Reward is available');
            _store_wallet.dispatch({ 
                type: 'SET_REWARD_STREAK', 
                payload: { 
                    reward_streak_amount : response.data.reward, 
                    reward_streak_message : response.data.message 
                }
            });
            setModal("streak_reward");
        }
        
        
    } catch (error) {
        console.error('Error previewing streak reward:', error);
    }
};



  useEffect(() => {
    // getVideos(3);
    fetchNewSnacks(1, 10, 4)
    getWallet()
    previewStreakReward()
  }, []);


  const [isPublisherPage, setisPublisherPage] = useState(false);

  const openPublisherPage = (creatorId) => {
    navigate('/creator', { state: { id: creatorId } });
    // setPageState("publisher")
    // setisPublisherPage(id)
  }

  const closePublisherPage = () => {
    setisPublisherPage(false)
  }

  const isHome = matchPath("/", pathname);



  return (<>
    {pageState === "home" && (
  <div >
    {/* {console.log(isFirstTime())} */}
    <SwipeUpAnimation  show={isFTUEVisible  && (videos.length > 0) && isHome && ftueState === "swipe" }/>

    <div className="slider-container ">
      {/* {console.log(videos)} */}
      {videos.length > 0 ? (
        <>
          {videos.map((video, id) => (
            <VideoCard
              key={id}
              index={id}

              snack_id={video?.id}
              snackType={video?.type}

              title={video?.title}
              description={video?.description}

              game_url={video?.game_url || ""}
              cover_image={video?.cover_image?.url ? `${BASE_URL}${video?.cover_image?.url}` : ""}

              publisherDetails={video?.publisher || {}}

              videoURL={video?.video?.url ? `${BASE_URL}${video?.video?.url}` : ""}
              getnewSnacks={fetchNewSnacks}
              lastVideoIndex={videos.length - 1}

              like_count={video?.liked_users?.length || 0}
              openPublisherPage={() => openPublisherPage(video?.publisher?.id)}

            // wrapperClass={isFTUEVisible ? "animate-bounce" : ""}

            />
          ))}
        </>
      ) : (
        <>
          {/* <h1>Nothing to show here</h1> */}
          <div className="bg-slate-900 _bg-violet-500 w-full h-full flex flex-col justify-center items-center">
            <div className="w-fit mx-auto">
              <BeatLoader color='white' className='mx-auto' />
            </div>
          </div>
        </>
      )}
    </div>
    <AnimatePresence>

      {
        (game_url && snack_type == "game" && snack_id) &&
        <GamePlay
          key="game_snack"
          url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
      {
        (snack_type == "comic" && snack_id) &&
        <ComicPlay
          key="comic_snack"
          // url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
      {
        (snack_type == "reel" && snack_id) &&
        <ReelPlay
          key="reel_snack"

          // url={game_url}
          snack_id={snack_id}
          snack_name={snack_name}
          snack_type={snack_type}
        // updateWallatScore={updateWallatScore}
        // updateHighScore={updateHighScore}

        />
      }
    </AnimatePresence>
    {/* <PopOverlay isVisible={isPublisherPage} onClose={() => closePublisherPage()} > */}
      {/* {console.log(isPublisherPage)} */}
      {/* <PublisherDetails publisher={isPublisherPage} />
    </PopOverlay> */}
  </div>
  )}
  {
    pageState === "publisher" && (
      <PublisherDetails publisher={isPublisherPage} />

    )
  }
  </>
  );
}

export default Home;

