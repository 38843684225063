import React, { useEffect, useState } from "react";
import { motion } from "framer-motion"; // For animations, replace `react-native-reanimated`
import { SecondaryButtonBoxyBlack, SecondaryButtonBoxyBlue } from "Components/ButtonSecondary";
import { SnackButton } from "Components/VideoCard/SnackButton/SnackButton";
import { useLocation } from "react-router-dom"; // React Router hook for pathname
import { useUI } from "UIContext/UIContext";
import { useNavigate } from "react-router-dom";
import _home_icon from "./assets/active/home.png";
import _discover_icon from "./assets/active/explore.png";
import _publisher_icon from "./assets/active/plus.png";
import _store_icon from "./assets/active/local_mall.png";
import _profile_icon from "./assets/active/profile_2.png";
import _hand_down from "./assets/hand_down.png";

export default function FTUE() {
  const { ftueState, setFtueState } = useUI(); // Replace with your context hook for managing FTUE states
  const [showFtue, setShowFtue] = useState(false);
  const location = useLocation(); // Get current path
  const navigate = useNavigate()
  useEffect(() => {
    if (location.pathname === "/") {
      setShowFtue(true);
    } else {
      setShowFtue(false);
    }
  }, [location.pathname]);

  const saveFtueState = async () => {
    try {
      await localStorage.setItem("ftue-shown", "true");
    } catch (error) {
      console.error("Error saving FTUE state", error);
    }
  };


  const dismissFTUE = () => {
    setFtueState("");
    saveFtueState();
    setShowFtue(false)
    navigate("/")
  };

  const animatedStyle = {
    y: [-10, 0], // Vertical bounce animation
    transition: {
      duration: 1,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };

//   useEffect(() => {
//     if (ftueState === "Final") {
//       dismissFTUE()
//     }
//   }, [ftueState]);

  return null
  if (!showFtue) return null;

  return (
    < motion.div
            initial={{ opacity: 1, translateY: "100vh" }}
            animate={{ opacity: 1, translateY: "0vh" }}
            exit={{ opacity: 1, translateY: "100vh" }}
            // transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen hide-scrollbar">
            
        <div className="_container _mx-auto md:max-w-[400px]  ">
      {ftueState === "Home" && (
        <FTUEOverlay
          icon={_home_icon}
          message="This is your home screen. You can swipe up and down to browse through all the available snacks."
          callback={() => setFtueState("Publisher")}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          bottom={60}
          size="w-[30px] h-[30px]"
          left={25}
        />
      )}
      {ftueState === "Discover" && (
        <FTUEOverlay
          icon={_discover_icon}
          message="If you know the name of the content, you can search for your favorite snack in Discover. More ways to Discover are coming up soon!"
          callback={() => setFtueState("Coins")}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          size="w-[35px] h-[35px]"
          bottom={62}
          left={95}        />
      )}
      {ftueState === "Publisher" && (
        <FTUEOverlay
          icon={_publisher_icon}
          message="You can be a creator/developer and publish your game, comic strip/episode, and short animation here."
          callback={() => setFtueState("Discover")}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          size="w-[60px] h-[60px]"
          bottom={90}
          left={170}  
        />
      )}
      {ftueState === "Coins" && (
        <FTUEOverlay
          icon={_store_icon}
          message="Each time you snack on SnackR, you earn ‘S’ coins. The shop to redeem your coins is coming soon!"
          callback={() => setFtueState("Profile")}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          size="w-[35px] h-[40px]"
          bottom={63}
          left={245}        />
      )}
      {ftueState === "Profile" && (
        <FTUEOverlay
          icon={_profile_icon}
          message="Check out your profile page here. You can edit, change your details, DP, and more here."
          callback={() => setFtueState("Play_now")}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          size="w-[30px] h-[30px]"
          bottom={63}
          left={320}        />
      )}
      {ftueState === "Play_now" && (
        <FTUEOverlay
          icon={null}
          message="Now, you are ready to Snack! Click here to Play, Read or Watch your snack, your way!"
          callback={() => dismissFTUE()}
          dismiss={dismissFTUE}
          animatedStyle={animatedStyle}
          bottom={120}
          left={30}
          isButton
        />
      )}
      
    </div>
    </motion.div>
  );
}

const FTUEOverlay = ({
  icon,
  message,
  callback,
  dismiss,
  animatedStyle,
  size,
  bottom,
  left,
  isButton = false,
}) => {
  return (
    
    <div className="absolute inset-0 bg-black/80 z-50 flex-col justify-center h-full">
      <div className="absolute top-[20px] w-[250px] m-4 p-4 bg-white rounded-lg shadow-lg">
        <p className="text-gray-800">{message}</p>
        <div className="mt-4 flex justify-between">
          <SecondaryButtonBoxyBlue
            onClick={dismiss}
            label="Dismiss"
            _className="w-[150px] text-center border py-[10px]"
          />
          <SecondaryButtonBoxyBlack
            onClick={callback}
            label="OK"
            _className="w-[150px] border py-[10px]"
          />
        </div>
      </div>
      <div className={`absolute z-20 `} style={{bottom: bottom, left: left}}>
      {icon && (
        <motion.div
        //   style={{pos}}
          animate={animatedStyle}
        >
          <img src={_hand_down} alt="hand gesture" className="w-[40px] h-[60px]" />
        </motion.div>
      )}
      {icon && (
        <div
          className={`absolute rounded-b-lg  w-[60px] h-[60px] flex items-center justify-center ${icon === _publisher_icon ? "" : "bg-zinc-900"}`}
        >
          <img src={icon} alt="icon" className={`${size}`} />
        </div>
      )}
      </div>
      <div className={`w-full absolute z-20`} style={{bottom: bottom, left: left}}>
      {isButton && (
        <SnackButton
          onClick={() => {}}
          disabled={true}
          text="PLAY NOW"
          _className="w-[500px] py-[20px]"
        />
      )}
      </div>
    </div>
  );
};
