// import React, { Fragment, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { useAuthContext } from "Auth/AuthContext";

// import { API } from "constant";
// import { setToken } from "helpers";
// import { ButtonPrimary } from "Components/ButtonPrimary";
// import axios from "axios";



// const ForgetContainer = () => {

//     return (<div>
//         <div className="h-screen _bg-zinc-800 bg-[#a07af6]">
//             <ForgetPassword />
//         </div>
//     </div>)
// }


// export const ForgetPassword = ({ callback = () => { }, loginRedirect }) => {

//     const navigate = useNavigate();

//     const { setUser } = useAuthContext();

//     const [isLoading, setIsLoading] = useState(false);
//     const [success, setSuccess] = useState(false);

//     const [error, setError] = useState("");

//     const [inputVals, setInputVals] = useState({
//         email: "",
       

//     })

//     const handleInputValues = (e) => {
//         setError("")
//         setInputVals(prev => ({
//             ...prev,
//             [e.target.name]: e.target.value
//         }))
//     }



//     const onResetMail = async (values) => {
      
//         setIsLoading(true);
//         try {
//             const value = {
               
//                 email: values.email,
              
//             };
//             // const response = await fetch(`http://localhost:1337/api/auth/local/forgot-password`, {
//             //     method: "POST",
//             //     headers: {
//             //         "Content-Type": "application/json",
//             //     },
//             //     body: value,
//             // });
//             const data = await axios.post(`${API}/auth/forgot-password`, {
//                 email : values.email
//             })

//             // const data = await response.json();

//             // console.log(data)
//             if (data?.error) {
//                 throw data?.error;
//             } else {
//                  console.log(data)
//                 setSuccess(true)

//             }
//         } catch (ex) {
//             console.log( ex.response?.data?.error?.message);
//             setError(ex.response?.data?.error?.message ?? ex?.message ??  "Something went wrong!");
//         } finally {
//             setIsLoading(false);
//         }
//     };


//     return (


//         <div className="flex flex-col h-full w-full  justify-between items-center">


//             <div className=" w-full p-[20px] text-slate-900">
                
//                 <div className="font-bold text-[24px]">
//                     Forget Password ?
//                 </div>
//                 <div className="text-slate-500 text-[14px]">
//                    We can help reset your password.
//                 </div>

//                 {success ? 
//                     <div className="_text-center p-[10px] bg-white shadow text-[14px] rounded-xl mt-[20px] w-full">

//                         We've sent a recovery mail to {inputVals.email}. <br/> please open the reset link and enter  new password to recover your account

//                     </div> 
//                     : 

//                     <div className="w-full  py-[20px] rounded-xl text-slate-900 text-[14px]">

//                         <div className="mt-[10px]">
//                             <input
//                                 type="text" name="email"
//                                 className='bg-white w-full rounded-[20px]  p-[10px] '
//                                 placeholder='Email'
//                                 // disabled 
//                                 value={inputVals?.email}
//                                 onChange={handleInputValues}
//                             />
//                         </div>
                    
//                         <div className="text-center my-[20px] text-red-500 text-[12px]">
//                             {error}
//                         </div>
//                         <div className="_mt-[20px]">

//                             <ButtonPrimary
//                                 disabled={isLoading}
//                                 onClick={() => onResetMail(inputVals)} className=" w-full py-[10px] text-center text-white font-bold text-[14px]">
//                                 {isLoading ? "..." : "Reset Passsword"}
//                             </ButtonPrimary>
//                         </div>
//                     </div>
//                 }

//                 <div className="my-[30px] text-center text-[14px] text-slate-900">
//                     <div className="font-bold">
//                          <span  onClick={() => loginRedirect()} className="text-violet-500 cursor-pointer"> Go back</span>
//                     </div>
//                 </div>
//             </div>

//         </div>
//         // </div>

//     );
// }

// export default ForgetContainer;

import React, { useState, useContext } from "react";
// import AuthContext from "../Auth/AuthContext";
import { API } from "constant";
import { SecondaryButtonBoxyWhite } from "Components/ButtonSecondary";
import Bg from '../../Assets/bg.png';

const ForgetPassword = () => {
//   const { loadUserData } = useContext(AuthContext);

  const [inputVals, setInputVals] = useState({
    email: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  
  const handleInputChange = (e) => {
    const { value } = e.target;
    setError("");
    setInputVals((prev) => ({
      ...prev,
      email: value,
    }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const value = {
        email: inputVals.email,
      };
      const response = await fetch(`${API}/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(value),
      });

      const data = await response.json();
      if (data?.error) {
        throw data?.error;
      } else {
        setSuccess(true);
      }
    } catch (ex) {
      setError(
        ex?.details?.errors?.[0]?.message ??
          ex?.message ??
          ex.response?.data?.error?.message ??
          "Something went wrong!"
      );
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-[#0E0C1C] min-h-screen ">
    <div className="h-full flex flex-col">
      <div
          className="w-full h-screen bg-cover bg-center p-5"
          style={{ backgroundImage: `url(${Bg})` }}
        >
      <div className="bg-black/50 p-8 rounded-lg w-full max-w-md mt-[50px]">
        {/* Header */}
        <div className="text-center">
          <h1 className="text-lg font-bold">Forgot password?</h1>
          <p className="text-sm text-white mt-2">
            Try recovering your password.
          </p>
        </div>

        {/* Input */}
        <div className="mt-6">
          <input
            type="email"
            value={inputVals.email}
            onChange={handleInputChange}
            placeholder="Your user email"
            disabled={success}
            className="w-full p-[10px] px-5 rounded-xl border border-gray-300 text-gray-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>

        {/* Buttons */}
        <div className="mt-6">
          {success ? (
            <div className="text-center">
              <p className="text-sm">
                Recovery mail sent to{" "}
                <span className="font-bold text-white">{inputVals.email}</span>.
              </p>
              <p className="text-sm text-gray-300 mt-2">
                Please open the reset link and enter a new password to recover
                your account.
              </p>
            </div>
          ) : (
            // <button
            //   onClick={handleSubmit}
            //   disabled={isLoading}
            //   className={`w-full p-3 rounded-md text-white font-bold ${
            //     isLoading
            //       ? "bg-gray-500 cursor-not-allowed"
            //       : "bg-blue-600 hover:bg-blue-700"
            //   }`}
            // >
            //   {isLoading ? "Loading..." : "Recover Password"}
            // </button>
            <SecondaryButtonBoxyWhite 
                  label="Recover Password" 
                  isLoading={isLoading} 
                  _className="py-[14px] w-full"
                  onClick={handleSubmit}   />
          )}
        </div>

        {/* Back Button */}
        <div className="mt-8 text-center">
          <button
            onClick={() => window.history.back()}
            className="text-white hover:underline"
          >
            Back
          </button>
        </div>
      </div>
      </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
