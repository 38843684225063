import React from "react";

const PrimaryButtonBoxyYellow = ({
  disabled,
  isLoading,
  label,
  className = "py-4", // Tailwind-compatible
  onClick,
}) => {

  const _btn_background_yellow = require("./UI_Images/buttons/yellow.png")

  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`relative rounded-lg overflow-hidden ${className} ${
        disabled || isLoading ? "opacity-50 cursor-not-allowed" : ""
      }`}
      style={{
        backgroundImage: `url(${_btn_background_yellow})`, // Use the background image
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <span className="block text-center font-bold text-black text-xl">
        {isLoading ? "..." : label}
      </span>
    </button>
  );
};

export default PrimaryButtonBoxyYellow;
