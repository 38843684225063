import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from 'helpers';
import { API } from 'constant';
import { Store as wallatStore } from 'StoreContext/wallatStore';
import { useAuthContext } from 'Auth/AuthContext';
import coinIcon from './assets/coin_bag.png';
import boxIcon from './assets/box.png';
import { motion } from 'framer-motion';
import { useUI } from 'UIContext/UIContext';

const StreakReward = () => {
  let _store_wallet = useContext(wallatStore)
  const { user, isLoggedIn, loadUserData } = useAuthContext();
  const {setModal} = useUI()
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

//   useEffect(() => {
//     analyticsLogEvent('streak_reward_card_opened');
//   }, []);

  const claimStreakReward = async (dailyReward) => {
    const token = await getToken();
    if (!token) {
      throw new Error('No token found');
    }
    try {
      const response = await axios.post(
        `${API}/snackr/user/claimStreakReward`,
        { dailyReward },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log('Claim Response:', response.data);
      const BlahCoin = response?.data?.blah_coin
      setSuccess(true);
      setSuccessMessage(response.data.message);
      if(BlahCoin){

        _store_wallet.dispatch({
            type: 'updateBlahCoin',
            payload: { BlahCoin }
        })

    }
    //   initEconomyData();
    } catch (error) {
      console.error('Error claiming streak reward:', error);
    }
  };

  return (
    < motion.div
            initial={{ opacity: 1, translateY: "100vh" }}
            animate={{ opacity: 1, translateY: "0vh" }}
            exit={{ opacity: 1, translateY: "100vh" }}
            // transition={{ type: "Tween", stiffness: 50 }}
            className="fixed md:max-w-[400px] md:left-auto left-0 z-[7000]  top-0 w-full h-screen hide-scrollbar">
            
        <div className="_container _mx-auto md:max-w-[400px]  ">
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center">
      <div className="bg-white m-[20px] p-4 rounded-lg w-full shadow-lg" style={{margin: "20px"}}>
        {/* <button
          onClick={() => props.setModal('')}
          className="absolute top-2 right-2 text-black"
        >
          <img src={closeIcon} alt="Close" className="w-6 h-6" />
        </button> */}
        <div className="text-center">
          <h1 className="text-lg text-black font-bold">Daily Login Bonus</h1>
          <p className="text-sm mt-2 text-black">Let’s turn grit into coins</p>
        </div>
        {success ? (
          <div className="mt-8 text-center">
            <div className="flex justify-center">
              <img src={coinIcon} alt="Coin" className="w-[100px] h-[100px]" />
            </div>
            <p className="text-xl text-black font-bold mt-4">+{_store_wallet?.state?.reward_streak_amount} coins</p>
            <p className="text-sm text-black mt-2">{successMessage}</p>
            <button
              onClick={() => setModal('')}
              className="mt-4 text-white border-2 border-white px-6 py-2 rounded-full"
              style={{backgroundColor: "#4CAF50"}}
            >
              OK
            </button>
          </div>
        ) : (
          <div className="mt-8 text-center">
            <div className="flex justify-center">
              <img src={boxIcon} alt="Box" className="w-[100px] h-[80px]" />
            </div>
            <p className="text-sm text-black mt-4">{_store_wallet?.state?.reward_streak_message}</p>
            <button
              onClick={() => claimStreakReward(_store_wallet?.state?.reward_streak_amount)}
              className="mt-4 bg-green-600 text-white px-4 py-2 border-2 border-white rounded-full"
              style={{backgroundColor: "#4CAF50"}}
            >
              COLLECT
            </button>
          </div>
        )}
      </div>
    </div>
    </div>
    </motion.div>
  );
};

export default StreakReward;
