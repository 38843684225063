
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, matchPath, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import Home_active from "../Buttons/UI_Images/bottomNav/active/home.png";
import Home_inactive from "../Buttons/UI_Images/bottomNav/inactive/home.png";
import Discover_active from "../Buttons/UI_Images/bottomNav/active/explore.png";
import Discover_inactive from "../Buttons/UI_Images/bottomNav/inactive/explore.png";
import Store_active from "../Buttons/UI_Images/bottomNav/active/local_mall.png";
import Store_inactive from "../Buttons/UI_Images/bottomNav/inactive/local_mall.png";
import Profile_active from "../Buttons/UI_Images/bottomNav/active/profile_2.png";
import Profile_inactive from "../Buttons/UI_Images/bottomNav/inactive/profile_2.png";
import Publisher from "../Buttons/UI_Images/bottomNav/plus.png"

const navItems = [
  { name: "Home", path: "/", activeIcon: Home_active, inactiveIcon: Home_inactive, width: 28, height: 28 },
  { name: "Discover", path: "/discover", activeIcon: Discover_active, inactiveIcon: Discover_inactive, width: 37, height: 38 },
  { name: "Publisher", path: "/submitsnack", activeIcon: Publisher, inactiveIcon: Publisher, width: 70, height: 60 },
  { name: "Store", path: "/store", activeIcon: Store_active, inactiveIcon: Store_inactive, width: 35, height: 40 },
  { name: "Profile", path: "/profile", activeIcon: Profile_active, inactiveIcon: Profile_inactive, width: 30, height: 30 },
];

const BottomNav = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tabWidth = 100 / navItems.length;

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const currentIndex = navItems.findIndex((item) =>
      pathname.startsWith(item.path)
    );
    setActiveIndex(currentIndex !== -1 ? currentIndex : 0);
  }, [pathname]);

  return (<>
    {/* <AnimatedBackgroundOverlay tabWidth={tabWidth} activeIndex={activeIndex} /> */}

    <nav className="relative md:max-w-[400px] md:left-auto mx-auto bottom-nav pt-[10px] px-[20px] text-[40px] z-[1000] shadow brightness-120 h-[60px]	">
      {navItems.map((item) => {
        const isActive = matchPath(item.path, pathname);

        return (

          <div
            key={item.name}
            onClick={() => navigate(item.path)}
            className="relative _bg-[#1A1A1A]/20 _dark:bg-[#1a1a1a] w-[60px] h-[40px] flex justify-center items-center cursor-pointer"
            
          >
            {isActive ? (
              <div className="flex justify-center align-items">
                <motion.div
                  style={styles.overlay}
                  layoutId="nav_underline"
                  // className="absolute bottom-0 h-[5px] bg-blue-500 rounded-full"
                  initial={{ translateX: "0%" }}
                  animate={{
                    translateX: `${activeIndex * tabWidth}%`,
                  }}
                  transition={{
                    duration: 0.3,
                    ease: "easeOut",
                  }}
                >
                  <div className="pt-[9vh] flex-col justify-center align-items">
                    <div className="w-[25px] h-[1vh] bg-[#6F6DFF]  rounded-[5vw] overflow-hidden">
                    </div>
                  </div>
                </motion.div>
                <img
                  src={item.activeIcon}
                  style={{width: item.width, height: item.height}}
                  // className="h-[20px] w-[20px] relative z-[20] mx-auto"
                  className={`${item.name === "Publisher" ? "absolute -top-[5.5vh] left-[-0.2vw]" : "relative z-[20]"}`}
                  alt={`${item.name} Active`}
                />
              </div>
            ) : (
              <img
                src={item.inactiveIcon}
                style={{width: item.width, height: item.height}}
                className={`${item.name === "Publisher" ? "absolute -top-[5.5vh] left-[-0.2vw]" : "relative z-[0]"}`}
                // className="h-[20px] w-[20px] relative z-[20] mx-auto"
                alt={`${item.name} Inactive`}
              />
            )}

          </div>
        );
      })}
    </nav>
    </>
  );
};

function AnimatedBackgroundOverlay({tabWidth, activeIndex}) {
  <motion.div
    style={styles.overlay}
    layoutId="nav_underline"
    // className="absolute bottom-0 h-[5px] bg-blue-500 rounded-full"
    initial={{ translateX: "0%" }}
    animate={{
      translateX: `${activeIndex * tabWidth}%`,
    }}
    transition={{
      duration: 0.3,
      ease: "easeOut",
    }}
    // className="absolute left-0 top-[-10px] w-full h-[47px] bg-violet-400 shadow rounded-b-xl z-[100]"
  >
    <div className="pt-[4vh]">
      <div className="w-[1vw] h-[1vh] bg-[#6F6DFF]  rounded-[5vw] overflow-hidden">
      </div>
    </div>
  </motion.div>
}

const styles = {
  overlay: {
    position: 'absolute',
    bottom: 0,
    height: 60,
    zIndex: 1, // Ensure the overlay is behind the icons
    justifyContent: 'center',
    alignItems: 'center',
},
}

export default BottomNav;
