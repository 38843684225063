import { useNavigate } from "react-router-dom"

function SnackButtonHook() {

    const location = useNavigate()

    const onSnackClick = (item) => {

        const {id: snack_id, type : snackType, title: title,  game_url: game_url} = item
        
        if (snackType == "game" && game_url) {
            location(`/?game_url=${game_url}&&snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
        } else if (snackType == "comic") {
            location(`/?snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
        } else if (snackType == "reel") {
            location(`/?snack_type=${snackType}&&snack_id=${snack_id}&&snack_name=${title}`)
        }
    }


    return {onSnackClick};
}

export default SnackButtonHook;