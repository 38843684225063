import React, { useEffect, useState, useCallback } from 'react';
import {DiscoverTab} from '../../Discover/index';
import Games from './Games';
import Comics from './Comics';
import Shots from './Shots';
import axios from 'axios';
import { getToken } from 'helpers';
import { API } from 'constant';
import { motion } from 'framer-motion';
const SnackTabs = ({ handlePageState = () => {} }) => {
  const [activeTab, setActiveTab] = useState('game');
  const [submittedSnacks, setSubmittedSnacks] = useState([]);

  const fetchData = async () => {
    const token =  getToken();
    try {
      const response = await axios.get(`${API}/snackr/user/getSubmittedSnacks`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      setSubmittedSnacks(response?.data?.submitted_snacks || []);
    } catch (ex) {
      console.error('Error fetching snacks:', ex);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const publishedGames = submittedSnacks
    .filter((item) => item?.snack_type === 'game')
    .sort((a, b) => b.id - a.id);
  const publishedComics = submittedSnacks
    .filter((item) => item?.snack_type === 'comic')
    .sort((a, b) => b.id - a.id);
  const publishedShots = submittedSnacks
    .filter((item) => item?.snack_type === 'reel')
    .sort((a, b) => b.id - a.id);

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full overflow-y-scroll bg-white' >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full overflow-y-auto hide-scrollbar'>
    <div className="p-4 z-[600] bg-white h-full">
      <div className="">
        {/* DiscoverTab Component */}
        <DiscoverTab activeTab={activeTab} setActiveTab={setActiveTab} />

        {/* Content based on activeTab */}
        <div className="px-4 py-2 bg-gray-200 rounded-md mt-2">
          {activeTab === 'game' && (
            <Games handlePageState={handlePageState} data={publishedGames || []} />
          )}
          {activeTab === 'comic' && (
            <Comics handlePageState={handlePageState} data={publishedComics || []} />
          )}
          {activeTab === 'shot' && (
            <Shots handlePageState={handlePageState} data={publishedShots || []} />
          )}
        </div>
      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default SnackTabs;
