import React from "react";

const defaultImage = require("../../Assets/cover.jpg")

export default function CoverImage(props) {
  const { coverImage, children } = props;

  return (
    <div className="w-full h-[20vh] bg-red-100 relative">
      <div
        className="w-full h-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${coverImage || defaultImage})`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
