import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
// Custom Components
import { SecondaryButtonBoxyBlue } from "Components/ButtonSecondary";
import { PublishedSnacksStatsCard } from "Pages/SubmitSnack/PublishedSnacksStatsCard";

// Assets
import bg_snack_card from "../../../../Assets/leaderboard_bg.png";

export default function Comics({ handlePageState = () => {}, data = [] }) {
  const navigate = useNavigate();

  // Navigation Handlers
  const goToAddGame = () => {
    navigate("/add_comic");
  };

  const goToDetails = (item) => {
    navigate("/submitted_snack_details", { state: { ...item } });
  };

  const goToHelp = () => {
    const item = {
      title: "Request help",
      subtext: "What is your issue?",
      placeholder: "Enter your issue here",
    };
    navigate("/settings_feedback", { state: { ...item } });
  };

  // Status Count
  const published_count = data.filter((item) => item?.status === "published").length;
  const in_review_count = data.filter((item) => item?.status === "Under-review").length;

  return (
    
      <div className="pb-24">
        <div className="mt-8 w-full mb-[10px]">
          <p className="text-black text-md text-center">The list of comics you have published so far.</p>
        </div>

        {/* Published Snacks Stats Card */}
        <PublishedSnacksStatsCard
          published_count={published_count}
          in_review_count={in_review_count}
          actionFunction={goToAddGame}
          actionLabel="Add Comic"
        />

        {/* Snacks List */}
        <div
          className="rounded-xl mt-4 px-2 overflow-hidden bg-cover"
          style={{ backgroundImage: `url(${bg_snack_card})` }}
        >
          <div className="h-[300px] overflow-y-auto">
            <div className="pb-24">
              {data.length > 0 ? (
                data.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => goToDetails(item)}
                    className="mt-4 w-full bg-violet-100 py-2 px-5 rounded-lg flex items-center justify-between cursor-pointer hover:bg-violet-200"
                  >
                    {/* Snack Details */}
                    <div className="w-1/2">
                      <p className="text-black text-lg font-semibold">{item?.snack_name || ""}</p>
                      <p className="text-black text-sm truncate">{item?.snack_description || ""}</p>
                    </div>

                    {/* Status */}
                    <div
                      className={`w-1/3 py-1 rounded-full text-center font-semibold 
                        ${
                          item?.status === "Published"
                            ? "bg-green-100 text-green-500"
                            : item?.status === "Under-review"
                            ? "bg-blue-100 text-blue-500"
                            : "bg-red-100 text-red-500"
                        }`}
                    >
                      {item?.status || ""}
                    </div>
                  </div>
                ))
              ) : (
                <div className="w-full h-[100px] flex items-center justify-center">
                  <p className="text-black text-xl">No comics submitted yet!</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Help Section */}
        <div className="text-center py-[10px]">
          <button
            onClick={goToHelp}
            className="text-blue-500 hover:text-blue-700 hover:underline"
            style={{
                color: '#3b82f6', 
                textDecorationLine: 'underline', 
                textDecorationStyle: 'solid', 
                cursor: 'pointer', 
                transition: 'color 150ms ease-in-out'
            }}
          >
            Need Help? Click here to contact us
          </button>
        </div>
      </div>

  );
}
