import React from 'react';
import { motion } from 'framer-motion';
const Prompt = ({ checkAlreadyRegistered }) => {
    const _bannar = require("./assets/bannar.png"); 
    const desktopIcon = require("./assets/desktop-icon.png")
    const mobileIcon = require("./assets/mobile-icon.png")

    // Button component for Desktop and Mobile
    const DeviceButton = ({ title }) => (
        <button
            onClick={checkAlreadyRegistered}
            className="relative p-5 rounded-xl flex flex-col items-center justify-center w-[150px] h-[70%] m-[10px] mt-[20px] shadow-lg"
            style={{backgroundImage: "linear-gradient(to right, #FFCD50, #FFBE3C)" }}

        >
            <img
                src={title === "Desktop" ? desktopIcon : mobileIcon}
                alt={`${title} Icon`}
                className="w-12 h-12 mb-2"
            />
            <span className="text-black font-extrabold text-lg">{title}</span>
        </button>
    );

    return (
        <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full ' 
        style={{backgroundImage: "linear-gradient(to bottom, #7C73FF, #6D67F0, #CEBFFF)" }}
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}

            // initial={{ opacity: 1, translateY: "100vh" }}
            // animate={{ opacity: 1, translateY: "0vh" }}
            // exit={{ opacity: 1, translateY: "100vh" }}

            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full '>
        <div className="relative flex flex-col items-center  min-h-screen h-full mt-[20px]">
            {/* Central Ellipse */}
            <div
                className="absolute top-[16.67%] left-[50%] -translate-x-1/2 w-[400px] h-[400px] rounded-full border-8 border-[#6E63FF] opacity-50"
                style={{ zIndex: 1, borderColor: "#6E63FF"}}
            ></div>

                <div className="mt-10 text-center">
                <h2
            className=" text-center text-white font-bold mx-5"
            style={{ textShadow: "0px 4px 5px rgba(0, 0, 0, 0.5)" }}
            >
            <span style={{ display: "block", fontSize: "1.25rem" }}>
                YOU ARE ONE STEP AWAY FROM
            </span>
            <span
                style={{
                display: "block",
                marginTop: "0.5rem",
                fontSize: "1.25rem",
                }}
            >
                OPENING YOUR SNACK SHOP
            </span>
            </h2>
                </div>
            

            {/* Banner */}
            <div className="w-full flex justify-center mb-[10px] mt-[10px]">
                    <img
                        src={_bannar}
                        alt="Banner"
                        className="w-full max-w-[800px] h-auto"
                    />
                </div>

            {/* Publish Snack Text */}
            <div className="my-5" style={{margin: "10px"}}>
                    <img
                        src={require("./assets/publish_snack-text.png")}
                        alt="Publish Snack Text"
                        className="w-[200px] h-auto max-w-[300px]"
                    />
                </div>

                {/* Choose Device Text */}
                <p className="text-white text-xl font-bold my-4">
                    Choose your device
                </p>

                {/* Device Buttons */}
                <div className="flex flex-row justify-center w-[80%]">
                    <DeviceButton title="Desktop" />
                    <DeviceButton title="Mobile" />
                </div>
        </div>
        </motion.div>
        </div>
    );
};

export default Prompt;
