import React, { useContext, useEffect } from 'react';
import { useUI } from 'UIContext/UIContext';
import { Store as wallatStore } from 'StoreContext/wallatStore';
import _icon_coin from 'Assets/coin.png';

export default function SignupBonus() {
  const { setModal } = useUI();
//   const { state } = useEconomy();
let _store_wallet = useContext(wallatStore)

  const handleClose = () => {
    setModal('');
  };

  return (
    <div className="w-full h-full bg-black/80 absolute left-0 top-0 flex flex-col items-center justify-center">
      <div className="bg-[#916eff] py-4 rounded-lg w-4/5 mx-auto shadow-lg relative">
        {/* Close Button */}
        {/* <button
          onClick={handleClose}
          className="absolute top-[-1.5rem] right-[-1.5rem] bg-white rounded-full p-2 shadow-lg"
        >
          <img src={_icon_close} alt="Close" className="w-6 h-6" />
        </button> */}

        {/* Header */}
        <div className="text-center mb-4">
          <h1 className="text-white text-2xl font-bold mb-1">SIGNUP BONUS</h1>
          <p className="text-white text-sm">You earned a reward!</p>
        </div>

        {/* Content */}
        <div className="bg-white w-full p-4 text-center">
          <h2 className="text-xl font-bold mb-2">🎉 Reward</h2>
          <p className="text-lg font-bold mb-4 flex items-center justify-center">
            <img src={_icon_coin} alt="Coin" className="w-6 h-6 mr-2" /> +250
          </p>
        </div>

        {/* Collect Button */}
        <div className="py-4">
          <button
            onClick={handleClose}
            className="bg-[#4CAF50] text-white font-bold py-2 px-6 rounded-full mx-auto shadow-lg hover:bg-green-600"
          >
            🎉 COLLECT
          </button>
        </div>
      </div>
    </div>
  );
}
