import React from "react";
import { BASE_URL } from "../../constant";
import { useNavigate } from "react-router-dom"; // Replace navigation for web

const RecentSnackCard = ({ item, lastPlayed }) => {
  const heartIcon = require("../Discover/assets/heart-icon.png")
  const playFillIcon = require("../Discover/assets/ph_play-fill.png")
  const playIcon = require("../Discover/assets/play-white.png")
  const navigate = useNavigate();


  const handleSnackButtonPress = (item) => {
    // console.log("Item: ", item);
    if (item.type == "game" && item.game_url) {
      navigate(`/?game_url=${item.game_url}&&snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "comic") {
      navigate(`/?snack_type=${item.type}&&snack_id=${item.id}&&snack_name=${item.title}`)
    } else if (item.type == "reel") {
      navigate(`/?snack_type=${item.type}&&item.id=${item.id}&&snack_name=${item.title}`)
    }
  };

  const imageUri = `${BASE_URL}${item?.played_snack?.cover_image?.url}`;

  return (
    <div className="flex items-center bg-white p-2 rounded-2xl shadow-lg mb-4 w-full h-[70px]">
      {/* Snack Image */}
      <img
        src={imageUri}
        alt="Snack"
        className="w-10 h-10 rounded-lg object-cover"
      />

      {/* Snack Details */}
      <div className="flex-1 ml-2">
        <h3
          className="text-md text-gray-700 truncate"
          title={item.played_snack.title}
        >
          {item.played_snack.title}
        </h3>

        <div className="flex mt-1">
          {/* Liked Users */}
          <div className="flex items-center mr-4 text-right">
          <span
            className="text-gray-500 font-semibold text-center w-[30px]"
            style={{ display: "inline-block" }} // Ensures fixed width for alignment
          >
            {item?.played_snack?.liked_users?.length}
          </span>
            <img
              src={heartIcon}
              alt="Likes"
              className="w-4 h-4 ml-1"
            />
          </div>

          {/* Play Count */}
          <div className="flex items-center text-right">
          <span
            className="text-gray-500 font-semibold text-center w-[30px]"
            style={{ display: "inline-block" }}
          >
            {100 + (item?.played_snack?.snacked_count || 0)}
          </span>
            <img
              src={playFillIcon}
              alt="Play Count"
              className="w-3 h-3 ml-1"
            />
          </div>
        </div>
      </div>

      {/* Play Again Button & Last Played Time */}
      <div className="flex flex-col items-center pr-4">
        {/* Play Again Button */}
        <button
          onClick={() => handleSnackButtonPress(item?.played_snack)}
          className="bg-gradient-to-b from-[#736FFF] to-[#B38DF9] text-white text-xs rounded-full px-3 py-1 mb-2 shadow-lg"
        >
          {item?.played_snack?.type === 'game' && 'Play Again'}
          {item?.played_snack?.type === 'comic' && 'Read Again'}
          {item?.played_snack?.type === 'reel' && 'Watch Again'}
          <img
            src={playIcon}
            alt="Play Icon"
            className="w-2 h-2 inline-block ml-2"
          />
        </button>

        {/* Last Played */}
        {/* <span className="text-xs text-gray-500">
          {timeAgo(item?.played_snack?.updatedAt)}
        </span> */}
      </div>
    </div>
  );
};

export default RecentSnackCard;

function timeAgo(inputDate) {
  const date = new Date(inputDate);
  const now = new Date();
  const diffInMs = now - date; // Difference in milliseconds

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day; // Approximation
  const year = 365 * day; // Approximation

  if (diffInMs < minute) {
    return "Just now";
  } else if (diffInMs < hour) {
    const minutesAgo = Math.floor(diffInMs / minute);
    return `${minutesAgo} minute${minutesAgo > 1 ? "s" : ""} ago`;
  } else if (diffInMs < day) {
    const hoursAgo = Math.floor(diffInMs / hour);
    return `${hoursAgo} hour${hoursAgo > 1 ? "s" : ""} ago`;
  } else if (diffInMs < week) {
    const daysAgo = Math.floor(diffInMs / day);
    return `${daysAgo} day${daysAgo > 1 ? "s" : ""} ago`;
  } else if (diffInMs < month) {
    const weeksAgo = Math.floor(diffInMs / week);
    return `${weeksAgo} week${weeksAgo > 1 ? "s" : ""} ago`;
  } else if (diffInMs < year) {
    const monthsAgo = Math.floor(diffInMs / month);
    return `${monthsAgo} month${monthsAgo > 1 ? "s" : ""} ago`;
  } else {
    const yearsAgo = Math.floor(diffInMs / year);
    return `${yearsAgo} year${yearsAgo > 1 ? "s" : ""} ago`;
  }
}
