import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../../../../constant";
import { getToken } from "../../../../helpers";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { removeToken } from "../../../../helpers";
import { PrimaryButton } from "Components/ButtonPrimary";
import { SecondaryButton } from "Components/ButtonSecondary";

function DeleteUser({handlePageState = () => {}, user}) {

  const goBack = () => {
    handlePageState("settings") // Go back to the previous page
  };

  const [inputVals, setInputVals] = useState({
    email: "",
    word: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    setInputVals({
      email: user?.email || "",
      word: "",
      password: "",
    });
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setError("");
    setInputVals((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogout = async () => {
    removeToken()
    navigate("/")
    window.location.reload();
  };

  const handleSubmit = async () => {
    if (inputVals.word !== "delete") {
      setError("Please type 'delete' to confirm.");
      return;
    }

    setIsLoading(true);
    try {
      const token = await getToken();
      const response = await axios.post(
        `${API}/snackr/user/deleteuser`,
        {
          password: inputVals.password,
          email: inputVals.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.error) {
        throw new Error(response.data.error.message);
      } else {
        handleLogout();
      }
    } catch (error) {
      setError(error.response?.data?.message || "Something went wrong!");
    }
    setIsLoading(false);
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
    style={{backgroundColor: "#0E0C1C"}}
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
    <div className="min-h-screen bg-[#0e0c1c] text-white">
    <div className="px-[10px] py-[5px] border-b border-[#b58ef7]">
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={require("../../../../Assets/left-arrow.png")}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">Delete Account</h1>
        </div>
      </div>
      <div className="px-[15px] py-[5px] ">
        <div className="bg-red-200 text-red-500 p-4 rounded-lg mt-6">
            <p>Permanently delete your account</p>
            <strong>{user?.email}</strong>
            <p>This action is irreversible.</p>
        </div>
      </div>
      <div className="px-[15px] py-[5px] mt-6">
        <label className="block text-sm mb-2">Password</label>
        <input
          type="password"
          name="password"
          value={inputVals.password}
          onChange={handleInputChange}
          placeholder="Enter your password"
          className="w-full p-[10px] pl-[20px] rounded-full bg-white text-gray-800"
        />
      </div>
      <div className="px-[15px] py-[5px] mt-6">
        <label className="block text-sm mb-2">Type "delete"</label>
        <input
          type="text"
          name="word"
          value={inputVals.word}
          onChange={handleInputChange}
          placeholder='Type "delete"'
          className="w-full p-[10px] pl-[20px] rounded-full bg-white text-gray-800"
        />
      </div>
      {error && <p className="text-red-500 text-center mt-4">{error}</p>}
      <div className="px-[15px] py-[5px] mt-[25px]">
        {/* {inputVals.word === "delete" ? (
        //   <button
        //     onClick={handleSubmit}
        //     disabled={isLoading}
        //     className={`w-full px-4 py-2 rounded-md text-white bg-red-500 hover:bg-red-600 ${
        //       isLoading && "opacity-50 cursor-not-allowed"
        //     }`}
        //   >
        //     {isLoading ? "Deleting..." : "Delete Account"}
        //   </button>
          <PrimaryButton disabled={false} label={"Delete Account"} onClick={handleSubmit} isLoading={true} 
          className="w-full p-[10px]"
      />
        ) : (
        //   <button
        //     disabled
        //     className="w-full px-4 py-2 rounded-md text-white bg-gray-500 cursor-not-allowed"
        //   >
        //     Delete Account
        //   </button>
        <PrimaryButton disabled={true} label={"Delete Account"} onClick={handleSubmit} isLoading={false} 
            className="w-full p-[10px]"
        /> */}
        {/* )} */}

        {inputVals?.word === "delete" ?
            <PrimaryButton disabled={inputVals?.word !== "delete"}  label={"Delete account"} onPress={handleSubmit} isLoading={isLoading} 
                className="w-full p-[10px]"
            />
            :
            <SecondaryButton label={"Delete account"} 
                className="w-full p-[10px]"
            />}
      </div>
    </div>
    </motion.div>
    </div>
  );
}

export default DeleteUser;
