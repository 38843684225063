import React, { useContext, useState } from "react";
import { motion } from "framer-motion";
import { PrimaryButton } from "Components/ButtonPrimary";

const Feedback = ({ handlePageState = () => {}, user, type = "Feedback", title = "Feedback", subtext = "We Value Your Feedback", placeholder = "Share your experience with us..." }) => {

  const [inputVals, setInputVals] = useState({ content: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");


  const goBack = () => {
    handlePageState("settings") // Go back to the previous page
  };

  const handleInputChange = (event) => {
    setError("");
    setInputVals((prev) => ({
      ...prev,
      content: event.target.value,
    }));
  };

  const clearInputs = () => {
    setInputVals({ content: "" });
  };

  const handleSubmit = async () => {
    if (inputVals?.content.trim() === "") {
      setError("Feedback cannot be empty");
      return;
    }

    setIsLoading(true);
    try {
      const value = {
        users: [user?.user?.id],
        type,
        content: inputVals?.content,
      };

      const response = await fetch("/api/feedbacks", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ data: value }),
      });

      const data = await response.json();
      if (response.ok) {
        alert("Thank you for your valuable feedback!");
        clearInputs();
        setSuccess(true);
      } else {
        throw new Error(data?.error?.message || "Something went wrong!");
      }
    } catch (ex) {
      setError(ex.message || "Something went wrong!");
    }
    setIsLoading(false);
  };

  return (
    <div className='fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full' 
    style={{backgroundColor: "#0E0C1C"}}
        
        >
        {/* {console.log(_store_wallet.state?.user)} */}
        <motion.div
            initial={{ opacity: 0, _translateY: "100vh" }}
            animate={{ opacity: 1, _translateY: "0vh" }}
            exit={{ opacity: 0, _translateY: "100vh" }}
            transition={{ type: "Tween", stiffness: 50 }}

            className='fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full'>
    <div className="min-h-screen bg-[#0e0c1c] text-white">
      <div className="px-[10px] py-[5px] border-b border-[#b58ef7]">
        <div className="p-4 flex items-center">
          <button
            onClick={goBack}
            className="p-2"
          >
            <img
              src={require("../../../../Assets/left-arrow.png")}
              alt="Back"
              style={{width: "12px", height: "16px"}}
            />
          </button>
          <h1 className="text-white text-lg font-bold ml-4">{title}</h1>
        </div>
      </div>

      <div className="px-[15px] py-[5px] mt-6">
        <p className="text-sm text-gray-400">{subtext}</p>
        <textarea
          name="content"
          value={inputVals.content}
          onChange={handleInputChange}
          placeholder={placeholder}
          className="w-full p-[10px] pl-[20px] rounded-md bg-white text-gray-800 h-40 mt-4"
          style={{resize: "none"}}
        ></textarea>
        {error && <p className="text-red-500 mt-2">{error}</p>}
      </div>

      <div className=" px-[15px] py-[5px] mt-[25px]">
        {/* <button
          onClick={handleSubmit}
          disabled={isLoading}
          className={`w-full py-3 rounded-md font-bold text-white ${
            isLoading ? "bg-gray-500 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
          }`}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button> */}
        <PrimaryButton label={"Submit"}  onPress={handleSubmit} isLoading={isLoading} 
            className="w-full p-[10px]"
        />

      </div>
    </div>
    </motion.div>
    </div>
  );
};

export default Feedback;
