import { faker } from '@faker-js/faker';

export const generateFakeDataSnackerList = (count) => {
    const data = [];
    for (let i = 0; i < count; i++) {
        data.push({
            id : i,
            avatar_url: `/extra/images/profile/Vegies/Asset ${Math.floor(Math.random() * 6) + 4}.png`,
            username: faker.internet.userName(),
            blahcoin_today_earning:  faker.number.int({ min: 1, max: 25 }).toString(),
        });
    }
    return data;
};

export const generateFakeDataAllTImeSnackerList = (count) => {
    const data = [];
    for (let i = 0; i < count; i++) {
        data.push({
            id : i,
            avatar_url: `/extra/images/profile/Vegies/Asset ${Math.floor(Math.random() * 6) + 4}.png`,
            username: faker.internet.userName(),
            blah_coin:  faker.number.int({ min: 100, max: 160 }).toString(),
        });
    }
    return data;
};

export const generateFakeDataCreatorList = (count) => {
    const data = [];
    for (let i = 0; i < count; i++) {
        data.push({
            id : i,
            avatar_url: `/extra/images/profile/Vegies/Asset ${Math.floor(Math.random() * 6) + 4}.png`,
            username: faker.internet.userName(),
            blahcoin_today_earning:  faker.number.int({ min: 1, max: 25 }).toString(),
        });
    }
    return data;
}

export const generateFakeDataAllTimeCreatorList = (count) => {
    const data = [];
    for (let i = 0; i < count; i++) {
        data.push({
            id : i,
            avatar_url: `/extra/images/profile/Vegies/Asset ${Math.floor(Math.random() * 6) + 4}.png`,
            username: faker.internet.userName(),
            blah_coin:  faker.number.int({ min: 100, max: 160 }).toString(),
        });
    }
    return data;
}