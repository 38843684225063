

import React from "react";
import { Content } from "./_terms_and_conditions";
import { motion } from "framer-motion";

export default function TermsAndConditions({ handlePageState = () => {} }) {
  return (
    <div
    className="fixed md:max-w-[400px] md:left-auto left-0 w-full top-0 z-[550] h-full bg-white"
  >
    <motion.div
      initial={{ opacity: 0, translateY: "100vh" }}
      animate={{ opacity: 1, translateY: "0vh" }}
      exit={{ opacity: 0, translateY: "100vh" }}
      transition={{ type: "Tween", stiffness: 50 }}
      className="fixed md:max-w-[400px] md:left-auto left-0 top-0 left-0 z-[200] h-full w-full"
    >
        {/* Header */}
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            height: "200px",
            background: "linear-gradient(to bottom right, #a78bfa, #818cf8)", // Tailwind 'from-purple-400 to-indigo-600'
            borderBottomLeftRadius: "16px",
            borderBottomRightRadius: "16px",
            zIndex: 10,
          }}
        >
          {/* Back Arrow */}
          <button
            style={{
              position: "absolute",
              top: "20px",
              left: "10px",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => handlePageState("prompt")}
          >
            <img
              src={require("../../Assets/left-arrow.png")}
              alt="Back"
              style={{
                width: "12px",
                height: "16px",
              }}
            />
          </button>

          {/* Question Mark Icon */}
          <button
            style={{
              position: "absolute",
              top: "20px",
              right: "30px",
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("./assets/question-mark.png")}
              alt="Help"
              style={{
                width: "18px",
                height: "18px",
              }}
            />
          </button>

          {/* Header Content */}
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              color: "white",
              paddingLeft: "30px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "0.875rem",
                  fontWeight: "600",
                }}
              >
                Hello Snacker
              </p>
              <img
                src={require("./assets/snacker.png")}
                alt="Snacker"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
                marginTop: "16px",
                lineHeight: "1.75rem",
              }}
            >
              Before you create your first snack, <br />
              please read and accept our Terms & <br />
              Conditions
            </p>
          </div>
        </div>

        {/* Scrollable Content */}
        <div
          style={{
            position: "absolute",
            marginTop: "220px", // Match the header height
            marginBottom: "120px", // Match the button height
            padding: "32px",
            paddingTop: 0,
            backgroundColor: "white",
            overflowY: "auto",
            height: "calc(100% - 320px)", // Adjust height to exclude header and buttons
          }}
        >
          <h1
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              color: "#1f2937", // Tailwind 'text-gray-800'
              marginBottom: "16px",
            }}
          >
            Terms & Conditions
          </h1>
          <Content />
        </div>

        {/* Bottom Buttons */}
        <div
          style={{
            position: "fixed",
            bottom: 60,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "space-between",
            padding: "32px",
            backgroundColor: "#f3f4f6", // Tailwind 'bg-gray-100'
            zIndex: 10,
          }}
        >
          <button
            style={{
              flex: "1",
              marginRight: "8px",
              padding: "12px",
              border: "2px solid #6366f1", // Tailwind 'border-indigo-500'
              color: "#6366f1", // Tailwind 'text-indigo-500'
              borderRadius: "16px",
              fontWeight: "bold",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => handlePageState("prompt")}
          >
            Decline
          </button>
          <button
            style={{
              flex: "1",
              marginLeft: "8px",
              padding: "12px",
              backgroundColor: "#6366f1", // Tailwind 'bg-indigo-500'
              border: "1px solid #c084fc", // Tailwind 'border-purple-400'
              color: "white",
              borderRadius: "16px",
              fontWeight: "bold",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
            onClick={() => handlePageState("registration-one")}
          >
            Accept
          </button>
        </div>
      </motion.div>
    </div>
  );
}
