export const Content = () => (<p className="text-black mb-[50px]">
    <p className="font-bold text-md text-black">Terms and Conditions for Content Submission</p>
    {
`
\n
1. Introduction
These terms and conditions ("Agreement") govern the submission of content by creators ("Creator" or "you") to Banzan Ventures Private Limited ("Publisher" or "we") on the SnackR platform ("Platform"). By submitting content, you agree to these terms and conditions in their entirety.

2. Content Submission
2.1 Eligibility: You must be at least 18 years old or have reached the age of majority in your jurisdiction to submit content to SnackR. By submitting content, you confirm that you meet this eligibility requirement.
2.2 Content: "Content" refers to any text, images, audio, video, graphics, code, or other materials you submit to the Platform. This includes, but is not limited to, articles, blogs, photographs, illustrations, videos, podcasts, comments, reviews, and other multimedia.
2.3 Submission Process: Content must be submitted through the designated submission process on the Platform. You must provide accurate and complete information during the submission process.

3. Ownership and Grant of Rights
3.1 Ownership: You retain all copyright and other proprietary rights in and to your Content.
3.2 Licence: By submitting Content, you grant Banzan Ventures Private Limited a non-exclusive, worldwide, royalty-free, sublicensable, transferable, and perpetual licence to use, reproduce, distribute, prepare derivative works of, display, and perform the Content in connection with SnackR and its business operations. This includes the right to promote and redistribute part or all of the Content in any media formats and through any media channels.
3.3 Moral Rights: You waive any moral rights or similar rights you may have in the Content to the fullest extent permitted by law, allowing Banzan Ventures Private Limited to use the Content without attributing it to you or altering it as necessary.
3.4 Use of Content: Banzan Ventures Private Limited may use the Content for promotional purposes, including but not limited to marketing campaigns, social media posts, advertisements, and newsletters.

4. Warranties and Representations
You represent and warrant that:
4.1 Ownership and Rights: You own or have the necessary licences, rights, consents, and permissions to grant the rights granted in this Agreement, and the Content is your original work or you have obtained all necessary rights and permissions from third parties to submit the Content.
4.2 No Infringement: The Content does not infringe any intellectual property rights, privacy rights, publicity rights, or other legal rights of any third party. You have obtained all necessary consents and permissions from any individuals appearing in the Content.
4.3 Lawfulness: The Content does not contain any defamatory, obscene, offensive, abusive, harassing, violent, sexually explicit, or otherwise unlawful material and complies with all applicable laws and regulations.
4.4 Accuracy: Any factual claims or representations made in the Content are accurate and not misleading. You agree to provide sources for any factual claims upon request by Banzan Ventures Private Limited.
4.5 No Viruses or Malicious Code: The Content does not contain any viruses, malware, spyware, or any other harmful or malicious code.

5. Content Moderation and Removal
5.1 Publisher's Discretion: Banzan Ventures Private Limited reserves the right to review, modify, reject, or remove any Content at its sole discretion, including but not limited to Content that violates these terms, SnackR's community guidelines, or is deemed inappropriate or harmful.
5.2 Creator's Request: You may request the removal of your Content from the Platform at any time by contacting snackr@banzan.co. Banzan Ventures Private Limited will use commercially reasonable efforts to comply with such requests, although residual copies of the Content may remain on our servers and backups. A full removal process should take around 30 to 45 days from the date of the acceptance of request to complete.
5.3 No Obligation to Publish: Banzan Ventures Private Limited is under no obligation to publish any Content submitted by you. The decision to publish Content is at the sole discretion of the Publisher.

6. Monetization and Royalties
6.1 Monetization Opportunities: Banzan Ventures Private Limited may offer monetization opportunities for Content submitted to SnackR. These opportunities may include, but are not limited to, ad revenue sharing, sponsorships, and premium content subscriptions.
6.2 Royalty Payments: If your Content generates revenue, you will be entitled to receive royalties based on a percentage of the net revenue received by Banzan Ventures Private Limited from the monetization of your Content. The specific percentage and terms will be communicated to you separately.
6.3 Payment Terms: Royalties will be paid to you on a [monthly/quarterly] basis, subject to a minimum payout threshold. If the amount of royalties owed to you is below the threshold, the payment will be rolled over to the next payment period. Payments will be made via [payment method], and you are responsible for providing accurate payment information.
6.4 Taxes: You are responsible for any taxes applicable to the royalties you receive. Banzan Ventures Private Limited may withhold taxes from your royalties if required by law.
6.5 Reporting: Banzan Ventures Private Limited will provide you with periodic reports detailing the revenue generated by your Content and the corresponding royalties owed to you.

7. Indemnification
You agree to indemnify, defend, and hold harmless Banzan Ventures Private Limited, SnackR, its affiliates, and their respective officers, directors, employees, and agents from any claims, liabilities, damages, losses, costs, or expenses (including reasonable legal fees) arising out of your breach of this Agreement, your Content, your violation of any rights of a third party, or your violation of any applicable laws or regulations.

8. Limitation of Liability
To the maximum extent permitted by law, Banzan Ventures Private Limited shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from your submission of Content to SnackR. In no event shall Banzan Ventures Private Limited's total liability to you for all claims arising out of or related to this Agreement exceed the amount paid by you, if any, to Banzan Ventures Private Limited for accessing and using the Platform.

9. Termination
Banzan Ventures Private Limited reserves the right to terminate or suspend your access to the Platform and remove your Content at any time, with or without cause, and with or without notice, including for any violation of this Agreement, other policies of SnackR, or any applicable laws or regulations.
9.1 Effect of Termination: Upon termination, all licences granted by you in this Agreement will immediately cease. However, Banzan Ventures Private Limited may retain archival copies of your Content for record-keeping purposes.

10. Governing Law and Dispute Resolution
This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of laws principles. Any disputes arising under or in connection with this Agreement shall be resolved through binding arbitration in [Location], in accordance with the rules of [Arbitration Organization]. The arbitration proceedings shall be conducted in english, and the decision of the arbitrator shall be final and binding. Each party shall bear its own costs in the arbitration proceedings.
10.1 Class Action Waiver: You agree to resolve any disputes with Banzan Ventures Private Limited on an individual basis and waive any right to participate in a class action lawsuit or class-wide arbitration.

11. Miscellaneous
11.1 Entire Agreement: This Agreement constitutes the entire agreement between you and Banzan Ventures Private Limited regarding your submission of Content to SnackR and supersedes any prior agreements or understandings, whether written or oral.
11.2 Severability: If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. The invalid or unenforceable provision shall be modified to the minimum extent necessary to make it valid and enforceable.
11.3 Amendments: Banzan Ventures Private Limited reserves the right to amend these terms at any time by posting the amended terms on the SnackR platform. Your continued submission of Content constitutes acceptance of the amended terms.
11.4 No Waiver: The failure of Banzan Ventures Private Limited to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision. Any waiver of any provision of this Agreement will be effective only if in writing and signed by an authorised representative of Banzan Ventures Private Limited.
11.5 Assignment: You may not assign or transfer any of your rights or obligations under this Agreement without the prior written consent of Banzan Ventures Private Limited. Banzan Ventures Private Limited may freely assign or transfer this Agreement without restriction.

---

Banzan Ventures Private Limited
snackr@banzan.co

`}

</p>) 