

import React from "react";
import { useNavigate } from "react-router-dom";
import { SecondaryButtonBoxyBlack } from "Components/ButtonSecondary";

function LoginPrompt() {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login"); // Navigate to the login page
  };

  return (
    <div className="_bg-[#412AAB] w-full mb-[40px] rounded-[20px] overflow-hidden" style={{boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.2)",}}>
      <div className="h-full w-full px-[20px] py-[10px] flex flex-col justify-center bg-white shadow-lg _bg-[#1A1A1A] _border-2 rounded-[20px] _border-[#8A6EFF]">
        <div className="flex-col text-center items-center h-full w-full">
          <h2 className="text-center font-bold text-[1.2rem] pt-[20px] text-black">
            Sign In
          </h2>
          <p className="text-center text-[1rem] pt-[10px] text-black">
            Sign in for a more rewarding experience!
          </p>
          <div className="mt-[20px] pb-[40px] ">
          <SecondaryButtonBoxyBlack _className="w-[200px] p-5 py-[12px]" label="LOGIN" onClick={() => goToLogin()} />

          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPrompt;
